/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example            from '../lib/example'
import BADropdown,
      {BADropdownExample} from '/lib/components/dropdown'

export const meta = {
  name: "Root Close HOC",
  category: "HOCs, Hooks, & Functions",
  tags: [],
  components: [],
  functions: ["withRootClose"],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`withRootClose `}<br />{` `}<small>{` A Higher Order Component that listens for clicks outside its children and hides them.`}</small></h1>
    <p>{`Meant to be wrapped around toggly popover components like `}<inlineCode parentName="p">{`BADropdown`}</inlineCode>{`.`}</p>
    <p>{`Has the option of being controlled, or controlling its own `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` state.`}</p>
    <p>{`See list of optional props below for more details on that.`}</p>
    <h2>{`Example (same as found in BADropdown)`}</h2>
    <Example mdxType="Example">
  <BADropdownExample withPortal mdxType="BADropdownExample" />
    </Example>
    <h2>{`Adding to your component`}</h2>
    <pre><code parentName="pre" {...{}}>{`// a React component class

class MyComponent extends Component {
  render() {
    return (
      <Some Markup />
    )
  }
}

// the class with \`withRootClose\`

const RootClosedComponent = withRootClose(MyComponent)

`}</code></pre>
    <h2>{`Alternatively.... adding with the Decorator Syntax`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`@decorator`}</inlineCode>{` syntax below is exactly the same as the above but a bit nicer.`}</p>
    <pre><code parentName="pre" {...{}}>{`@withRootClose
class MyComponent extends Component {
  render() {
    return (
      <Some Markup />
    )
  }
}
`}</code></pre>
    <h2>{`Optional props to be added to the component wrapped by `}<inlineCode parentName="h2">{`withRootClose`}</inlineCode></h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`isOpen`}</inlineCode>{`, Boolean: if you want to control the `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` state of your dropdown content from a parent component,
pass it down as this prop. This will supplant the `}<inlineCode parentName="p">{`false`}</inlineCode>{` value `}<inlineCode parentName="p">{`withRootClose`}</inlineCode>{` starts with by default.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`onClose`}</inlineCode>{`, Function: again, if the component's `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` state is being controlled by a parent, OR if you simply have other
functionality that you want to trigger when the component closes, pass that down in this function prop.
`}<inlineCode parentName="p">{`withRootClose`}</inlineCode>{` will call that instead of / as well as its own state setting function.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`closeAfterDelay`}</inlineCode>{`, Boolean: on top of listening for clicks outside the Toggle Button and the dropdown content,
withRootClose will also listen for the mouse hovering away from them for 1.5 seconds.`}</p>
      </li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
