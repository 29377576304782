/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example';
import IconButton from '/lib/components/IconButton/IconButton';

export const meta = {
  name: "Icon Button",
  category: "Deprecated Components",
  tags: [],
  components: ["IconButton"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Icon Button (deprecated)`}</h1>
    <p>{`render a button containing an icon. `}</p>
    <h2>{`Reasons for deprecation`}</h2>
    <ul>
      <li parentName="ul">{`currently used on the favourites list only`}</li>
      <li parentName="ul">{`not documented well`}</li>
      <li parentName="ul">{`doesn't offer any clear advantages over any of the options available on our other (more widely used) button components`}</li>
    </ul>
    <Example twoColumn mdxType="Example">
  <IconButton size="small" name="fas fa-anchor" onClick={() => alert('clicked!')} mdxType="IconButton" />
    </Example>
    <Example twoColumn mdxType="Example">
  <IconButton size="medium" name="fas fa-anchor" onClick={() => alert('clicked!')} mdxType="IconButton" />
    </Example>
    <Example twoColumn mdxType="Example">
  <IconButton size="large" name="fas fa-anchor" onClick={() => alert('clicked!')} mdxType="IconButton" />
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
