/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import {Swatch,
        ColorTab,
        MiniSwatch,
        ColorSwatch,
        MiniSwatcher,
        defaultSuffixes,
        ColorSwatcher} from '/styleguide/lib/swatch'
import Example from '/styleguide/lib/example.jsx'

export const meta = {
  name: "Colours",
  category: "Style Guide",
  tags: [],
  components: [],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Colours Overview`}</h1>
    <p>{`Colours are provided by CSS Custom properties.`}</p>
    <p>{`We also provide three utility classes for each colour.`}</p>
    <p>{`If you need to set text, background, or border colour, grab one of the utility classes.`}</p>
    <p>{`If you need to do something more fancy, you can use the CSS Custom property.`}</p>
    <p>{`You can use these within regular stylesheets, JSX `}{`\``}{`style`}{`\``}{` attributes, and even style-components definitions.`}</p>
    <p>{`If you need a colour that isn't represented here, it's recommended that you strongly rethink whether you really need it.`}</p>
    <p>{`If you are still convinced that you need a new colour, pelase submit a PR to this styleguide.`}</p>
    <p>{`The colour palette is restricted for two reasons:`}</p>
    <ol>
      <li parentName="ol">{`to build visual consistency around the application.`}</li>
      <li parentName="ol">{`to make things easy to theme if clients require custom colour schemes.`}</li>
    </ol>
    <h2>{`Brand Colours`}</h2>
    <ColorSwatcher color="" suffixes={["--brand-1", "--brand-2", "--brand-3", "--brand-4", "--brand-5", "--brand-6"]} mdxType="ColorSwatcher" />
    <h2>{`UI Colours`}</h2>
    <p>{`UI Colours are for drawing attention to interactive elements within user interfaces.`}</p>
    <p>{`Use them to highlight key parts of the UI, in particular areas that demand specific attention, or are the focus for an interaction.`}</p>
    <p>{`Variants (light, lighter, dark, darker) are provided, and can be use for hover-states, disabled states, borders, adjoining elements etc.`}</p>
    <ColorSwatcher color="--highlight" suffixes={defaultSuffixes} mdxType="ColorSwatcher" />
    <ColorSwatcher color="--warning" suffixes={defaultSuffixes} mdxType="ColorSwatcher" />
    <ColorSwatcher color="--hazard" suffixes={defaultSuffixes} mdxType="ColorSwatcher" />
    <ColorSwatcher color="--confirm" suffixes={defaultSuffixes} mdxType="ColorSwatcher" />
    <h2>{`Text Colours`}</h2>
    <p>{`These four text colours are provided.`}</p>
    <p>{`Almost all text should be the main colour.`}</p>
    <p>{`The light colour should be used in cases where supporting text is useful.`}</p>
    <p>{`But it can be glossed over without detriment to the user experience.`}</p>
    <p>{`Dark text should be used for elements of particular import`}</p>
    <ColorSwatcher color="--text" suffixes={["-main", "-dark", "-light", "-dark-bg"]} mdxType="ColorSwatcher" />
    <h2>{`Utility Classes`}</h2>
    <p>{`You can use className to apply colours to background, text, and borders.`}</p>
    <h2>{`Using utility classes to apply colours`}</h2>
    <Example mdxType="Example">
  <div className="margin-bottom padding border-highlight" style={{
        borderWidth: "3px",
        borderStyle: "dotted"
      }}> using the border class, to set border-colour. note, you'll need to set border-width and style yourself. </div>
  <div className="margin-bottom padding text-highlight"> using the text class to set text colour </div>
  <div className="margin-bottom padding bg-highlight"> using the bg class to set the background colour </div>
  <div className="margin-bottom padding" style={{
        backgroundColor: "var(--warning-dark)",
        color: "var(--text-dark-bg)"
      }}>Using the CSS custom property directly</div>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
