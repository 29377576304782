/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example.jsx'
import { BAColorPickerPopoverButton } from '/lib/components/color-picker/color-picker-popover-button.tsx'
import { ColorPickerHelper }  from './color-picker-helpers.tsx'
import { css } from 'styled-components'
export const meta = {
  name: "Colour Picker",
  category: "Components",
  tags: ["colors", "colours"],
  components: ["BAColorPicker"],
  functions: [],
  values: {
    color1: '#f00',
    color2: '#0f0'
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BAColorPickerPopoverButton`}</h1>
    <Example mdxType="Example">
  <BAColorPickerPopoverButton selectedColor={props.get('color2')} onChange={color => {
        props.set('color2', color.hex);
      }} size="medium" mdxType="BAColorPickerPopoverButton" />
    </Example>
    <p>{`Renders a button which triggers a popover containing a colourpicker from the 'react-colorful' library.`}</p>
    <p>{`The button accepts a 'size' prop as per the BAButton component.`}</p>
    <p>{`N.B. we spell it 'color' in the code, and with the English spelling 'colour' if it's text to be displayed on-screen.`}</p>
    <p>{`It takes the following props:`}</p>
    <ul>
      <li parentName="ul">{`selectedColor: the selected colour.`}</li>
      <li parentName="ul">{`colors: a pre-defined palette of colours to choose from.`}</li>
      <li parentName="ul">{`onChange: a change handling function.`}</li>
      <li parentName="ul">{`disabled: whether the button is disabled.`}</li>
      <li parentName="ul">{`size: the size of the button. Same sizes as BAButton`}</li>
      <li parentName="ul">{`zIndex: the z-index of the popover.`}</li>
      <li parentName="ul">{`children: the text to display on the button. Will appear to the left of the color indicator.`}</li>
      <li parentName="ul">{`CSS: a styled CSS object - see below for an example.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<BAColorPickerPopoverButton
  selectedColor={selectedColor}
  onChange={onChange}
  size="medium"
  CSS={css\`
    margin-left: 10px;
    width: 200px;
  \`}
/>
`}</code></pre>
    <p>{`The colour picker will pass your `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` prop a ColorResult object like the following, so you can decide what format you want to use.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "hsl": {
    "h":0,
    "s":1,
    "l":0.5571428571428572,
    "a":1
  },
  "hex": "#ff1d1d",
  "rgb":{
    "r":255,
    "g":29,
    "b":29,
    "a":1
  },
}
`}</code></pre>
    <h1>{`Stand-alone ColorPicker with useColorPicker hook`}</h1>
    <p>{`You can also import BAColorPicker and useColorPicker directly to render it outside a popover.`}</p>
    <p>{`BAColorPicker has 2 change handlers: onChange and duringChange.`}</p>
    <p>{`duringChange is called on every color change, and onChange is called when the user stops dragging the cursor.`}</p>
    <p>{`useColorPicker creates some internal state for the colorPicker's current color, and a handler ('duringChange').`}</p>
    <p>{`The component itself will call the onChange handler on mouseUp.`}</p>
    <p>{`This is useful for preventing excessive re-renders in the parent component as you drag across.`}</p>
    <p>{`useColorPicker also returns an error message, which is useful for validation.`}</p>
    <ColorPickerHelper onChangeColor={color => {
      props.set('color1', color.hex);
    }} selectedColor={props.get('color1')} mdxType="ColorPickerHelper" />
    <p>{`The code for the above example is as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [color, setColor] = useState('#f00')

const { duringChange, colorResult, error } = useColorPicker(color)

return (
  <BAColorPicker
    selectedColor={colorResult}
    duringChange={duringChange}
    onChange={color => {
      setColor(color.hex)
    }}
    error={error}
  />
)
`}</code></pre>
    <h1>{`Disable the hue picker`}</h1>
    <p>{`Use the `}<inlineCode parentName="p">{`shouldShowSwatchOnly`}</inlineCode>{` prop to force the user to choose from your pre-selected palette passed into the `}<inlineCode parentName="p">{`colors`}</inlineCode>{` prop.`}</p>
    <BAColorPickerPopoverButton selectedColor={props.get('color2')} onChange={color => {
      props.set('color2', color.hex);
    }} size="medium" shouldShowSwatchOnly mdxType="BAColorPickerPopoverButton" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<BAColorPickerPopoverButton
  selectedColor={props.get('color2')}
  label="Pick a colour!"
  labelLeft
  onChange={color => {
    props.set('color2', color.hex)
  }}
  size="medium"
  shouldShowSwatchOnly
/>
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
