import React, { Component } from "react";
import { generateId } from "/lib/components/_shared/vars";
import { components } from "../../pages";

import autobind from "autobind-decorator";


// create a fallback route
const FallBack = () => <div key={generateId()} className="padding jumbo">
    There is no component configured for {window.location.pathname}
  </div>



// ROUTE CONTROLLER  /  RENDERER -----------------------------------------------
class RouteController extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("click", this.navHandler);
    window.addEventListener("popstate", this.backHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.navHandler);
    window.removeEventListener("popstate", this.backHandler);
  }

  @autobind
  navHandler(e) {
    const { router } = this.props;
    if (e.target.pathname && router.has(e.target.pathname)) {
      e.preventDefault();
      e.stopPropagation();
      window.history.pushState({}, null, e.target.pathname);
      this.forceUpdate();
      if (this.props.onChange) this.props.onChange(e.target.pathname);
    }
  }

  @autobind
  backHandler(e) {
    const { router } = this.props;
    if (window.location.pathname && router.has(window.location.pathname)) {
      this.forceUpdate();
      if (this.props.onChange) this.props.onChange(e.target.pathname);
    }
  }

  render() {
    const componentName = window.location.pathname.replace(/^\//, "")
    let Component = components[componentName] ? components[componentName].default : FallBack
    // handle root route
    if (componentName === "") Component = components.styleguide_pages_index.default
    return (
      <Component
        get={this.props.get}
        set={this.props.set}
      />
    );
  }
}

export { RouteController };
