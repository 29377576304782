/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example  from '/styleguide/lib/example'
import { dimensions, metrics, datasources } from '/styleguide/fixtures/things-tree.js'
import { BACheck } from '/lib/components/forms/checkboxes'
import { BAGrid, BACell } from '/lib/components/grid.jsx'
import { BAThingPicker, BAFloatingThingPalette, BAThingPalette, BAThingPickerButton } from './thing-picker.jsx'
import { getLargePickerTree } from './large-picker-tree'
import { StyleguideControlledPicker } from './controlled-picker-example'
export const meta = {
  name: "Thing Picker",
  category: "Components",
  tags: [],
  components: ["BAThingPicker", "BAFloatingThingPalette", "BAThingPalette", "BAThingPickerButton"],
  values: {
    dimensions,
    metrics,
    datasources,
    dark: false,
    selectedDimensions: [],
    selectedDimension: null
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Thing Picker & ThingPalette 👈`}</h1>
    <p>{`The ThingPicker is an interface for selecting multiple items from a tree-shaped datastructure. It implements search, and tree-browsing. try it out:`}</p>
    <BAGrid style={{
      width: '60%'
    }} mdxType="BAGrid">
  <BACell cols="1 / 3" mdxType="BACell">
    <BAThingPickerButton icon='F' position='fixed' prompt="I am fixed in position" dark={props.get("dark")} items={dimensions} initiallySelected={props.get("selectedDimensions")} onChange={selectedItems => window.itemsSelected = selectedItems} confirm={selectedItems => props.set("selectedDimensions", selectedItems)} cancel={() => {
          console.log("cancelled");
        }} mdxType="BAThingPickerButton" />
  </BACell>
  <BACell cols="3 / 13" mdxType="BACell">
    The resulting palette stays put in the viewport, thanks to a `position` prop.
  </BACell>
  <BACell cols="1 / 3" mdxType="BACell">
    <BAThingPickerButton icon='S' prompt="I scroll with the page" dark={props.get("dark")} items={dimensions} initiallySelected={props.get("selectedDimensions")} onChange={selectedItems => window.itemsSelected = selectedItems} confirm={selectedItems => props.set("selectedDimensions", selectedItems)} cancel={() => {
          console.log("cancelled");
        }} mdxType="BAThingPickerButton" />
  </BACell>
  <BACell cols="3 / 13" mdxType="BACell">
    The resulting palette scrolls with the page. This is the default settings and does not need a prop to specify this behaviour.
  </BACell>
    </BAGrid>
    <p>{`You picked`}</p>
    <div><span children={[(props.get("selectedDimensions") || 0).length]} /> dimensions</div>
    <div>{(props.get("selectedDimensions") || []).map(d => d.text).join(", ")}</div>
    <h2>{`Flavours`}</h2>
    <p>{`You can use the ThingPicker in a number of ways.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Role`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`<BAThingPicker />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The core thingpicker UI -- pick some things`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`<BAThingPalette />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The thingpicker UI styled in a contained palette`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`<BAThingPickerButton />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A button that launches a floating thingpicker palette`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`What is it?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`prompt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a short prompt that appears at the top of the picker`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`textKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`what key on each node gets its visible text. `}<em parentName="td">{`defaultValue = `}<inlineCode parentName="em">{`text`}</inlineCode></em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Object`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`required`}</em>{` `}<br />{` a tree-shaped object. Each node must have an `}<inlineCode parentName="td">{`id`}</inlineCode>{` or `}<inlineCode parentName="td">{`_id`}</inlineCode>{` property. Display text is read from the `}<inlineCode parentName="td">{`text`}</inlineCode>{` property. Leaves and Branches are inferred from the presences of a `}<inlineCode parentName="td">{`children`}</inlineCode>{` property, which should be a list of other nodes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`cancel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`function`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`() => {}`}</inlineCode>{` `}<br />{` if provided, a "cancel" button will be rendered. The `}<inlineCode parentName="td">{`cancel`}</inlineCode>{` function is called with no arguments if the user clicks the cancel button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`mode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`use one of `}<inlineCode parentName="td">{`BAThingPicker.modes`}</inlineCode>{`: `}<br />{` `}<inlineCode parentName="td">{`.many`}</inlineCode>{` (default), `}<inlineCode parentName="td">{`.one`}</inlineCode>{`, `}<inlineCode parentName="td">{`.url`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`confirm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`function`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`selectedItems => {}`}</inlineCode>{` `}<br />{`if provided, an "OK" button with be rendered. The `}<inlineCode parentName="td">{`confirm`}</inlineCode>{` function is called with an array of nodes selected at the time the OK button is clicked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`onChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`function`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`selectedItems => {}`}</inlineCode>{` `}<br />{`if provided, the onChange function will be called with a list of currently selected items any time the selection changes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`onClose`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`function`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em>{`(BAThingPickerButton only)`}</em><br /><inlineCode parentName="td">{`selectedItems => {}`}</inlineCode>{` `}<br />{`if provided the `}<inlineCode parentName="td">{`onClose`}</inlineCode>{` function will be called with a list of currently selected items if the picker is closed because the user clicks elsewhere in the screen`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em>{`(BAThingPickerButton only)`}</em><br />{`a BADSOUP size. "micro", "small", "medium", "large", or "jumbo" `}<br />{` used to set the button size`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em>{` or `}<em parentName="td">{`JSX`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em>{`(BAThingPickerButton only)`}</em><br />{` the icon or character to render at the center of the trigger button for the BAThingPickerButton`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`clearable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Bool`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em>{`(multi selection mode only)`}</em><br />{` if set to `}<inlineCode parentName="td">{`false`}</inlineCode>{` this will not allow you to deselect the last remaining selection, and remove the 'deselect all' button from the footer. A niche application thus far only implemented in the Dashboard filters widget`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`urlGenerator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`function`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em>{`"url" mode only`}</em><br />{` `}<inlineCode parentName="td">{`node => urlString`}</inlineCode>{` `}<br />{`generate a url for a given node.`}<br />{` You can alternatively pass nodes that have a `}<inlineCode parentName="td">{`url`}</inlineCode>{` property`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`keyGen`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`function`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`node => uniqueKey`}</inlineCode>{` `}<br />{` generate a unique key for a node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`maxHeight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`CSS size`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em>{`the max-height of the internal list component for internal scrolling`}</em><br />{` Defaults to `}<inlineCode parentName="td">{`60vh`}</inlineCode>{` `}<br />{` pass `}<inlineCode parentName="td">{`"none"`}</inlineCode>{` to remove internal scrolling completely`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`virtualize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Bool`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Use react-virtualized to render the internal list.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`initiallySelected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Object`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><br />{` This prop is used to pass into the Things picker a tree of previously selected and expanded items. These are copied in local state to determine the Things Picker initial selections`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Object`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><br />{` This prop is used to control the picker selection (selected state) from props (`}<inlineCode parentName="td">{`props.selected`}</inlineCode>{`). By passing this value as a valid Tree, it will be the user responsibility to update the picker selected props to match the application state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`padded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Bool`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><br />{` if set explicitely to true, this will output the picker without cntaining padding (i.e no padding on the PickerContainer styled div)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`showSearch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Bool`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><br />{` choose to hide the search bar by setting `}<inlineCode parentName="td">{`showSearch`}</inlineCode>{` to false. This is set to true by default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`tooltipPosition`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><br />{` the position for leaf and branches info tooltip: defaults to "right"`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<inlineCode parentName="p">{`ThingPicker`}</inlineCode>{` takes an `}<inlineCode parentName="p">{`items`}</inlineCode>{` prop that must be a tree structure, e.g.`}</p>
    <pre><code parentName="pre" {...{}}>{`import { BAThingPicker, BAThingPalette, BAThingPickerButton } from 'ba-component-lib'

const items = {
  id :"root",
  children: [
    {
      id: 1,
      text : "Sub-item with children",
      children : [
        { id: 2, text : "sub-sub-item 1" },
        { id : 3, text : "sub-sub-item 2" }
      ]
    },
    {
      id: 4,
      text : "Another Sub-item with children",
      children : [
        { id: 5, text : "sub-sub-item 1" },
        { id: 6, text : "sub-sub-item 2" },
        {
            id : 7,
            text : "sub-sub-item with children",
            children : [
              { id: 8, text : "sub-sub-item 1" },
              { id: 9, text : "sub-sub-item 2" }
            ]
          }
      ]
    }
  ]
}

...

<BAThingPicker
  items = { items }
/>
`}</code></pre>
    <h2>{`Managing selected state`}</h2>
    <p>{`In terms of selected nodes management, the ThingsPicker can be used in one of two ways`}</p>
    <ul>
      <li parentName="ul">{`Uncontrolled: in this approach, the ThingsPicker should not be passed a `}<inlineCode parentName="li">{`selected`}</inlineCode>{` prop. If an initially selected state is needed, `}<inlineCode parentName="li">{`initiallySelected`}</inlineCode>{` should be used instead`}</li>
      <li parentName="ul">{`Controlled : in this approach the ThingsPicker expects the prop `}<inlineCode parentName="li">{`selected`}</inlineCode>{` to determine its selected nodes (managed component).  When in this mode the props `}<inlineCode parentName="li">{`selected`}</inlineCode>{` and `}<inlineCode parentName="li">{`expanded`}</inlineCode>{` will be used to populate the Picker `}<inlineCode parentName="li">{`expanded`}</inlineCode>{` and `}<inlineCode parentName="li">{`selected`}</inlineCode>{` states`}</li>
    </ul>
    <h2>{`Disabling options`}</h2>
    <p>{`Branches (nodes with children) and leaves (nodes without children) will both respond to the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop.
In the case of the branch only its checkbox will be disabled.`}</p>
    <p>{`Here is an example:`}</p>
    <Example mdxType="Example">
  <BAThingPicker items={{
        id: "root",
        children: [{
          id: 1,
          text: "Sub-item with children",
          children: [{
            id: 2,
            text: "sub-sub-item 1"
          }, {
            id: 3,
            text: "sub-sub-item 2"
          }]
        }, {
          id: 4,
          text: "Another Sub-item with children",
          children: [{
            id: 5,
            text: "sub-sub-item 1"
          }, {
            id: 6,
            text: "sub-sub-item 2"
          }, {
            id: 7,
            text: "sub-sub-item with children",
            disabled: true,
            children: [{
              id: 8,
              text: "sub-sub-item 1"
            }, {
              id: 9,
              text: "sub-sub-item 2",
              disabled: true
            }]
          }]
        }]
      }} onChange={() => {}} confirm={() => {}} cancel={() => {}} mdxType="BAThingPicker" />
    </Example>
    <h2>{`Modes`}</h2>
    <p>{`Modes let you change the behaviour of the ThingPicker, between choosing many items, choosing a single item, and rendering a link for each item.`}</p>
    <p>{`Many is the default behaviour. Let's take a look at a `}<em parentName="p">{`one mode`}</em>{` thingpicker:`}</p>
    <BAThingPickerButton icon="1" prompt="Choose one dimension" mode={BAThingPicker.modes.one} dark={props.get("dark")} items={dimensions} initiallySelected={props.get("selectedDimension")} confirm={selectedItem => console.log(selectedItem) || props.set("selectedDimension", selectedItem)} cancel={() => {
      console.log("cancelled");
    }} tooltipPosition="bottom" mdxType="BAThingPickerButton" />
    <p>{`And how about URL?`}</p>
    <BAThingPickerButton icon="#" prompt="Visit a dimension" mode={BAThingPicker.modes.url} dark={props.get("dark")} items={dimensions} cancel={() => {
      console.log("cancelled");
    }} urlGenerator={node => "#"} mdxType="BAThingPickerButton" />
    <h2>{`BAThingPalette`}</h2>
    <p>{`The ThingPalette is a ThingPicker, width-constrained, and styled to look like a palette, for use in modal or contextual presentations.`}</p>
    <BAThingPalette dark={props.get("dark")} items={metrics} onChange={selectedItems => window.itemsSelected = selectedItems} confirm={selectedItems => {
      console.log("confirmed");
    }} cancel={() => {
      console.log("cancelled");
    }} maxHeight="none" mdxType="BAThingPalette" />
    <p>{`Button Powered:`}</p>
    <BAThingPickerButton dark={props.get("dark")} items={metrics} onChange={selectedItems => window.itemsSelected = selectedItems} confirm={selectedItems => {
      console.log("confirmed");
    }} cancel={() => {
      console.log("cancelled");
    }} mdxType="BAThingPickerButton" />
    <h2>{`Sizing`}</h2>
    <p>{`The ThingPicker should respond to text-size if you need to render one smaller or larger`}</p>
    <Example mdxType="Example">
  <div style={{
        "fontSize": "var(--text-size-micro)"
      }}>
    <BAThingPicker items={{
          id: "root",
          children: [{
            id: 1,
            text: "Sub-item with children",
            children: [{
              id: 2,
              text: "sub-sub-item 1"
            }, {
              id: 3,
              text: "sub-sub-item 2"
            }]
          }, {
            id: 4,
            text: "Another Sub-item with children",
            children: [{
              id: 5,
              text: "sub-sub-item 1"
            }, {
              id: 6,
              text: "sub-sub-item 2"
            }, {
              id: 7,
              text: "sub-sub-item with children",
              disabled: true,
              children: [{
                id: 8,
                text: "sub-sub-item 1"
              }, {
                id: 9,
                text: "sub-sub-item 2",
                disabled: true
              }]
            }]
          }]
        }} onChange={() => {}} confirm={() => {}} cancel={() => {}} mdxType="BAThingPicker" />
  </div>
    </Example>
    <h2>{`Disabling search`}</h2>
    <p>{`By setting the `}<inlineCode parentName="p">{`showSearch`}</inlineCode>{` prop to false we can disable the search bar.`}</p>
    <p>{`Here is an example:`}</p>
    <Example mdxType="Example">
  <BAThingPicker items={{
        id: "root",
        children: [{
          id: 1,
          text: "Sub-item with children",
          children: [{
            id: 2,
            text: "sub-sub-item 1"
          }, {
            id: 3,
            text: "sub-sub-item 2"
          }]
        }, {
          id: 4,
          text: "Another Sub-item with children",
          children: [{
            id: 5,
            text: "sub-sub-item 1"
          }, {
            id: 6,
            text: "sub-sub-item 2"
          }, {
            id: 7,
            text: "sub-sub-item with children",
            children: [{
              id: 8,
              text: "sub-sub-item 1"
            }, {
              id: 9,
              text: "sub-sub-item 2"
            }]
          }]
        }]
      }} onChange={() => {}} confirm={() => {}} cancel={() => {}} showSearch={false} mdxType="BAThingPicker" />
    </Example>
    <h2>{`Controlled picker`}</h2>
    <p>{`The TPicker tree can be iupdated externally under all setups - this is supported for controled and not controlled mode
By passing a selected props, we can drive the TPicker selection externalle. If we instead do not need that but still need
to pass the TPicker a sert of pre-selected items, we can use the initiallySelected prop`}</p>
    <p>{`Here is an example of a controlled picker:`}</p>
    <Example mdxType="Example">
  <StyleguideControlledPicker mdxType="StyleguideControlledPicker" />
    </Example>
    <h2>{`Non-clearable picker`}</h2>
    <p>{`It is possible to prevent the user from deselecting ALL options in the ThingPicker when it's in multi mode. This wouldn't
be relevant if you were in URL, single-option modes or your component was not controlled. `}</p>
    <p>{`However, if you passed a `}<inlineCode parentName="p">{`clearable = {false}`}</inlineCode>{` prop to any thing picker in our library, you would find that the last selected
leaf in the tree would not respond to a click in its checkbox. The de-select ALL toggle on a branch would leave one selected.`}</p>
    <Example mdxType="Example">
  <BAThingPicker clearable={false} prompt="Choose at least one dimension" items={dimensions} onChange={selectedItems => window.itemsSelected = selectedItems} confirm={selectedItems => props.set("selectedDimensions", selectedItems)} cancel={() => {
        console.log("cancelled");
      }} mdxType="BAThingPicker" />
    </Example>
    <h2>{`Very large Picker tree`}</h2>
    <p>{`Throiugh search debounce we can speed up the pickler search performance`}</p>
    <Example mdxType="Example">
  <BAThingPicker items={getLargePickerTree()} onChange={() => {}} confirm={() => {}} cancel={() => {}} mdxType="BAThingPicker" />
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
