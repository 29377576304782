import React  from 'react'
import styled from 'styled-components'

const Swatch = styled.div`
  background   : var(${props => props.color});
  height       : ${props => props.height || "100px"};
  width        : ${props => props.width  || "inherit"};
  display      : ${props => props.inline ?  "inline-block": "block"};
  border       : ${props => props.border || "inherit"};
`

const ColorTab = styled.div`
  min-width: 175px;
  border-width: 0.5rem;
  border-style: solid;
  border-color: #eee;
  display: inline-block;
  background: #eee;
  .example {
    font-size: 1rem;
    padding: 0.5rem;
    color: black;
    font-family: 'Courier New', Courier, monospace;
    margin : 0;
  }
`

const MiniSwatch = props => <Swatch
    inline
    height = "50px"
    width  = "50px"
    border = "0.5rem solid #eee"
    {...props}
  />

const ColorSwatch = props => <ColorTab className={"border-" + props.color.replace("--", "")}>
    <Swatch color={props.color} />
    <div className="example">
      var({ props.color })<br /><br />
      { ".bg-" + props.color.replace("--", "") }<br />
      { ".text-" + props.color.replace("--", "") }<br />
      { ".border-" + props.color.replace("--", "") }<br />
    </div>
  </ColorTab>



  // set the display name so that the component shows up correctly in code examples
  ColorSwatch.displayName = "ColorSwatch"
  const MiniSwatcher = ({color, suffixes}) => <div style={{height: "50px"}}>
      {
        suffixes
        ? suffixes.map(suffix => <MiniSwatch key={color + suffix + "key"} color={color + suffix} />)
        : <MiniSwatch color={color} />
      }
    </div>

  const defaultSuffixes = [
    "-lighter",
    "-light",
    "",
    "-dark",
    "-darker"
  ]

  const ColorSwatcher = ({color, suffixes}) => <div>
  {
    suffixes
    ? suffixes.map(suffix => <ColorSwatch key={color + suffix + "key"} color={color + suffix} />)
    : <ColorSwatch color={color} />
  }
  </div>

export {
  Swatch,
  ColorTab,
  MiniSwatch,
  ColorSwatch,
  MiniSwatcher,
  defaultSuffixes,
  ColorSwatcher
}
