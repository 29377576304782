/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import {BACard, 
        StyledCard,
        CardStatistic,    
        CardButtons, 
        CardDescriptor, 
        SparklineAndButtons}  from '/lib/components/card.jsx'
import Page               from '/lib/components/page.tsx'
import Example            from '../lib/example.jsx'
import Grid               from '/lib/components/Grid/Grid'
import IconButton         from '/lib/components/IconButton/IconButton.tsx'
import Sparkline          from '/lib/components/Sparkline/Sparkline.tsx'

export const meta = {
  name: "Cards",
  category: "Components",
  tags: [],
  components: ["BACard", "StyledCard", "CardStatistic", "CardButtons", "CardDescriptor", "SparklineAndButtons"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Card component`}</h1>
    <p>{`This is a few `}<inlineCode parentName="p">{`BACard`}</inlineCode>{`s on their own. They have minimum height and width by default
but will expand if the content requires it`}</p>
    <div style={{
      display: 'flex',
      justifyContent: 'space-around'
    }}>
  <Example mdxType="Example"><BACard mdxType="BACard" /></Example>
  <Example mdxType="Example">
    <BACard mdxType="BACard">
      <CardStatistic className="center-all-directions" mdxType="CardStatistic">78%</CardStatistic>
    </BACard>
  </Example>
  <Example mdxType="Example">
    <BACard mdxType="BACard">
      <CardStatistic mdxType="CardStatistic">71,809</CardStatistic>
      <CardStatistic mdxType="CardStatistic">42%</CardStatistic>
    </BACard>
  </Example>
    </div>
    <h2>{`Further example, AKA how to use in Platform Usage page`}</h2>
    <p>{`This is a `}<inlineCode parentName="p">{`grid`}</inlineCode>{` with a `}<inlineCode parentName="p">{`columnCount`}</inlineCode>{` prop passed.
It shows how the Platform Usage page might use this component.
There are other subcomponents available that you could use with this card, namely: `}</p>
    <p><inlineCode parentName="p">{`CardDescriptor  `}</inlineCode>{`  which tells you about the big number/icon below it`}</p>
    <p><inlineCode parentName="p">{`CardStatistic  `}</inlineCode>{`  is the main piece of information your card is conveying`}</p>
    <p><inlineCode parentName="p">{`CardButtons  `}</inlineCode>{` which places a container for buttons in the bottom right of the card`}</p>
    <p><inlineCode parentName="p">{`Sparkline  `}</inlineCode>{` which gives you a snapshot of the trendline.`}</p>
    <Example mdxType="Example">
  <Grid columnCount={3} mdxType="Grid"> 
    <BACard mdxType="BACard">
      <CardDescriptor mdxType="CardDescriptor">
        a description of the statistic being presented to you will go here
      </CardDescriptor> 
      <CardStatistic className="center-all-directions" mdxType="CardStatistic">18,210</CardStatistic>
      <Sparkline height="50px" width="350px" thickness="4" data={[-4, -20, 22, 17, 27, -4, -20, 22, 23, 17, 27]} mdxType="Sparkline" />   
      <CardButtons className="flex-row float-right" mdxType="CardButtons">
          <IconButton name="fas fa-folder" className="margin-left" onClick={() => {}} mdxType="IconButton" />
          <IconButton name="fas fa-plus" className="margin-left" onClick={() => {}} mdxType="IconButton" />
          <IconButton name="fas fa-cog" className="margin-left" onClick={() => {}} mdxType="IconButton" />
        </CardButtons>
      
    </BACard>
    <BACard mdxType="BACard">think you're pretty funny, huh</BACard>
    <BACard mdxType="BACard">
      Sparkline chart!
      <Sparkline height="50px" width="150px" thickness="4" data={[-4, -20, 22, 17, 27, -4, -20, 22, 23, 17, 27]} mdxType="Sparkline" />    
      
    </BACard>
  </Grid>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
