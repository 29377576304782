/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import ConfirmationButton from "/lib/components/ConfirmationButton/ConfirmationButton";
import Icon from "/lib/components/Icon/Icon";

export const meta = {
  name: "Confirmation Button",
  category: "Deprecated Components",
  tags: [],
  components: ["ConfirmationButton"]
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`ConfirmationButton`}</h1>
    <p>{`This is a button that has a confirmation step before triggering the click handler.`}</p>
    <p>{`See `}<inlineCode parentName="p">{`ConfirmationButton.tsx`}</inlineCode>{` for prop options`}</p>
    <div style={{
      margin: "4rem 0"
    }}>
  <ConfirmationButton handleClick={console.log} mdxType="ConfirmationButton">Click Me!</ConfirmationButton>
      <div style={{
        marginBottom: "1rem"
      }} />
  <ConfirmationButton handleClick={console.log} confirmButtonColor="confirm" cancelButtonColor="hazard" confirmButtonChildren={<Icon customColor="--confirm" name="fas fa-check" mdxType="Icon" />} cancelButtonChildren={<Icon customColor="--hazard" name="fas fa-times" mdxType="Icon" />} color="confirm" mdxType="ConfirmationButton">Click Me!</ConfirmationButton>
  
  <div style={{
        marginBottom: "1rem"
      }} />
  <ConfirmationButton handleClick={console.log} cancelButtonColor="hazard" confirmButtonColor="confirm" mdxType="ConfirmationButton">Click Me!</ConfirmationButton>
    </div>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
