import React, {Component} from 'react'
import Tippy              from '@tippy.js/react'
import {Manager,
        Reference,
        Popper}           from 'react-popper'
import {BAPortal}         from './portal'
import withRootClose      from './rootClose'
import styled, {css}      from 'styled-components'
import {generateId}       from './_shared/vars'
import {fadeIn}           from './_shared/animations'

const DEFAULT_PLACEMENT = 'top'
const DEFAULT_THEME     = 'dark'

export const BATooltip = props => (
  <Tippy {...props}
    content   = {props.content || ''}
    arrow     = {props.arrow === false ? false : true}
    duration  = {props.duration || 500}
    delay     = {props.delay || [100, 50]}
    theme     = {props.theme || DEFAULT_THEME}
    flip      = {props.flip || false}
    popperOptions = {{
          modifiers: {
            addZIndex: {
              enabled: true,
              order: 810,
              fn: data => ({
                ...data,
                styles: {
                  ...data.styles,
                  zIndex: 1000000,
                },
              }),
            },
          },
        }}
  />
)

// BA POPPER
const getColorForTheme = theme => {

  const themeColors = {
    light: {
      bg     : 'var(--content-bg)',
      border : 'var(--line-dark)',
      txt    : 'var(--text-main)'
    },
    dark: {
      bg     : 'var(--dark-bg)',
      border : 'var(--dark-bg)',
      txt    : 'var(--content-bg)'
    }
  }

  return themeColors[theme] || themeColors[DEFAULT_THEME]
}

const getStylesForPlacement = (placement, theme) => {
  const themeColor = getColorForTheme(theme)

  const placementStyles = {
    top: css`
      margin-bottom: 5px;
      .arrow {
        border-width: 5px 5px 0 5px;
        border-color: ${themeColor.border} transparent transparent transparent;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
      }
    `,
    bottom: css`
      margin-top: 5px;
      .arrow {
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent ${themeColor.border} transparent;
        top: -5px;
        left: calc(50% - 5px);
        margin-bottom: 0;
      }
    `,
    right: css`
      margin-left: 5px;
      .arrow {
        border-width: 5px 5px 5px 0;
        border-color: transparent ${themeColor.border} transparent transparent;
        left: -5px;
        top: calc(50% - 5px);
        margin-right: 0;
      }
    `,
    left: css`
      margin-right: 5px;
      .arrow {
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent ${themeColor.border};
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
      }
    `
  }

  return placementStyles[placement] || placementStyles[DEFAULT_PLACEMENT]
}


const StyledPopperContent = styled.div`
  background    : ${props => getColorForTheme(props.theme).bg};
  color         : ${props => getColorForTheme(props.theme).txt};
  border        : 1px solid ${props => getColorForTheme(props.theme).border};
  padding       : 0.5rem;
  border-radius : 2px;
  box-shadow    : 2px 2px 7px var(--dark-bg-trans);
  animation     : ${fadeIn} 0.2s linear;

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }
  ${props => getStylesForPlacement(props.placement, props.theme)};
`

class BAPopperComponent extends Component {

  constructor() {
    super()
    this.key = generateId()
  }

  render() {

    const {
      isOpen,
      hide,
      show,
      children,
      placement,
      theme,
      setRefFromWithRootClose,
      content,
      contentStyles,
      trigger,
      flip=false,
      delay,
      disabled,
      zIndex
    } = this.props

    const triggerAction = trigger === "hover"
      ? {
        onMouseEnter: () => setTimeout(() => show(), delay || 0),
        onMouseLeave: () => setTimeout(() => hide(), delay || 0)
      }
      : {onClick: () => isOpen ? hide() : show()}

    return (
      <Manager>
        <Reference>
          { ({ref}) => (
            <div
              {...triggerAction}
              className = {disabled ? "popper-trigger disabled" : "popper-trigger"}
              ref       = {ref}
            >
              {children}
            </div>
          )}
        </Reference>

        <BAPortal isOpen={isOpen}>
          <Popper
            placement     = {placement || 'top'}
            eventsEnabled = {false}
          >
            {({ ref, style, placement, arrowProps }) => (
              <div
                id             = 'bacl-popper'
                ref            = {ref}
                style          = {{zIndex:zIndex, ...style}}
                data-placement = {placement}
              >
              {/* set ref from withRootClose so clicking content doesn't trigger a close */}
                <div ref={ref => setRefFromWithRootClose(ref)}>
                  <StyledPopperContent
                    theme     = {theme}
                    placement = {placement}
                  >

                    <div 
                      style={ contentStyles || {} }
                      key={this.key}
                    >
                      {content}
                    </div>

                    <div
                      className = "arrow"
                      ref       = {arrowProps.ref}
                      style     = {arrowProps.style}
                    />
                  </StyledPopperContent>
                </div>
              </div>
            )}
          </Popper>
        </BAPortal>

      </Manager>
    )
  }
}

export const BAPopper = withRootClose(BAPopperComponent)
