import React, { useState }  from 'react'
import {BAButton}          from '../buttons'

import { BAThingPicker } from './thing-picker'

const initialItems = {
  id :"root",
  children: [
    {
      id: 1,
      text : "Sub-item with children",
      children : [
        { id: 2, text : "sub-sub-item 1" },
        { id : 3, text : "sub-sub-item 2" }
      ]
    },
    {
      id: 4,
      text : "Another Sub-item with children",
      children : [
        { id: 5, text : "sub-sub-item 1" },
        { id: 6, text : "sub-sub-item 2" },
        {
            id : 7,
            text : "sub-sub-item with children",
            children : [
              { id: 8, text : "sub-sub-item 1" },
              { id: 9, text : "sub-sub-item 2" }
            ]
          }
      ]
    }
  ]
}

export const StyleguideControlledPicker = () => {

  const expandend = [{ id: 1, text : "Sub-item with children"}]
  const [selected, setSelected] = useState([{ id: 2, text : "sub-sub-item 1" }])
  const [items, setItems] = useState(initialItems)

  return <div>
    <BAButton solid className="margin-top margin-bottom padding-half" onClick={ () =>  {
      setItems({ 
        id: 'root', 
        children: [ {
          id: 1,
          text : "Sub-item with children",
          children : [
            { id: 2, text : "sub-sub-item 1" },
            { id : 3, text : "sub-sub-item 2" }
          ]
        }]
      })
      setSelected([{ id : 3, text : "sub-sub-item 2" }])
    }}>Modify items and selected</BAButton>
    <BAThingPicker
      items    = { items }
      selected={ selected }
      expanded={ expandend }
      onChange = { () => {} }
      confirm  = { () => {} }
      cancel   = { () => {} }
    />
  </div>
}