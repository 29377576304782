/**
 * 
 * this helper outputs a 3-deep nested tree consisting of 1900+ items
 */
export const getLargePickerTree = () => {
  const root = {
    id :"root",
    children: []
  }
  const randSTR =  '0dVBNVdfqweqjjjj87DFSOJKASKsqwqweqr0pcxv9870erfms690SDFS'

  const randChar = (n) => randSTR[n % randSTR.length]

  for (let i = 0; i < 20; i++) {
    root.children.push({
      id: `${i}`,
      text: `${i}-item-foo-${ randChar(i) }`,
      children: []
    })

    for (let s = 0; s < 20; s++) {

      root.children[i].children.push({
        id: `${i}-${s}`,
        text: `${i}-${s}-item-bar-${ randChar(s) }`,
        children: []
      })
      for (let h = 0; h < 20; h++) {

        root.children[i].children[s].children.push({
          id: `${i}-${s}-${h}`,
          text: `${i}-${s}-${h}-item-impression-${ randChar(h) }`
        })
      }
    }
  }

  return root
}

window.glt = getLargePickerTree