/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import {Fragment}                        from 'react'
import Example                           from '../lib/example'
import { NotificationDisplay, MessageDispatcher, BAButton, InputGroup } from '../../lib/index.ts'

export const meta = {
  name: "Notifications",
  category: "Components",
  tags: [],
  components: ["NotificationDisplay", "MessageDispatcher", "BAButton", "InputGroup"],
  functions: [],
  values: {
    msg: ""
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Notifications`}</h1>
    <p>{`We can display notifications using a `}<inlineCode parentName="p">{`NotificationDisplay`}</inlineCode>{` component. This can take a `}<inlineCode parentName="p">{`MessageDispatcher`}</inlineCode>{` as a prop, or will use the singleton one to subscribe to messages.`}</p>
    <h2>{`Message passing`}</h2>
    <p>{`Anything wishing to display a notification can dispatch a message via the either the singleton `}<inlineCode parentName="p">{`MessageDispatcher`}</inlineCode>{`, or the `}<inlineCode parentName="p">{`MessageDispatcher`}</inlineCode>{` instance you have passed to your `}<inlineCode parentName="p">{`<NotificationDisplay />`}</inlineCode>{` instance. You can get the singleton `}<inlineCode parentName="p">{`MessageDispatcher`}</inlineCode>{` by calling the static method `}<inlineCode parentName="p">{`MessageDispatcher.getSingletonInstance()`}</inlineCode></p>
    <p>{`A `}<inlineCode parentName="p">{`MessageDispatcher`}</inlineCode>{` will pass anything around your app that you want it to, but `}<inlineCode parentName="p">{`<NotificationDisplay />`}</inlineCode>{` expects messages to be objects with the following properties:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`optional`}</em>{` the `}<inlineCode parentName="td">{`number`}</inlineCode>{` of milliseconds you want the message to display. Pass 0 to keep the message alive until manually cleared`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`required`}</em>{` The content of the message: `}<inlineCode parentName="td">{`any valid React child`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`optional`}</em>{` `}<inlineCode parentName="td">{`function`}</inlineCode>{` -- what to do if the message is clicked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`iconClass`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`optional`}</em>{` `}<inlineCode parentName="td">{`string`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Notification display`}</h2>
    <p>{`Notification display is via the `}<inlineCode parentName="p">{`<NotificationDisplay />`}</inlineCode>{` component. This component takes two props:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Role`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`messageEmitter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`MessageDispatcher`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`optional`}</em>{` this is the dispatcher to which the notification display will subscribe. It normalises to the singleton `}<inlineCode parentName="td">{`MessageDispatcher.getSingletonInstance()`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`maxMessageCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`optional`}</em>{` the maximum number of notifications to display at a given time. Subsequent notifications will be queued.and displayed as previously-displayed notifications are cleared`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Examples`}</h2>
    <p>{`Here we render a `}<inlineCode parentName="p">{`<NotificationDisplay />`}</inlineCode>{` that will raise a default subscription to `}<inlineCode parentName="p">{`MessageDispatcher.getSingletonInstance()`}</inlineCode></p>
    <Example twoColumn mdxType="Example">
  <NotificationDisplay maxMessagesCount={1} defaultDuration={6000} mdxType="NotificationDisplay" />
    </Example>
    <h3>{`Sending a message from a text input`}</h3>
    <InputGroup mdxType="InputGroup">
      <label htmlFor="notifier">Press enter to send input content to notification</label>
      <input id="notifier" onChange={e => props.set("msg", e.target.value)} value={props.get("msg")} onKeyUp={e => {
        if (e.key == "Enter") {
          MessageDispatcher.getSingletonInstance().dispatch({
            message: props.get("msg")
          });
          props.set("msg", "");
        }
      }} />
    </InputGroup>
    <p>{`the keyup action here looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{` e => {
      if (e.key == "Enter") {
        MessageDispatcher.getSingletonInstance().dispatch({message : e.target.value})
        e.target.value = ""
      }
    }
`}</code></pre>
    <h3>{`Sending a react fragment as a notification`}</h3>
    <BAButton onClick={() => MessageDispatcher.getSingletonInstance().dispatch({
      message: <Fragment mdxType="Fragment">
        Go learn about <a href="/styleguide_pages_colours">Colours!</a>
      </Fragment>
    })} mdxType="BAButton">
  Show a notification with a link
    </BAButton>
    <p>{`The onclick action here looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`() => MessageDispatcher.getSingletonInstance().dispatch({ 
    message : (
      <Fragment>
        Go learn about <a href="/colours">Colours!</a>
      </Fragment>
    )
  })
`}</code></pre>
    <h3>{`Custom styling & positioning for your notification`}</h3>
    <p>{`Because these are created using styled-components in order to style you have to use styled-components
to override the default styles.`}</p>
    <h4>{`For positioning the notifications`}</h4>
    <p>{`by default the component is styled with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`position: fixed;
top: 0;
right: 0;
width: 350px;
`}</code></pre>
    <p>{`In order to override these styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { css } from 'styled-components';

<Example twoColumn>
  <NotificationDisplay 
    notificationDisplayCss={css\`
      right: 0;
      top: 50px;
    \`}
    maxMessagesCount={1} 
    defaultDuration={6000}
  />
</Example>

`}</code></pre>
    <h4>{`For colouring the notification`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`() => MessageDispatcher.getSingletonInstance().dispatch({
    notificationCss: css\`
      background: green;
      color: white;
    \`,
    message : (
      <Fragment>
        Go learn about <a href="/colours">Colours!</a>
      </Fragment>
    )
  })
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
