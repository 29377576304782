/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from "../lib/example";
import Icon from "/lib/components/Icon/Icon";

export const meta = {
  name: "Icon",
  category: "Components",
  tags: [],
  components: ["Icon"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Icon`}</h1>
    <p>{`Render an icon like this`}</p>
    <p><inlineCode parentName="p">{`<Icon name="fa fa-thumbs-down" />`}</inlineCode></p>
    <p>{`instead of this`}</p>
    <p><inlineCode parentName="p">{`<i className="fa fa-thumbs-down" />`}</inlineCode></p>
    <p>{`Or pass the `}<inlineCode parentName="p">{`useSvg`}</inlineCode>{` prop to render one of the SVG icons included in `}<inlineCode parentName="p">{`/styleguide/styles/svg-icons/icons.svg`}</inlineCode></p>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`required?`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`FontAwesome or BA Icon className, OR fragment ID for SVG icons`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`specify your icon size using our size scales. `}<br />{` must be one of case "micro", "small", "sm-md", "medium", "large", or "jumbo"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`useSvg`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`look for a matching fragment ID in the SVG spritesheet at `}<inlineCode parentName="td">{`/styleguide/styles/svg-icons/icons.svg`}</inlineCode>{`, instead of creating a class-based fontawesome style icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`customColor`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`name of a CSS Custom Property for the colour you want to use, e.g. `}<inlineCode parentName="td">{`--brand-6`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`styles`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React.CSSProperties`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`directly apply styles to the component`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Basic Examples`}</h2>
    <Example twoColumn mdxType="Example">
  <Icon size="large" name="fas fa-address-book" mdxType="Icon" />
    </Example>
    <Example twoColumn mdxType="Example">
  <Icon size="large" name="fas fa-adjust" mdxType="Icon" />
    </Example>
    <Example twoColumn mdxType="Example">
  <Icon size="large" name="fas fa-anchor" mdxType="Icon" />
    </Example>
    <h2>{`SVG Examples`}</h2>
    <p>{`⚠️ the up/down and right/left chevrons have been designed at such drastically different line weights which looks weird to me! I think we should review these SVG icons before using them widely ⚠️`}</p>
    <Example twoColumn mdxType="Example">
  <Icon useSvg size="jumbo" name="icon-angle-right" mdxType="Icon" />
    </Example>
    <Example twoColumn mdxType="Example">
  <Icon useSvg size="jumbo" name="icon-chevron-up" mdxType="Icon" />
    </Example>
    <h2>{`Available SVG names`}</h2>
    <ul>
      <li parentName="ul">{`icon-angle-down`}</li>
      <li parentName="ul">{`icon-angle-right`}</li>
      <li parentName="ul">{`icon-angle-left`}</li>
      <li parentName="ul">{`icon-angle-up`}</li>
      <li parentName="ul">{`icon-check`}</li>
      <li parentName="ul">{`icon-check-circle-o`}</li>
      <li parentName="ul">{`icon-times-circle-o`}</li>
      <li parentName="ul">{`icon-times`}</li>
      <li parentName="ul">{`icon-chevron-up`}</li>
      <li parentName="ul">{`icon-chevron-down`}</li>
    </ul>
    <h2>{`To do`}</h2>
    <ul>
      <li parentName="ul">{`write instructions for adding new SVG icons to the set`}</li>
      <li parentName="ul">{`review existing SVG icons`}</li>
      <li parentName="ul">{`review this component -- does it offer us enough advantage over and above webfont based icon usage to be worth maintaining? If we get into lots of custom SVG icons that'll be a yes.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
