/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example     from '../lib/example'
import { TestComponent, TestTwo } from './HOCWithLoadingDisplayHelpers'

export const meta = {
  name: "Loading HOCs",
  category: "HOCs, Hooks, & Functions",
  tags: [],
  components: [],
  functions: ["withLoadingDisplay", "withConfiguredLoadingDisplay"],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Higher Order Components (HOC's)`}</h1>
    <p>{`Higher order components wrap regular components extending them with extra functionality`}</p>
    <p>{`e.g.`}</p>
    <pre><code parentName="pre" {...{}}>{`const TestComponent = withLoadingDisplay(
  () => <h2>Test Component</h2>
);
`}</code></pre>
    <p>{`or using decorator syntax to wrap a class:`}</p>
    <pre><code parentName="pre" {...{}}>{`@withLoadingDisplay
class TestComponent extends PureComponent {
  //...etc
}
`}</code></pre>
    <h2>{`withLoadingDisplay`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`type LoadingPropOptions = {
  displayMessage?: boolean,
  displaySpinner?: boolean,
  spinner?: boolean,
  message?: string
};
`}</code></pre>
    <h3>{`Basic {loading: true (boolean)}`}</h3>
    <TestComponent loading mdxType="TestComponent" />
    <h3>{`Custom text {loading: 'Custom message text' (string)}`}</h3>
    <TestComponent loading="Custom message text" mdxType="TestComponent" />
    <h3>{`Custom object no spinner {loading: { displaySpinner: false }}`}</h3>
    <TestComponent loading={{
      displaySpinner: false
    }} mdxType="TestComponent" />
    <h2>{`withConfiguredLoadingDisplay`}</h2>
    <p>{`this is a HOC that is configured via an initial argument, e.g.`}</p>
    <pre><code parentName="pre" {...{}}>{`const TestTwo = withConfiguredLoadingDisplay({ 
    animation: "trend-line", 
    displayMessage: false 
  })(
    () => <h2>Test Component</h2>
  );
`}</code></pre>
    <p>{`or using decorator syntax`}</p>
    <pre><code parentName="pre" {...{}}>{`const config = { 
  animation: "trend-line", 
  timeout: 0
}

@withConfiguredLoadingDisplay(config)
class TestTwo extends PureComponent {
  // ...etc
}
`}</code></pre>
    <h2>{`passing loading: true to a component with a configured loading spinner`}</h2>
    <Example twoColumn mdxType="Example">
  <TestTwo loading={true} mdxType="TestTwo" />
    </Example>
    <h2>{`passing a string to `}<inlineCode parentName="h2">{`props.loading`}</inlineCode>{` for a component with a configured loading spinner`}</h2>
    <Example twoColumn mdxType="Example">
  <TestTwo loading="You can pass a string as a loading message" mdxType="TestTwo" />
    </Example>
    <h2>{`passing an object to `}<inlineCode parentName="h2">{`props.loading`}</inlineCode>{` for a component with a configured loading spinner to extend / override the configuration`}</h2>
    <Example twoColumn mdxType="Example">
  <TestTwo loading={{
        displayMessage: false
      }} mdxType="TestTwo" />
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
