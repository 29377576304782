import React, { Fragment } from 'react';
import styled from 'styled-components';

class ConsumableString {
  constructor(str) {
    this.stack = str.split("").reverse()
  }
  take(n) {
    let count = n
    let result = ""
    while (count > 0) {
      const char = this.stack.pop()
      if (!char) break
      result += char
      count--
    }
    return result
  }
}
const sprinkle = (arr, confetti) => arr.reduce(
  (accumulator, item, index, arr) => {
    if (accumulator.length) accumulator.push(confetti)
    accumulator.push(item)
    return accumulator
  }, 
  []
)

const Highlight = styled.span`
  display: inline;
  text-decoration: underline;
  text-decoration-color: var(--warning-light);
`

export const SearchTermHighlighter = ({ text, searchTerm }) => {

  if (!searchTerm) return text

  const lcText = text.toLowerCase()
  const lcSearchTerm = searchTerm.toLowerCase()

  if (!lcText.includes(lcSearchTerm)) return text

  const readout = new ConsumableString(text)
  return <Fragment>
    {sprinkle(lcText.split(lcSearchTerm), { highlight: true })
      .map((segment, index) => segment.highlight
        ? <Highlight key={lcSearchTerm + index}>{readout.take(searchTerm.length)}</Highlight>
        : segment.length ? <span key={segment + index}>{readout.take(segment.length)}</span> : null)}
  </Fragment>
}