/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Page from '../../lib/components/page'

export const meta = {
  name: "Page",
  category: "Components",
  tags: [],
  components: ["Page"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Page`}</h1>
    <p>{`A component for a page of content`}</p>
    <Page className="pad" mdxType="Page">
      <p>{`  The `}<inlineCode parentName="p">{`Page`}</inlineCode>{` component is a big white div. It is width-restricted, unless you pass a `}<inlineCode parentName="p">{`fullWidth`}</inlineCode>{` prop in.`}</p>
    </Page>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
