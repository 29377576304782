export const initState = (maxMessageCount = 5) => ({
  maxMessageCount,
  messages: [],
  messageMeta: new WeakMap(),
  queuedMessages: []
})

function addMessage(state, message) {
  state.messages.push(message)
  state.messageMeta.set(message, {
    timeRemaining:
      message.duration != null
        ? message.duration
        : state.defaultDuration || 12000,
    className: "visible",
    running: message.duration !== 0
  })
  return { ...state }
}

function clearMessage(state, message) {
  const { maxMessageCount } = state
  state.messages = state.messages.filter(m => m != message)
  // if there are queued messages show the next queued message now that we have an available slot
  if (state.queuedMessages.length && state.messages.length < maxMessageCount) {
    state = addMessage(state, state.queuedMessages.pop())
  }
  return { ...state }
}

function queueMessage(state, message) {
  state.queuedMessages.unshift(message)
  return { ...state }
}

function tick(state) {
  const { messages } = state
  for (const message of messages) {
    const meta = state.messageMeta.get(message)
    if (meta.running) {
      meta.timeRemaining = meta.timeRemaining - 10
      if (meta.timeRemaining <= 310) meta.className = "fade"
      if (meta.timeRemaining <= 0) state = clearMessage(state, message)
    }
  }
  return { ...state }
}

function pauseMessage(state, message) {
  const currentMessageState = state.messageMeta.get(message)
  // if the message already isn't running, return state unaltered.
  if (currentMessageState.running == false) return state

  const meta = {
    ...currentMessageState,
    running: false
  }
  if (meta.timeRemaining < 310) meta.timeRemaining = 310
  state.messageMeta.set(message, meta)
  return { ...state }
}

function runMessage(state, message) {
  const currentMessageState = state.messageMeta.get(message)
  if (currentMessageState.running == true) return state
  const meta = {
    ...currentMessageState,
    running: true
  }
  state.messageMeta.set(message, meta)
  return { ...state }
}

export const actionReducer = (state, action) => {
  switch (action.type) {
    case "tick":
      return tick(state)

    case "clearMessage":
      return clearMessage(state, action.payload)

    case "addMessage":
      if (state.messages.length < state.maxMessageCount) {
        return addMessage(state, action.payload)
      } // otherwise fall through and queue the message

    case "queueMessage":
      return queueMessage(state, action.payload)

    case "pauseMessage":
      return pauseMessage(state, action.payload)

    case "runMessage":
      return runMessage(state, action.payload)

    default:
      console.warn("Action not recognised", action)
      return state
  }
}
