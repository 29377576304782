/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Breadcrumbs from '/lib/components/breadcrumbs'
import StyledNav   from '/lib/components/header'
import Example     from '../lib/example'

export const meta = {
  name: "Header",
  category: "Components",
  tags: [],
  components: ["StyledNav", "BreadCrumbs"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`NOTE`}</h1>
    <p>{`The relative position style on the StyledNav components in this page is purely to allow it to flow
within the page. The defaule style for header position is `}<inlineCode parentName="p">{`fixed`}</inlineCode></p>
    <Example mdxType="Example">
  <StyledNav style={{
        position: 'relative',
        note: 'RELATIVE POSITIONING FOR THIS PAGE ONLY!!!'
      }} mdxType="StyledNav">
    <Breadcrumbs breadcrumbs={[{
          label: 'Breadcrumb Cog',
          icon: 'fa fa-cog'
        }, {
          label: 'Mooar Cog',
          icon: 'fa fa-cog'
        }, {
          label: 'the end my friend'
        }]} mdxType="Breadcrumbs" />
  </StyledNav>
    </Example>
    <h1>{`Application Header Bar — `}<inlineCode parentName="h1">{`<StyledNav />`}</inlineCode></h1>
    <p>{`a 45px high fixed nav element, used as the header element on bright-ux and bright-auth`}</p>
    <p>{`You can put the `}<inlineCode parentName="p">{`<Breadcrumbs />`}</inlineCode>{` component inside it, etc.`}</p>
    <p>{`You can also include other text which is not a breadcrumb component and acts more like a page, this will
inherit the same left hand smargin as the Breadcrumbs, as it is provided by the Header component
It is up to the text used here to provide its own styles`}</p>
    <Example mdxType="Example">
  <StyledNav style={{
        position: 'relative',
        note: 'RELATIVE POSITIONING FOR THIS PAGE ONLY!!!'
      }} mdxType="StyledNav">
    <h2 className="text-small" style={{
          color: 'var(--white)',
          opacity: '0.8'
        }}>Some very nice feature page!</h2>
  </StyledNav>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
