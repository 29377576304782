/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example      from '../lib/example'
import {BAButton}   from '/lib/components/buttons'

export const meta = {
  name: "Spacing",
  category: "Style Guide",
  tags: [],
  components: [],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Spacing`}</h1>
    <p>{`Elements should be styled in such a way that they are responsible for their internal spacing.`}</p>
    <p>{`Except for some rare cases, external spacing should be handled by the containing element.`}</p>
    <p>{`So, for example, a styled button component should have all of the rules necessary to lay out its internal content, ensure that it has enough padding, etc.`}</p>
    <p>{`It should not make any assumptions about its external spacing (margins, alignment, etc).`}</p>
    <p>{`We can add spacing and alignment to them as needed using simple classes like `}{`\``}{`.margin`}{`\``}{` or `}{`\``}{`.float-right`}{`\``}</p>
    <p>{`Spacing should be specified in `}{`\``}{`rem`}{`\``}{`s, to help maintain vertical typographic rhythm.`}</p>
    <p>{`In the following example, we can see that the bootstrap buttons don't imply any margin, so they sit up against each other.`}</p>
    <p>{`If the buttons all had a default margin, it would be difficult to get the first and last buttons to align properly within their container, and things would get complicated with floats.`}</p>
    <Example twoColumn mdxType="Example">
  <p>These buttons don't have any margin, but they pad their content properly.</p>
  <BAButton className="margin-right" mdxType="BAButton">one</BAButton>
  <BAButton className="margin-right" mdxType="BAButton">two</BAButton>
  <BAButton className="margin-right" mdxType="BAButton"><i className="fas fa-alarm-clock margin-right" />three</BAButton>
  <BAButton className="float-right" mdxType="BAButton">four</BAButton>
    </Example>
    <p>{`This example uses margin classes to add a little spacing where we need it.`}</p>
    <Example twoColumn mdxType="Example">
  <BAButton className=" margin-right" mdxType="BAButton">one</BAButton>
  <BAButton className=" margin-right" mdxType="BAButton">two</BAButton>
  <BAButton className="" mdxType="BAButton"><i className="fas fa-alarm-clock margin-right" />three</BAButton>
  <BAButton className=" float-right" mdxType="BAButton">four</BAButton>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
