// there can only be one
let dispatchSingleton = null

/**
 * a dispatcher for sending messages about the place
 */
class MessageDispatcher {
  /**
   * get a singleton instance of the message queue
   */
  static getSingletonInstance() {
    if (dispatchSingleton === null) dispatchSingleton = new MessageDispatcher()
    return dispatchSingleton
  }

  subscriptions = new Set()

  /**
   * clear all subscriptions
   */
  clear() {
    this.subscriptions = new Set()
  }

  /**
   * register a message handler
   * @param {onMessage callback} handler
   */
  subscribe(handler) {
    if (typeof handler != "function")
      throw new Error("MessageDispatch subscriptions must be functions")
    this.subscriptions.add(handler)
  }

  /**
   * deregister a message handler
   * @param {onMessage callback} handler
   */
  unsubscribe(handler) {
    this.subscriptions.delete(handler)
  }

  /**
   * dispatch a message.
   * @param {Any} message
   */
  dispatch(message) {
    for (const pass of this.subscriptions) {
      pass(message)
    }
  }
}

export default MessageDispatcher
