/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example.jsx'
export const meta = {
  name: "Typography",
  category: "Style Guide",
  tags: ["fonts", "typefaces"],
  components: [],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Typography`}</h1>
    <p>{`Bright Analytics uses Proxima Nova Alt in most places, and Proxima Nova for tabular numerals.
We also use Courier New for inputs in admin screens.`}</p>
    <p><em parentName="p">{`do not introduce new fonts or font sizes to the project!`}</em></p>
    <h3>{`Font-styles & Weights`}</h3>
    <p>{`We have the following font styles in the project:`}</p>
    <Example twoColumn mdxType="Example">
  <p className="font-regular">regular</p>
  <p className="font-medium">medium</p>
  <p className="font-semibold">semibold</p>
  <p className="font-italic">italic</p>
  <p className="font-tabular">tabular</p>
    </Example>
    <p>{`You can use them via class name as above, or by using the following CSS custom properties in other component styles.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.regular-thing {
  font-family: var(--font-regular-font-family);
  //.. other styles
}
.medium-thing {
  font-family: var(--font-medium-font-family);
  //.. other styles
}
.semibold-thing {
  font-family: var(--font-semibold-font-family);
  //.. other styles
}
.italic-thing {
  font-family: var(--font-italic-font-family);
  //.. other styles
}
.tabular-thing {
  font-family: var(--font-tabular-font-family);
  //.. other styles
}
`}</code></pre>
    <p>{`You should avoid using too many different font styles too close together, reserving italic, medium, and semibold weights for when we need to introduce contrast or emphasis. `}</p>
    <p>{`The `}<inlineCode parentName="p">{`tabular`}</inlineCode>{` style provides fixed-width numerals, for use in tables or other situations where it's useful to rely on character spacing to compare indices. `}</p>
    <h3>{`Classes & Mixins`}</h3>
    <p>{`here are your font-weight classes - you can use these in your markup to set text styles.
If you're writing some custom styles or classes for your components, you can use these mixins.`}</p>
    <Example twoColumn mdxType="Example">
  <p className="font-regular">This is a paragraph in the regular font.</p>
  <p className="font-medium">This is a paragraph in the medium font</p>
  <p className="font-semibold">this is a paragraph in the semibold font</p>
  <p className="font-italic">This is a paragraph in the italic font</p>
  <p>This number is contained within a span that uses the tabular variant of the font, which has fixed-width numerals <span className="font-tabular">1,002,361</span></p>
    </Example>
    <h2>{`Headings`}</h2>
    <p>{`Headings 1 through 6 should be used to signify content at their relevant heirarchy.`}</p>
    <p>{`They should `}<em parentName="p">{`not`}</em>{` necessarily imply a particular style. Don't choose a heading just to make text bold. And don't avoid a heading just because you don't want text to be bold.`}</p>
    <p>{`There should only be one h1 element on any given page, and it should show the title of the page content.`}</p>
    <p>{`h2 and h3 elements can be used for sub-section headings.`}</p>
    <p>{`If you use an h1, try to make sure it helps to orient the user as to the the purpose of the page they are on.`}</p>
    <p>{`Heading levels 2 through 6 are all presented at the same size by default.
Override this contextually if you need to, using the font size classes listed below.`}</p>
    <Example twoColumn mdxType="Example">
  <h1>This is top level heading (h1)</h1>
  <h2>This is a second level heading (h2)</h2>
  <h3>This is a third level heading (h3)</h3>
  <h4>This is a fourth level heading (h4)</h4>
  <h5>This is a fifth level heading (h5)</h5>
  <h6>This is a sixth level heading (h6)</h6>
    </Example>
    <h2>{`Typographic Scale`}</h2>
    <p>{`We have five font sizes in our typographic scale:`}</p>
    <Example twoColumn mdxType="Example">
  <p className="text-micro">micro</p>
  <p className="text-small">small</p>
  <p className="text-medium">medium</p>
  <p className="text-large">large</p>
  <p className="text-jumbo">jumbo</p>
    </Example>
    <p>{`You can use these using the above utility classes, or by including the relevant css custom properties in your components. This scale is defined in the `}<inlineCode parentName="p">{`_typography.scss`}</inlineCode>{`.`}</p>
    <h3>{`Using CSS Custom Properties to set font size in a component.`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.micro-panel {
  font-size: var(--text-size-micro);
  line-height: var(--text-line-height-micro)
}
`}</code></pre>
    <p>{`the following CSS variables map to the five sizes. Note that an alternative line-height is supplied for buttons, or other components requiring greater spacing around text.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`
:root {
  --text-size-micro           : 0.7rem;
  --text-line-height-micro    : 0.8rem;
  --button-line-height-micro  : 1rem;
  
  --text-size-small           : 0.8rem;
  --text-line-height-small    : 1.2rem;
  --button-line-height-small  : 1.5rem;
  
  --text-size-medium          : 1rem;
  --text-line-height-medium   : 1.2rem;
  --button-line-height-medium : 2rem;
  
  --text-size-large           : 1.5rem;
  --text-line-height-large    : 2rem;
  --button-line-height-large  : 3rem;
  
  --text-size-jumbo           : 2rem;
  --text-line-height-jumbo    : 3rem;
  --button-line-height-jumbo  : 4rem;
}
`}</code></pre>
    <p>{`You can override these variables at a component or application level to introduce themes. I would avoid overriding them at the comoponent level, unless you're implementing something that provides user control over font styles. `}</p>
    <h2>{`Vertical Rhythm & Line Height`}</h2>
    <p>{`You'll note that line-heights are tidy multiples of 1rem. This means that e.g. every 5 lines of micro text, you'll get a line that perfectly lines up with an adjacent line of regular text.`}</p>
    <p>{`We should aim to avoid situations where that "meshing" of lines in different type scales is too irregular.`}</p>
    <h2>{`Font family rules`}</h2>
    <p>{`You can use font family to address rules for bold, italic, reguilar, thin and tabular`}</p>
    <Example twoColumn mdxType="Example">
  <p className="text-medium font-regular">font regular</p>
  <p className="text-medium font-semibold">font semibold</p>
  <p className="text-medium font-italic">font italic</p>
  <p className="text-medium font-thin">font thin</p>
  <p className="text-medium font-tabular">font tabular</p>
    </Example>
    <p>{`r`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
