import React from 'react'
import styled from 'styled-components'


export const BAGrid = styled.div`
  display    : grid;
  column-gap : 1rem;
  row-gap    : 0.5rem;
  grid-template-columns: ${props => props.columns || 'repeat(12, 1fr)'};
`

export const BACell = styled.div`
  grid-column : ${props => props.cols};
  ${props => props.rowStart ? `grid-row-start: ${props.rowStart};` : ''}
`
