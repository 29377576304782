/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import { TabRegion } from "../../lib/components/tabbed-ui/TabbedRegion.tsx";
import { Tab } from "../../lib/components/tabbed-ui/Tab.tsx";
import Example from "../lib/example";

export const meta = {
  name: "Tabbed Region",
  category: "Components",
  tags: [],
  components: ["Tab", "TabRegion"],
  functions: [],
  values: {
    activeTabIndex: 0
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tabbed UI Regions`}</h1>
    <p>{`You can use the `}<inlineCode parentName="p">{`<TabRegion />`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Tab />`}</inlineCode>{` components together to render a tabbed region.`}</p>
    <p>{`There are only two rules:`}</p>
    <ol>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`<Tab />`}</inlineCode>{` components as direct children of your `}<inlineCode parentName="li">{`<TabRegion />`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<Tab />`}</inlineCode>{` components have to have a `}<inlineCode parentName="li">{`name`}</inlineCode>{` prop, containing a string`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`null`}</inlineCode>{` expressions can also be passed as direct children of `}<inlineCode parentName="li">{`<TabRegion />`}</inlineCode></li>
    </ol>
    <p>{`You can also pass a `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop to each tab if you want it to display in the tab-list, but remain inactive`}</p>
    <p>{`Here's an example showing all these features.`}</p>
    <Example mdxType="Example">
  <TabRegion mdxType="TabRegion">
    <Tab name="One" mdxType="Tab">This is the content for Tab one</Tab>
    <Tab name="Two" mdxType="Tab">
      <article>
        <h2>This is a more complex set of tab content</h2>
        <p>
          Eventually, you do plan to have dinosaurs on your dinosaur tour,
          right? Just my luck, no ice. You know what? It is beets. I've crashed
          into a beet truck. Checkmate... I gave it a cold? I gave it a virus. A
          computer virus. Yeah, but John, if The Pirates of the Caribbean breaks
          down, the pirates don’t eat the tourists.
        </p>
        <p>
          Hey, you know how I'm, like, always trying to save the planet? Here's
          my chance. Life finds a way. Hey, you know how I'm, like, always
          trying to save the planet? Here's my chance. This thing comes fully
          loaded. AM/FM radio, reclining bucket seats, and... power windows.
        </p>
        <p>
          God help us, we're in the hands of engineers. Just my luck, no ice.
          Hey, take a look at the earthlings. Goodbye! I gave it a cold? I gave
          it a virus. A computer virus. Hey, take a look at the earthlings.
          Goodbye! Hey, you know how I'm, like, always trying to save the
          planet? Here's my chance.
        </p>
      </article>
    </Tab>
    <Tab name="Three" mdxType="Tab">This tab has less stuff</Tab>
    {null}
    <Tab name="disabled tab" disabled mdxType="Tab">
      You shouldn't ever be able to see this content
    </Tab>
  </TabRegion>
    </Example>
    <h2>{`Styling with CSS Custom properties`}</h2>
    <p>{`The TabRegion uses the following CSS Custom Properties to style itself.`}</p>
    <p>{`Aside from where defaults are provided, these properties are part of our theme / colour set, so the component will nicely reflect any changes to application theme (hello night mode, etc).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`--border-radius`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sets the border radius on the curvy parts of the tab region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3px.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`--border-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--text-main`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`--content-bg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background for the active tab and content part of the tab region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`--bg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background for inactive tabs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`--highlight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tab hover background colour`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`--text-dark-bg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tab hover text colour`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`--text-grey`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text and border color for disabled tabs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`The TabRegion component accepts a className prop to set the class name of its first container, for styling purposes`}</p>
    <h2>{`Changing the tab that initially appears active`}</h2>
    <p>{`You can pass an `}<inlineCode parentName="p">{`initiallyActiveTab`}</inlineCode>{` prop to set which tab is active when the component renders`}</p>
    <Example mdxType="Example">
  <TabRegion initiallyActiveTab={2} mdxType="TabRegion">
    <Tab name="dogs" mdxType="Tab">
      <img width="100%" height="auto" src="http://www.bassethoundsrunning.com/wp-content/uploads/2013/01/basset_hound_running_0037update.jpg" />
    </Tab>
    <Tab name="cats" mdxType="Tab">
      <img width="100%" height="auto" src="https://i.pinimg.com/736x/9c/65/72/9c6572022945ed5c9578f044189fac9a.jpg" />
    </Tab>
    <Tab name="marmosets" mdxType="Tab">
      <iframe width="100%" height="640px" src="https://www.youtube.com/embed/4oiLfTnrC40" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </Tab>
  </TabRegion>
    </Example>
    <h2>{`Skipping render of non active tabs`}</h2>
    <p>{`You can pass a `}<inlineCode parentName="p">{`renderActiveOnly`}</inlineCode>{` prop to get the tabbed region to render only the active tab content, instead of all tabs content at once. You can see via dom inspector that only the active tab is renered`}</p>
    <Example mdxType="Example">
  <TabRegion initiallyActiveTab={2} renderActiveOnly={true} mdxType="TabRegion">
    <Tab name="london" mdxType="Tab">
      <h2>I am in London</h2>
    </Tab>
    <Tab name="paris" mdxType="Tab">
      <h2>I am in Paris</h2>
    </Tab>
    <Tab name="milano" mdxType="Tab">
      <h2>I am in Milano</h2>
    </Tab>
  </TabRegion>
    </Example>
    <h3>{`Controlled Tab Region`}</h3>
    <p>{`If you need to control the active tab region from outside the component (e.g. to sync it with a URL param), you can pass an `}<inlineCode parentName="p">{`activeTabIndex`}</inlineCode>{` and `}<inlineCode parentName="p">{`onChangeTabIndex`}</inlineCode>{` prop to the TabRegion component.`}</p>
    <p>{`This will make the component behave as a controlled component.`}</p>
    <Example mdxType="Example">
  <TabRegion activeTabIndex={props.get("activeTabIndex")} onChangeTabIndex={i => {
        props.set("activeTabIndex", i);
      }} mdxType="TabRegion">
    <Tab name="edinburgh" mdxType="Tab">
      <h2>I am in edinburgh</h2>
    </Tab>
    <Tab name="barcelona" mdxType="Tab">
      <h2>I am in barcelona</h2>
    </Tab>
    <Tab name="toronto" mdxType="Tab">
      <h2>I am in toronto</h2>
    </Tab>
  </TabRegion>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
