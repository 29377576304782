/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example                  from '../lib/example'
import { BAPaginationExample }    from '../../lib/components/Pagination/Pagination'

export const meta = {
  name: "Pagination Widget",
  category: "Components",
  tags: [],
  components: ["BAPagination"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BAPagination `}<br />{` `}<small>{`Pagination component to allow users to select which page they're viewing`}</small></h1>
    <p>{`This is a function component which takes three props:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`activePage`}</inlineCode>{` - the current active page`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`totalPages`}</inlineCode>{` - the total number of pages available`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`onPageChange`}</inlineCode>{` - the function to be called when the page changes. The new page number is passed as an argument "page"`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`  const BAPaginationExample = () => {

    // We could handle active/total pages with state
    const [exampleActivePage, setExampleActivePage] = useState(1)
    const [exampleTotalPages, setExampleTotalPages] = useState(15)

    // function to handle the page change, passed the new page number by BAPagination
    const onPageChange = (page) => {
      setExampleActivePage(page)
    }

    // call the BAPagination component with props
    return (
      <BAPagination
        activePage={exampleActivePage}
        totalPages={exampleTotalPages}
        onPageChange={onPageChange}
      />
    )
  }
`}</code></pre>
    <Example mdxType="Example">
  <BAPaginationExample mdxType="BAPaginationExample" />
    </Example>
    <p>{`The pagination component autocloses page selection options when clicking away.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
