/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import { useEffect, useState } from "react"
import Example from "../lib/example";
import { SpinnerDemo } from './loadspinner-helpers'
export const meta = {
  name: "Loading Spinner",
  category: "Components",
  tags: [],
  components: ["LoadSpinner"],
  functions: [],
  values: {}
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const LoadSpinner = makeShortcode("LoadSpinner");
const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`SVG Element for Loading Screens`}</h1>
    <p>{`This is used internally in `}<inlineCode parentName="p">{`withSpinner`}</inlineCode>{` hoc, as well as directly in various places around the dashboard app`}</p>
    <p>{`If you find yourself needing to handle a loading prop, you might want to take a look at the `}<inlineCode parentName="p">{`withSpinner`}</inlineCode>{` HOC first`}</p>
    <h2>{`props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`how many milliseconds should we wait before displaying the message? `}<br />{` useful if you only want to display a message if things are taking a long time `}<br />{` defaults to 3000. Pass 0 to disable the timeout`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string\``}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`animation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`which animation should we use? options are `}<inlineCode parentName="td">{`spinner`}</inlineCode>{`, `}<inlineCode parentName="td">{`ellipsis`}</inlineCode>{` and `}<inlineCode parentName="td">{`trend-line`}</inlineCode>{`. (`}<inlineCode parentName="td">{`spinner`}</inlineCode>{` is the default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`displayMessage`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`should we show a message string?`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`displaySpinner`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`should we show the spinner?`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`messageText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a text message to display. defaults to "loading. please do not refresh the page"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`noPadding`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`should we add padding to the loader? (2rem is the default)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Usage`}</h2>
    <p>{`Here is an example with all the default props`}</p>
    <Example twoColumn mdxType="Example">
  <LoadSpinner mdxType="LoadSpinner" />
    </Example>
    <p>{`Here is an example with a size and custom timeout`}</p>
    <Example twoColumn mdxType="Example">
  <LoadSpinner size={100} timeOut={5000} mdxType="LoadSpinner" />
    </Example>
    <p>{`Here's an example with no timeout, using the trend-line animation.
A fresh trend-line is generated on each initial render. Reload the page to get another one
They're always positive, of course. `}</p>
    <SpinnerDemo animation="trend-line" timeout={0} mdxType="SpinnerDemo" />
    <p><strong parentName="p">{`Minimal size`}</strong></p>
    <p>{`If you need the spinner to display in a smaller space, you can remove both the display message and padding:`}</p>
    <Example twoColumn mdxType="Example">
  <table>
    <tr>
      <th>Smol spinner:</th>
      <th><LoadSpinner animation="ellipsis" size={12.8} displayMessage={false} noPadding={true} mdxType="LoadSpinner" /></th>
    </tr>
  </table>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
