/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import { 
  FlexContainer,
  Grid,
  InputGroup,
  InputGroupWithErrorMessage,
  Select,
  BARadio,
  BACheck,
  BARadioGroup } from "/lib/index";
import Example from "/styleguide/lib/example.jsx";

export const meta = {
  name: "Forms",
  category: "Components",
  tags: ["error"],
  components: ["InputGroup", "InputGroupWithErrorMessage", "FlexContainer", "BARadio", "BARadioGroup", "BACheck", "Select"],
  functions: [],
  values: {
    name: "",
    lastName: "Acula",
    age: 45,
    appelation: "Dr.",
    role_description: "Really sucks",
    years_employed: 1,
    rank: "Junior",
    rank2: "Junior",
    rank3: "Junior",
    firstCheck: false,
    secondCheck: false,
    thirdCheck: false,
    fourthCheck: false,
    fifthCheck: false
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Forms`}</h1>
    <p>{`You're going to need to build a form. We have some styled input components to help you along your way.`}</p>
    <h2>{`Input Group`}</h2>
    <p>{`We provide an `}<inlineCode parentName="p">{`<InputGroup />`}</inlineCode>{` component for grouping and taming form elements.
It provides a little internal layout for a form element and a label, and helps keep sizing consistent throughout forms.`}</p>
    <p>{`It works like this:`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
    </Example>
    <p>{`You can provide a size to it, which will cascade down to any contained form elements which aren't given their own size prop`}</p>
    <Example mdxType="Example">
  <InputGroup size="jumbo" mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
  <InputGroup size="large" mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
  <InputGroup size="medium" mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
  <InputGroup size="small" mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
  <InputGroup size="micro" mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
    </Example>
    <p>{`Alternatively you can size an entire form by setting the `}<inlineCode parentName="p">{`--form-element-size`}</inlineCode>{` custom property on the containing element. `}</p>
    <p>{`e.g. `}</p>
    <pre><code parentName="pre" {...{}}>{`const container = styled.div\`
  --form-element-size: var(--text-size-jumbo);
\`
`}</code></pre>
    <h2>{`Input Group With Error`}</h2>
    <p>{`We provide an `}<inlineCode parentName="p">{`<InputGroupWithErrorMessage />`}</inlineCode>{` component which extends the standard `}<inlineCode parentName="p">{`InputGroup`}</inlineCode>{`, taking a string `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop.`}</p>
    <p>{`Try typing your name:`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroupWithErrorMessage error={props.get("name") === "" ? "Name can't be blank" : null} mdxType="InputGroupWithErrorMessage">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroupWithErrorMessage>
    </Example>
    <h3>{`Radios`}</h3>
    <p>{`Use a `}<inlineCode parentName="p">{`<BARadio />`}</inlineCode>{` to create a radio input.`}</p>
    <p>{`Use several `}<inlineCode parentName="p">{`<BARadio name="my-radio-group-name"/>`}</inlineCode>{` inputs linked by a common name to create a group of radios.`}</p>
    <p>{`You can pass a `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop if you need to disable an option.`}</p>
    <p>{`You can pass an `}<inlineCode parentName="p">{`inline=false`}</inlineCode>{` prop if you want each option to occupy its own line.`}</p>
    <p>{`Pass a `}<inlineCode parentName="p">{`label`}</inlineCode>{` prop to give each option a label.`}</p>
    <p>{`Pass a `}<inlineCode parentName="p">{`size`}</inlineCode>{` prop - `}<span className='text-micro'>{`micro`}</span>{`, `}<span className='text-small'>{`small`}</span>{`, `}<span className='text-medium'>{`medium`}</span>{`, `}<span className='text-large'>{`micro`}</span>{`, or `}<span className='text-jumbo'>{`jumbo`}</span>{` to alter the text and input size.`}</p>
    <p><inlineCode parentName="p">{`<BARadio />`}</inlineCode>{` plays nicely with input groups, if you need to align your `}<inlineCode parentName="p">{`<BARadio />`}</inlineCode>{` within the context of a larger form.`}</p>
    <p>{`There is a `}<inlineCode parentName="p">{`<BARadioGroup />`}</inlineCode>{` component that is a shortcut for groups of radio buttons (see below).`}</p>
    <Example mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <legend htmlFor="Radios">Radios!</legend>
    <BARadio size="micro" value="radio the first" label="Check me" name="first-radio-group" mdxType="BARadio" />
    <BARadio size="small" value="radio the first" label="Or me" name="first-radio-group" mdxType="BARadio" />
    <BARadio size="medium" value="radio the first" label="Or me" name="first-radio-group" mdxType="BARadio" />
    <BARadio size="large" value="radio the second" label="Or me" name="first-radio-group" mdxType="BARadio" />
    <BARadio disabled size="jumbo" value="radio the third" label="Not me -- i have been disabled" name="first-radio-group" mdxType="BARadio" />
  </InputGroup>
    </Example>
    <h3>{`Checkboxes`}</h3>
    <p>{`Use a `}<inlineCode parentName="p">{`<BACheck />`}</inlineCode>{` component for a checkbox.
You can pass a `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop if you need to disable a checkbox.`}</p>
    <Example mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <legend htmlFor="Radios-two">Checkboxes!</legend>
    <BACheck value="check the first" checked={props.get("firstCheck")} onChange={e => props.set("firstCheck", e.target.checked)} label="Check me" name="first-checkbox" mdxType="BACheck" />
    <BACheck value="check the second" checked={props.get("secondCheck")} onChange={e => props.set("secondCheck", e.target.checked)} label="And me!" name="second-checkbox" mdxType="BACheck" />
    <BACheck disabled value="check the third" label="Not me -- i have been disabled" name="second-checkbox" mdxType="BACheck" />
  </InputGroup>
    </Example>
    <h2>{`Checkboxen at different sizes`}</h2>
    <Example mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <legend htmlFor="Radios-two">Checkboxes!</legend>
    <BACheck size="micro" value="check the first" checked={props.get("firstCheck")} onChange={e => props.set("firstCheck", e.target.checked)} label="Check me" name="first-sized-checkbox" mdxType="BACheck" />
    <BACheck size="small" value="check the second" checked={props.get("secondCheck")} onChange={e => props.set("secondCheck", e.target.checked)} label="And me!" name="second-sized-checkbox" mdxType="BACheck" />
    <BACheck size="medium" value="check the third" checked={props.get("thirdCheck")} onChange={e => props.set("thirdCheck", e.target.checked)} label="And me!" name="third-sized-checkbox" mdxType="BACheck" />
    <BACheck size="large" value="check the fourth" checked={props.get("fourthCheck")} onChange={e => props.set("fourthCheck", e.target.checked)} label="And me!" name="fourth-sized-checkbox" mdxType="BACheck" />
    <BACheck size="jumbo" value="check the fourth" checked={props.get("fifthCheck")} onChange={e => props.set("fifthCheck", e.target.checked)} label="And me!" name="fifth-sized-checkbox" mdxType="BACheck" />
  </InputGroup>
    </Example>
    <h3>{`Radio groups`}</h3>
    <p>{`we provide a `}<inlineCode parentName="p">{`<BARadioGroup />`}</inlineCode>{` component to automatically create a bunch of radios for a list of options.
Options can be a list of primitive values, or a list of react-select style option objects.`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <legend>Choose your rank</legend>
    <BARadioGroup name="rank" value={props.get("rank2")} onChange={e => props.set("rank2", e.target.value)} options={["Junior", "Senior", "Legendary", "Incandescent"]} mdxType="BARadioGroup" />
  </InputGroup>
    </Example>
    <p>{`Radio and checkbox inputs can be created with an `}<inlineCode parentName="p">{`inline=false`}</inlineCode>{` prop, to give each input its own row.
You can do the same with a radio group.
This is perfect if your values / labels are longer and include more detail.`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <legend>Choose your rank</legend>
    <BARadioGroup name="il_rank" inline={false} value={props.get("rank3")} onChange={e => props.set("rank3", e.target.value)} options={[{
          label: "Junior, one before senior and one after infant",
          value: "Junior"
        }, {
          label: "Senior, one after junior, but not yet legendary",
          value: "Senior",
          disabled: true
        }, {
          label: "Legendary, truly the stuff of legends, spoken of in hushed tones around campfires",
          value: "Legendary",
          disabled: () => false
        }, {
          label: "Incandescent. Literally on fire.",
          value: "Incandescent",
          disabled: null
        }]} mdxType="BARadioGroup" />
  </InputGroup>
    </Example>
    <h3>{`Disable an `}<inlineCode parentName="h3">{`<InputGroup />`}</inlineCode>{` entirely with the `}<inlineCode parentName="h3">{`disabled`}</inlineCode>{` prop.`}</h3>
    <p>{`Here's the above but disabled.`}</p>
    <Example mdxType="Example">
  <InputGroup disabled mdxType="InputGroup">
    <legend>Choose your rank</legend>
    <BARadioGroup name="disabled_rank" value={props.get("rank")} onChange={e => props.set("rank", e.target.value)} options={["Junior", "Senior", "Legendary", "Incandescent"]} mdxType="BARadioGroup" />
  </InputGroup>
    </Example>
    <h3>{`Disabling options in a `}<inlineCode parentName="h3">{`<RadioGroup />`}</inlineCode></h3>
    <p>{`You have 3 ways of doing this.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`A disabled property on each option's object (if indeed passing options as an object).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop on the Radiogroup, containing a function which takes `}<inlineCode parentName="p">{`option.value`}</inlineCode>{` as its argument and returns a boolean.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`if RadioGroup.disabled is not a function, it is passed straight to the disabled prop.
So we can disable an entire radio-group by passing true if we want to.
It gets a default argument of false so by default nothing is disabled.
So we don’t run any unnecessary functions.`}</p>
      </li>
    </ol>
    <h3>{`Multiple Input Types Grouped`}</h3>
    <p>{`It's possible to use input groups to bunch multiple types of input together.
In this example, we pair a checkbox with a text field.`}</p>
    <Example mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <label htmlFor="ic_name">Username</label>
    <input id="ic_name" type="text" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
  <InputGroup mdxType="InputGroup">
    <BACheck value="account enabled" label="Account enabled" name="ic_checkbox" mdxType="BACheck" />
  </InputGroup>
    </Example>
    <h2>{`Display`}</h2>
    <p>{`By default, `}<inlineCode parentName="p">{`<InputGroup />`}</inlineCode>{` displays as an inline-block, so you can combine multiple form fields on a single row.`}</p>
    <Example mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
  <InputGroup mdxType="InputGroup">
    <label htmlFor="age-input">Age</label>
    <input type="text" id="age-input" value={props.get("age")} onChange={e => props.set("age", e.target.value)} />
  </InputGroup>
    </Example>
    <p>{`To display both the label and field inline, you can wrap the `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` tags in their own `}<inlineCode parentName="p">{`<InputGroup>`}</inlineCode>{`.`}</p>
    <Example mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <label>Name</label>
  </InputGroup>
  <InputGroup mdxType="InputGroup">
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
  <InputGroup mdxType="InputGroup">
    <label>Age</label>
  </InputGroup>
  <InputGroup mdxType="InputGroup">
    <input type="text" id="age-input" value={props.get("age")} onChange={e => props.set("age", e.target.value)} />
  </InputGroup>
    </Example>
    <p>{`If you need an input group to span the full width of the containing element, or need to use them with floats, you can add a `}<inlineCode parentName="p">{`.block`}</inlineCode>{` class:`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroup className="block" mdxType="InputGroup">
    <label htmlFor="name-input">Name</label>
    <input type="text" id="name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
  </InputGroup>
    </Example>
    <h2>{`Flex containers, and complicated form rows`}</h2>
    <p>{`We define a `}<inlineCode parentName="p">{`<FlexContainer />`}</inlineCode>{` component for grouping flex elements together.
It creates a flex container, with `}<inlineCode parentName="p">{`flex-direction : row`}</inlineCode>{` and `}<inlineCode parentName="p">{`flex-wrap: wrap`}</inlineCode>{`.
It's up to children to specify their own flex-numbers and alignment`}</p>
    <p>{`You can use it to lay out a few form elements together in a line:`}</p>
    <Example twoColumn mdxType="Example">
  <FlexContainer mdxType="FlexContainer">
    <InputGroup mdxType="InputGroup">
      <label htmlFor="appelation">Appelation</label>
      <Select id="appelation" value={props.get("appelation")} onChange={val => props.set("appelation", val)} options={["Dr.", "Ms.", "Professor", "Viscount"]} mdxType="Select" />
    </InputGroup>
    <InputGroup className="flex-2" mdxType="InputGroup">
      <label htmlFor="name-input">Surname</label>
      <input type="text" id="name-input" value={props.get("lastName")} onChange={e => props.set("lastName", e.target.value)} />
    </InputGroup>
    <InputGroup className="flex" mdxType="InputGroup">
      <label htmlFor="age-input">Age</label>
      <input type="number" id="age-input" value={props.get("age")} onChange={e => props.set("age", e.target.value)} />
    </InputGroup>
  </FlexContainer>
    </Example>
    <h2>{`Complex forms`}</h2>
    <p>{`Creating complex, multi-field forms might involve using several flex-containers and input groups together to create a unified layout.`}</p>
    <Example mdxType="Example">
  <FlexContainer mdxType="FlexContainer">
    <InputGroup mdxType="InputGroup">
      <label htmlFor="cf_appellation">Appelation</label>
      <Select id="cf_appellation" value={props.get("appelation")} onChange={val => props.set("appelation", val)} options={["Dr.", "Ms.", "Professor", "Viscount"]} mdxType="Select" />
    </InputGroup>
    <InputGroup className="flex-2" mdxType="InputGroup">
      <label htmlFor="cf_first-name-input">Forename</label>
      <input type="text" id="cf_first-name-input" value={props.get("name")} onChange={e => props.set("name", e.target.value)} />
    </InputGroup>
    <InputGroup className="flex-2" mdxType="InputGroup">
      <label htmlFor="cf_last-name-input">Surname</label>
      <input type="text" id="cf_last-name-input" value={props.get("lastName")} onChange={e => props.set("lastName", e.target.value)} />
    </InputGroup>
    <InputGroup className="flex" mdxType="InputGroup">
      <label htmlFor="cf_age-input">Age</label>
      <input type="number" id="cf_age-input" value={props.get("age")} onChange={e => props.set("age", e.target.value)} />
    </InputGroup>
  </FlexContainer>
  <FlexContainer mdxType="FlexContainer">
    <InputGroup className="flex" mdxType="InputGroup">
      <label htmlFor="cf_role_description">Role Description</label>
      <textarea id="cf_role_description" value={props.get("role_description")} onChange={e => props.set("role_description", e.target.value)} />
    </InputGroup>
    <InputGroup mdxType="InputGroup">
      <label htmlFor="cf_years_employed">Years in role</label>
      <input id="cf_years_employed" type="number" value={props.get("years_employed")} onChange={e => props.set("years_employed", e.target.value)} />
    </InputGroup>
  </FlexContainer>
    </Example>
    <h2>{`Read-only forms`}</h2>
    <p>{`You can use the `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` prop to make a form read-only.`}</p>
    <Example mdxType="Example">
  <FlexContainer mdxType="FlexContainer">
    <InputGroup readOnly mdxType="InputGroup">
      <label htmlFor="readonly_appellation">Appelation</label>
      <Select id="readonly_appellation" value={props.get("appelation")} onChange={val => props.set("appelation", val)} options={["Dr.", "Ms.", "Professor", "Viscount"]} mdxType="Select" />
    </InputGroup>
    <InputGroup readOnly className="flex-2" mdxType="InputGroup">
      <label htmlFor="readonly_first-name-input">Forename</label>
      <input type="text" id="readonly_first-name-input" value={props.get("firstName")} onChange={e => props.set("name", e.target.value)} />
    </InputGroup>
    <InputGroup readOnly className="flex-2" mdxType="InputGroup">
      <label htmlFor="readonly_last-name-input">Surname</label>
      <input type="text" id="readonly_last-name-input" value={props.get("lastName")} onChange={e => props.set("lastName", e.target.value)} />
    </InputGroup>
    <InputGroup readOnly className="flex" mdxType="InputGroup">
      <label htmlFor="readonly_age-input">Age</label>
      <input type="number" id="readonly_age-input" value={props.get("age")} onChange={e => props.set("age", e.target.value)} />
    </InputGroup>
  </FlexContainer>
  <FlexContainer mdxType="FlexContainer">
    <InputGroup readOnly className="flex" mdxType="InputGroup">
      <label htmlFor="readonly_role_description">Role Description</label>
      <textarea id="readonly_role_description" value={props.get("role_description")} onChange={e => props.set("role_description", e.target.value)} />
    </InputGroup>
    <InputGroup readOnly mdxType="InputGroup">
      <label htmlFor="readonly_years_employed">Years in role</label>
      <input id="readonly_years_employed" type="number" value={props.get("years_employed")} onChange={e => props.set("years_employed", e.target.value)} />
    </InputGroup>
  </FlexContainer>
  
  <InputGroup className='full-w' readOnly mdxType="InputGroup">
    <legend>Read-only radios!</legend>
    <BARadioGroup name="readonly-radios" value={props.get("rank")} onChange={e => props.set("rank", e.target.value)} options={["Junior", "Senior", "Legendary", "Incandescent"]} mdxType="BARadioGroup" />
  </InputGroup>
  <InputGroup className='full-w' readOnly mdxType="InputGroup">
    <legend>Read-only Checkboxes!</legend>
    <BACheck value="check the first" checked={true} onChange={() => {}} label="First" name="first-checkbox" mdxType="BACheck" />
    <BACheck value="check the second" checked={true} onChange={() => {}} label="Second" name="second-checkbox" mdxType="BACheck" />
    <BACheck value="check the third" label="third" name="third-checkbox" mdxType="BACheck" />
  </InputGroup>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
