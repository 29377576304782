export const focusNext = e => {
  const tabIndex = document.querySelectorAll("[tabindex]");
  const thisIndex = Array.from(tabIndex).indexOf(e.target);
  const next = tabIndex[thisIndex + 1];
  if (next) next.focus();
};
export const focusPrev = e => {
  const tabIndex = document.querySelectorAll("[tabindex]");
  const thisIndex = Array.from(tabIndex).indexOf(e.target);
  const prev = tabIndex[thisIndex - 1];
  if (prev) prev.focus();
};
