/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from "../lib/example";
import Grid from "/lib/components/Grid/Grid";
import GridItem from "/lib/components/Grid/Subcomponents/GridItem";
const Item = ({ children }) => (
  <div
    style={{
      background: "var(--brand-3)",
      border: "1px dashed white",
      color: "white",
      fontSize: "var(--text-size-medium)",
      fontWieght: "bold",
      textAlign: "center"
    }}
  >
    {children}
  </div>
);
const Items = [
  <Item>1</Item>,
  <Item>
    2<img src="https://via.placeholder.com/150" />
  </Item>,
  <Item>3</Item>,
  <Item>4</Item>,
  <Item>5</Item>,
  <Item>6</Item>,
  <Item>7</Item>,
  <Item>
    8<img src="https://via.placeholder.com/200" />
  </Item>,
  <Item>9</Item>,
  <Item>10</Item>
];

export const meta = {
  name: "Grid",
  category: "Deprecated Components",
  tags: [],
  components: ["Grid", "GridItem"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Deprecated Grid Components`}</h1>
    <p>{`N.B. currently used only in the `}<inlineCode parentName="p">{`FormSection`}</inlineCode>{` component of the datasource page in the report engine.
the `}<inlineCode parentName="p">{`BAGrid`}</inlineCode>{` component pre-dates this by a couple of years, and is used all over `}<inlineCode parentName="p">{`bright-ux`}</inlineCode>{`, so let's standardise around that. `}</p>
    <p>{`If you need features of this grid component that BAGrid doesn't support, please port them over.`}</p>
    <h2>Grid 1 - auto column count</h2>
    <Example mdxType="Example">
  <Grid mdxType="Grid">{Items}</Grid>
    </Example>
    <br />
    <h2>Grid 1 - auto column count with gaps gridGap="20px"</h2>
    <Example mdxType="Example">
  <Grid gridGap="20px" mdxType="Grid">{Items}</Grid>
    </Example>
    <br />
    <h2>Grid 1 squashed - auto column count</h2>
    <Example mdxType="Example">
  <div style={{
        width: 550
      }}>
    <Grid mdxType="Grid">{Items}</Grid>
  </div>
    </Example>
    <br />
    <h2>Grid 2 - fixed column count: columnCount={3}</h2>
    <Example mdxType="Example">
  <Grid columnCount={3} mdxType="Grid">{Items}</Grid>
    </Example>
    <br />
    <h2>Grid 2 squashed - fixed column count: columnCount={3}</h2>
    <Example mdxType="Example">
  <div style={{
        width: 500
      }}>
    <Grid columnCount={3} mdxType="Grid">{Items}</Grid>
  </div>
    </Example>
    <br />
    <h2>Grid 2 squashed - fixed column count and min column width: columnCount={3} minColumnWidth="200px"</h2>
    <Example mdxType="Example">
  <div style={{
        width: 200
      }}>
    <Grid columnCount={3} minColumnWidth="200px" mdxType="Grid">
      {Items}
    </Grid>
  </div>
    </Example>
    <br />
    <h2>Grid 3 - Using `<GridItem mdxType="GridItem" />` Subcomponent to span a full row</h2>
    <Example mdxType="Example">
  <Grid minColumnWidth="200px" mdxType="Grid">
    {[...Items.slice(0, 4), <GridItem spanFullRow mdxType="GridItem">
        <h2 style={{
            background: "var(--brand-2)",
            margin: "auto",
            width: "100%",
            textAlign: "center",
            color: "var(--white)"
          }}>
          ------ Grid Item ------
        </h2>
      </GridItem>, ...Items.slice(4, 9)]}
  </Grid>
    </Example>
    <br />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
