import React, { Component } from "react";
import { generateId } from "../_shared/vars";
import { ToggleInput } from "./radios";

export class BACheck extends Component {
  constructor() {
    super();
    this.id = generateId();
  }

  render() {
    const {
      id,
      label,
      value,
      name,
      disabled = false,
      inline = true,
      onChange,
      checked,
      size
    } = this.props;
    const _id = id || this.id;
    const extraProps = {};
    if (this.props.className)
      extraProps.className = this.props.className;

    return (
      <ToggleInput
        size={size}
        block={!inline}
        onKeyDown={e => {
          // don't scroll down on spacebar
          if (e.key === " ")
            e.preventDefault();
        }}
        onKeyUp={e => {
          if (["Enter", " "].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
            onChange(e);
          }
        }}
        {...extraProps}
      >
        <input
          id={_id}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange} />
        <label tabIndex={0} htmlFor={_id}>
          {label}
        </label>
      </ToggleInput>
    );
  }
}
