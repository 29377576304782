import React from "react"
import { BARadio } from "../forms/radios"
import { BACheck } from "../forms/checkboxes"
import { SearchTermHighlighter } from "../searchterm-highlighter"
import { PickerItem } from "./thing-picker-styled-components"
import { BAThingPicker } from "./thing-picker"
import {BATooltip} from '../tooltip'

export const Leaf = ({
  uniqueId,
  collapseParent,
  mode,
  name,
  node,
  nodeMeta,
  searchMatch,
  searchTerm,
  selected,
  textKey,
  toggleSelected,
  url,
  tooltipPosition
}) => (
  <PickerItem
    depth={nodeMeta.depth}
    disabled={node.disabled}
    searchMatch={searchMatch}
    selected={selected}
    onKeyUp={e => {
      if (e.key === "ArrowLeft") {
        collapseParent()
      }
    }}
  >
    {mode === BAThingPicker.modes.url && url ? (
      <a href={url} className="leaf-label">
        <SearchTermHighlighter text={node[textKey]} searchTerm={searchTerm} />
        {node.info ? (
          <BATooltip content={node.info} placement={ tooltipPosition }>
            <i className="fa fa fa-info-circle margin-half-left"/>
          </BATooltip> ) : null}
      </a>
    ) : (
      <label htmlFor={uniqueId} className="leaf-label">
        <SearchTermHighlighter text={node[textKey]} searchTerm={searchTerm} />
        {node.info ? (
          <BATooltip content={node.info} placement={ tooltipPosition }>
            <i className="fa fa fa-info-circle margin-half-left"/>
          </BATooltip> ) : null}
      </label>
    )}
    {mode === BAThingPicker.modes.many ? (
      <BACheck
        className="float-right"
        id={uniqueId}
        name={uniqueId}
        checked={selected}
        onChange={toggleSelected}
      />
    ) : null}
    {mode === BAThingPicker.modes.one ? (
      <BARadio
        checked={selected}
        className="float-right"
        id={uniqueId}
        name={name}
        onChange={toggleSelected}
      />
    ) : null}
  </PickerItem>
)

Leaf.displayName = "ThingPickerLeaf"
