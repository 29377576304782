/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example        from '../lib/example'
import Sparkline from '/lib/components/Sparkline/Sparkline'

export const meta = {
  name: "Sparkline",
  category: "Components",
  tags: [],
  components: ["Sparkline"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sparkline`}</h1>
    <p>{`The sparkline component renders a trend line based on a simple array of numbers`}</p>
    <p>{`it also takes a few props to control styling. It's designed to be extremely lightweight, for use in-line with text, or in places where HighCharts with all its interactivity and configurability would be overkill`}</p>
    <h2>{`Example`}</h2>
    <Example mdxType="Example">
  This has been a great year for duck 🦆 sightings in Chumleigh <Sparkline data={[12, 5, 8, 9, 1, 5, 16, 28, 32, 23, 21, 25, 15, 14, 13, 2]} mdxType="Sparkline" />
    </Example>
    <h2>{`Props`}</h2>
    <p>{`You must pass a `}<inlineCode parentName="p">{`data`}</inlineCode>{` prop. You can additionally pass some styling props`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`required?`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array of numbers`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`an array of data points. must be numbers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`height for the canvas element. defaults to 1em for inline use`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width for the canvas element. defaults to 8em for inline use.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`maxWidth`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`maximum width. no default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`line color. defaults to the value of `}<inlineCode parentName="td">{`var(--text-default)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`thickness`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`line thickness. defaults to 2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`background`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`CSS background prop. default none applied.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Further Example`}</h2>
    <Example twoColumn mdxType="Example">
  <Sparkline width="400px" height="250px" background="linear-gradient(to bottom right, salmon, orangered)" color="yellow" thickness="5" data={[-4, -20, -16, 12, 16, 17, 28, 22, 23, 17, 27]} mdxType="Sparkline" />
    </Example>
    <h2>{`Limitations`}</h2>
    <p>{`Sparkline size is determined at render time based on width and height properties. If you use dynamic sizes (like `}<inlineCode parentName="p">{`width = "100%"`}</inlineCode>{`) it won't re-render itself if its parent component resizes. If this becomes a problem later on we can implement it 😎`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
