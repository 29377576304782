import styled from 'styled-components'

const NAV_HEIGHT = '45px'

const StyledNav = styled.div`
  position    : fixed;
  top         : 0px;
  width       : 100%;
  height      : ${NAV_HEIGHT};
  background  : var(--header-color);
  color       : var(--header-font-color);
  z-index     : var(--z-index-layer-4);
  display     : flex;
  align-items : center;
  padding-left: 45px;
`

export default StyledNav
