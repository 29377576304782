function isSuperset(set, subset) {
  for (const elem of subset) {
      if (!set.has(elem)) {
          return false;
      }
  }
  return true;
}
function union(setA, setB) {
  const _union = new Set(setA);
  for (const elem of setB) {
      _union.add(elem);
  }
  return _union;
}
function intersection(setA, setB) {
  const _intersection = new Set();
  for (const elem of setB) {
      if (setA.has(elem)) {
          _intersection.add(elem);
      }
  }
  return _intersection;
}
function difference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
      if (_difference.has(elem)) {
          _difference.delete(elem);
      } else {
          _difference.add(elem)
      }
  }
  return _difference;
}
function takeOneFrom (set) {
    if (set.size == 0) return null
    const first = Array.from(set)[0]
    set.delete(first)
    return first
}

function isEqual(setA, setB) {
    if (setA.size !== setB.size) return false;
    for (var a of setA) if (!setB.has(a)) return false;
    return true;
}

export { difference, isEqual, intersection, union, isSuperset, takeOneFrom }