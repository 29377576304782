import React from "react"
import { BACheck } from "../forms/checkboxes"
import { SearchTermHighlighter } from "../searchterm-highlighter"
import { focusNext, focusPrev } from "./focus-tools"
import { ExpandoButton, PickerItem } from "./thing-picker-styled-components"
import { BAThingPicker } from "./thing-picker"
import {BATooltip}       from '../tooltip'

const Expando = ({ expanded, toggleExpanded }) => (
  <ExpandoButton expanded={expanded} onClick={toggleExpanded}>
    ‣
  </ExpandoButton>
)
export const Branch = ({
  uniqueId,
  allSelected,
  collapseParent,
  expanded,
  mode,
  node,
  nodeMeta,
  searchMatch,
  searchTerm,
  selectedChildren,
  textKey,
  toggleExpanded,
  toggleSelectAll,
  url,
  tooltipPosition
}) =>
  nodeMeta.parent ? (
    <PickerItem
      depth={nodeMeta.depth}
      searchMatch={searchMatch}
      selected={allSelected}
      onKeyUp={e => {
        switch (e.key) {
          case "ArrowDown":
            e.preventDefault()
            e.stopPropagation()
            focusNext(e)
            break
          case "ArrowUp":
            e.preventDefault()
            e.stopPropagation()
            focusPrev(e)
            break
          case "ArrowRight":
            if (!expanded) {
              toggleExpanded()
              e.target.focus()
            }
            break
          case "ArrowLeft":
            if (expanded) {
              toggleExpanded()
              e.target.focus()
            } else {
              collapseParent()
            }
            break
        }
      }}
    >
      <Expando expanded={expanded} toggleExpanded={toggleExpanded} />
      {mode === BAThingPicker.modes.url && url ? (
        <a href={url}>
          <SearchTermHighlighter text={node[textKey]} searchTerm={searchTerm} />
            {node.info ? (
              <BATooltip content={node.info} placement={ tooltipPosition }>
                <i className="fa fa fa-info-circle margin-half-left"/>
              </BATooltip> ) : null}
        </a>
      ) : (
        <label onClick={toggleExpanded}>
          <SearchTermHighlighter text={node[textKey]} searchTerm={searchTerm} />
          {node.info ? (
            <BATooltip content={node.info} placement={ tooltipPosition }>
              <i className="fa fa fa-info-circle margin-half-left"/>
            </BATooltip> ) : null}
          {selectedChildren.length ? (
            <span className="float-right margin-right">
              ({selectedChildren.length} selected)
            </span>
          ) : null}
        </label>
      )}

      {node.children.length && mode == BAThingPicker.modes.many ? (
        <BACheck
          disabled={node.disabled}
          id={uniqueId}
          name={uniqueId}
          checked={allSelected}
          onChange={toggleSelectAll}
        />
      ) : null}
    </PickerItem>
  ) : null // the root node doesn't render anything

Branch.displayName = "ThingPickerBranch"