import React from "react";
import styled from "styled-components";
import {
  disabledStyles,
  getFontSize,
  formMinWidth,
  formMinHeight,
} from "../_shared/vars";

const setFormElementSize = ({ size }) =>
  size
    ? `
  --form-element-size: var(--text-size-${size});
  --form-element-line-height: var(--text-line-height-${size});
`
    : "";

const setZIndex = ({ stackIndex }) =>
  stackIndex
    ? `
  z-index: var(--z-index-layer-${stackIndex});
`
    : "";

const readOnly = ({ readOnly }) =>
  readOnly
    ? `

label {
  pointer-events: none;
}

input, textarea, select {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0;
  pointer-events: none;
  cursor: text;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.Select-control {
  pointer-events: none;
  border: none;
  outline: none;
  background-color: transparent;
  
  .Select-arrow-zone {
    display: none;
  }
}

textarea {
  resize: none;
}

input[type=radio], input[type=checkbox] {
  pointer-events: none;
  
  & + label {
    pointer-events: none;

    &::after {
      display: none;
    }
    
    &::before, &::after {
      opacity: 0.3 !important;
    }
  }

  &[checked] + label {
    &::before, &::after {
      display: inherit;
    }
  }
}
`
    : "";

const InputGroup = styled.fieldset`
  ${disabledStyles}
  ${formMinWidth}
  ${formMinHeight}
  ${getFontSize}
  ${setFormElementSize}
  ${setZIndex}
  outline        : none;
  border         : none;
  padding        : 0;
  display        : inline-block;
  margin-left    : 0.25rem;
  margin-right   : 0.25rem;
  margin-bottom  : 0.5rem;

  &.min-width {
    min-width: 200px;
  }
  &.skinny {
    max-width: 80px;
  }
  label, legend {
    line-height : var(--form-element-line-height); 
    font-weight : 300;
    padding-left: 0.25rem;
  }
  label, legend, input, textarea, select {
    display: block;
  }
  input, textarea {
    ${formMinWidth}
    ${formMinHeight}
    line-height: calc(var(--form-element-line-height) + 0.4rem);
    padding: 0 0.5em;
  }
  > input, > textarea, input.ba-date-picker {
    margin: 0;
    outline: none;
  }
  > input, > textarea, input.ba-date-picker, .Select-control {
    border        : 1px solid #F0F0F0;
    border-bottom : 1px solid #CCC;
    border-radius : 0;
    font-size     : 0.8em;
  }

  text-area { width: 100%; }
  &.block { display: block; }
  &.flex { flex: 1 1 200px; }
  &.flex-2 { flex: 2 2 200px; }
  &.flex-3 { flex: 3 3 200px; }
  &.block, &.flex, &.flex-2, &.flex-3 {
    input, textarea, select, div.input {
      width: 100%;
    }
  }

  ${readOnly}
`;
InputGroup.displayName = "InputGroup";

function withFormFieldErrorMessage(WrappedFormFieldComponent) {
  return function(props) {
    const { error, ...rest } = props;
    return (
      <>
        <WrappedFormFieldComponent {...rest} />
        {error ? (
          <InputGroup>
            <label className="text-hazard text-small nowrap">{error}</label>
          </InputGroup>
        ) : null}
      </>
    );
  };
}

const InputGroupWithErrorMessage = withFormFieldErrorMessage(InputGroup);
InputGroupWithErrorMessage.displayName = "InputGroupWithErrorMessage";

export { InputGroup, InputGroupWithErrorMessage };
