/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  name: "useRootClose",
  category: "HOCs, Hooks, & Functions",
  tags: [],
  components: [],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`useRootClose `}<br />{` `}<small>{`A Custom Hook which can be used to close an element when the user clicks away.`}</small></h1>
    <p>{`useRootClose takes three arguments:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`rootRegionRef`}</inlineCode>{` - the ref to the element to close`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`callback`}</inlineCode>{` - the function to be called to close the element`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`excludeClasses`}</inlineCode>{` (optionsl) - an array of classes to exclude from triggering the callback`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`doc`}</inlineCode>{` (optional) - the document which the element lives in (added for testing purposes)`}</p>
      </li>
    </ul>
    <h3>{`rootRegionRef`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`rootRegionRef`}</inlineCode>{` can be setup using the standard React hook `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#useref"
      }}>{`https://reactjs.org/docs/hooks-reference.html#useref`}</a>{`)`}</p>
    <pre><code parentName="pre" {...{}}>{`import {useRef} from 'react'

...

const myRootRegionRef = useRef() // create the ref constant

useRootClose(myRootRegionRef, () => close()) // call userRootClose with the new ref

...

<div ref={myRootRegionRef}> // add the ref the target element
  ...
</div>
`}</code></pre>
    <h3>{`callback function`}</h3>
    <p>{`Pass in a callback function to close the element when triggered by the user clicking outside the ref region:`}</p>
    <pre><code parentName="pre" {...{}}>{`// example close function
const close = () => { 
  setClassName("removing"); 
  closeTimeout = setTimeout(() => {
    setClassName("")
    setActive(false)
  }, 300)
}

// pass in the close() function to useRootClose
useRootClose(myRootRegionRef, () => close())
`}</code></pre>
    <h3>{`excludeClasses (optional)`}</h3>
    <p>{`Pass in an array of classes which you would like to exclude from triggering the callback function.`}</p>
    <p>{`This is useful when we want to exclude elements which are outside of the component which calls useRootClose`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <p>{`Consider a parent component which when clicked toggles a child component:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`When the parent component is clicked the first time the child component is rendered`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When the parent component is clicked a second time, the child component is closed by useRootClose`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The child component is instantly opened again by the parent component toggle`}</p>
      </li>
    </ul>
    <p>{`In this scenario, we can pass in the class of the element we wish to exclude from useRootClose:`}</p>
    <pre><code parentName="pre" {...{}}>{`// call userRootClose classes we want to exclude
useRootClose(myRootRegionRef, () => close(), ['parent-component-class'])
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
