import React, { useEffect, useReducer } from "react"
import MessageDispatcher from "../../util/MessageDispatcher"
import { actionReducer, initState } from "./notification-state"
import { Notification } from "./Notification"
import styled from "styled-components"
import { useInterval } from "../../util/use-interval.js"

const NotificationDisplayBlock = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  a,
  a:visited,
  a:hover {
    text-decoration: none;
    color: var(--highlight-lighter);
  }
  a:hover {
    text-decoration: underline;
  }
  ${({ notificationDisplayCss }) => notificationDisplayCss}
`

const NotificationDisplay = React.memo(({
  messageEmitter = MessageDispatcher.getSingletonInstance(),
  maxMessagesCount = 5,
  defaultDuration = 12000,
  // Should be a styled-components css block
  notificationDisplayCss
}) => {
  const [state, dispatch] = useReducer(
    actionReducer,
    maxMessagesCount,
    initState
  )

  // we don't need to respond to these state changes directly,
  // we only care about the effects they will have the next time dispatch is called
  state.maxMessageCount = maxMessagesCount
  state.defaultDuration = defaultDuration

  useInterval(() => dispatch({ type: "tick" }), 10)

  // bootstrap subscription to message dispatcher
  useEffect(() => {
    const addMessage = message =>
      dispatch({
        type: "addMessage",
        payload: message
      })

    // subscribe to the message emitter on mount
    messageEmitter.subscribe(addMessage)
    return () => {
      // unsub from the message emitter on unmount
      messageEmitter.unsubscribe(addMessage)
    };
  }, [messageEmitter])

  return (
    <NotificationDisplayBlock notificationDisplayCss={notificationDisplayCss}>
      {state.messages.map((message, i) => (
        <Notification
          key={"notification_message_" + i}
          message={message}
          meta={state.messageMeta.get(message) || {}}
          dispatch={dispatch}
        />
      ))}
    </NotificationDisplayBlock>
  )
});

NotificationDisplay.displayName = "NotificationDisplay"

export default NotificationDisplay
export { initState, actionReducer }
