/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from "../lib/example";
import BADropdown, { BADropdownExample } from "/lib/components/dropdown";
import { useComputedPosition } from "/lib/components/hooks/useComputedPosition";
export const meta = {
  name: "useComputedPosition",
  category: "HOCs, Hooks, & Functions",
  tags: [],
  components: [],
  functions: ["useComputedPosition"],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`useComputedPosition`}</h1>
    <h2>{`What it does`}</h2>
    <p>{`A hook that takes a ref to a DOM element and returns an object with the element's position and dimensions.`}</p>
    <h2>{`What it's for`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`computedPosition`}</inlineCode>{` object looks like this:`}</p>
    <p><inlineCode parentName="p">{`{ top: number, left: number, width: number, height: number }`}</inlineCode>{`.`}</p>
    <p>{`You can use this for positioning a second element absolutely.`}</p>
    <p>{`One use case this is useful for is positioning a portal relative to another element.`}</p>
    <p>{`(See the PositionedPortal component for an example.)`}</p>
    <h2>{`Adding to your component`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function MyComponent() {
  const { computedPosition, ref } = useComputedPosition();

  return (
    <div ref={ref as RefObject<HTMLDivElement>}>
      <SomeVariableSizedContent />
    </div>
  )
}
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
