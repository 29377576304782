/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example    from '../lib/example'
import {BAPortal} from '/lib/components/portal'
import {BAButton} from '/lib/components/buttons'

export const meta = {
  name: "Portals",
  category: "Components",
  tags: [],
  components: ["BAPortal"],
  functions: ["withBAPortal"],
  values: {
    portal1Visible: false,
    portal2Visible: false
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BAPortal `}<br />{` `}<small>{`A higher order component that transports its children to a new 'ba-modal' div right at the root level of the 'layout' template.`}</small></h1>
    <p>{`Useful for components like Modals, tooltips and dropdowns that need to appear on top of everything else.`}</p>
    <p>{`(You can also achieve this with z-index rules to override a component that is higher up in the DOM.)`}</p>
    <Example mdxType="Example">
  <BAButton onClick={() => props.set('portal1Visible', true)} mdxType="BAButton">
    Transport me by Portal
  </BAButton>
  <BAPortal isOpen={props.get('portal1Visible')} mdxType="BAPortal">
    <div style={{
          position: 'fixed',
          margin: 'auto',
          background: "url('https://spacenews.com/wp-content/uploads/2018/05/24359364107_152b0152ff_k-879x485.jpg')",
          opacity: '1',
          width: '500px',
          height: '500px',
          zIndex: '10000000',
          top: '30%',
          left: '30%',
          borderRadius: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
      <BAButton color="highlight" solid onClick={() => props.set('portal1Visible', false)} mdxType="BAButton">
        Woowwww
      </BAButton>
    </div>
  </BAPortal>
    </Example>
    <h2>{`Composing your PortalContent with `}<inlineCode parentName="h2">{`withBAPortal`}</inlineCode></h2>
    <p>{`You can also add a Portal to a component with the `}<inlineCode parentName="p">{`withBAPortal`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{}}>{`const content = (
  <div
    open={this.state.portalIsOpen}
  >
    Some amazing Portal Content
  </div>
)

const portalDiv = withBAPortal(content)

`}</code></pre>
    <h2>{`Decorator syntax`}</h2>
    <p>{`Or... if your content is returned from the render function of a class...`}</p>
    <p>{`you can use the same function but with the extremely concise `}<inlineCode parentName="p">{`@decorator`}</inlineCode>{` syntax above.`}</p>
    <pre><code parentName="pre" {...{}}>{`@withBAPortal
class PortalContent {
  render() {
    return (
      <div open={this.state.portalIsOpen}>
        Content
      </div>
    )
  }
}
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
