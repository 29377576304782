/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example        from '../lib/example'
import { SegmentControl } from '/lib/index'
import { InputGroup } from '/lib/index'

export const meta = {
  name: "Segment Control",
  category: "Components",
  tags: [],
  components: ["SegmentControl"],
  functions: [],
  values: {
    first: "left",
    second: "down",
    third: 3,
    size: "medium",
    color: "brand-3",
    icons: 2
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Segment Control`}</h1>
    <p>{`The segment control is useful for select-like operations with a small number of options. It is cribbed from Apple's human interface guidelines, and providers a way to let a user quickly select one of a small number of options.`}</p>
    <p>{`Segment controls operate on a list of `}<inlineCode parentName="p">{`options`}</inlineCode></p>
    <p>{`Options can either be a list of strings, or a list of objects shaped `}<inlineCode parentName="p">{`{ value, label }`}</inlineCode></p>
    <p>{`so for a list of options `}<inlineCode parentName="p">{`["left", "middle", "right"]`}</inlineCode>{` you get a segment like this:`}</p>
    <Example twoColumn mdxType="Example">
      <SegmentControl id="first-input" options={["left", "middle", "right"]} value={props.get("first")} onChange={e => props.set("first", e.target.value)} mdxType="SegmentControl" />
    </Example>
    <p>{`Segment controls that only have two options will ever-so-slightly enlarge the selected segment, so that it is obvious which segment is active.`}</p>
    <Example twoColumn mdxType="Example">
  <SegmentControl id="second-input" options={["up", "down"]} value={props.get("second")} onChange={e => props.set("second", e.target.value)} mdxType="SegmentControl" />
    </Example>
    <h2>{`Non-string values`}</h2>
    <p>{`You can use a list of react-select style option objects for a segment control too. This is perfect if you need values that aren't strings, or if your values and labels need to differ in format.`}</p>
    <Example twoColumn mdxType="Example">
  <SegmentControl id="third-input" options={[{
        value: 1,
        label: 'inside'
      }, {
        value: 2,
        label: 'outside'
      }, {
        value: 3,
        label: 'topside'
      }, {
        value: 4,
        label: 'underside'
      }]} value={props.get("third")} onChange={e => props.set("third", e.target.value)} mdxType="SegmentControl" />
    </Example>
    <h2>{`Events`}</h2>
    <p>{`Segment controls fire somewhat watered down versions of a reguler `}<inlineCode parentName="p">{`event`}</inlineCode>{`, containing the following properties:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  target: {
    name: "segment-control-name",
    id: "segment-control-id",
    value: "the-value-of-the-seg"
  }
}
`}</code></pre>
    <p>{`this is designed so that you can pass regular input-style event handlers in the format`}</p>
    <pre><code parentName="pre" {...{}}>{`e => doSomethingWith( e.target.value )
`}</code></pre>
    <h2>{`Sizes`}</h2>
    <p>{`Segment controls accept the same `}<inlineCode parentName="p">{`size`}</inlineCode>{` props that a `}<inlineCode parentName="p">{`BAButton`}</inlineCode>{` can accept. `}</p>
    <Example twoColumn mdxType="Example">
  <SegmentControl className="margin-bottom" size={props.get("size")} id="size" options={["micro", "small", "medium", "large", "jumbo"]} value={props.get("size")} onChange={e => props.set("size", e.target.value)} mdxType="SegmentControl" />
    </Example>
    <h2>{`Input Groups`}</h2>
    <p>{`Segment controls work nicely within the `}<inlineCode parentName="p">{`<InputGroup />`}</inlineCode>{` component, which allows you to group them together with a label, and build them into more complex form layouts. If unconstrained, for example by an Input Group, segment controls will be full-width.`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <label htmlFor="fourth-input">Please choose a side</label>
    <SegmentControl id="fourth-input" options={["left", "middle", "right"]} value={props.get("first")} onChange={e => props.set("first", e.target.value)} mdxType="SegmentControl" />
  </InputGroup>
    </Example>
    <h2>{`Colours`}</h2>
    <p>{`The segment control can take a `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop. This should be a named CSS variable available to the scope where you are using the segment.`}</p>
    <p>{`All of the BA styleguide colours are available. For example, to use the third brand colour, you'd pass `}<inlineCode parentName="p">{`color="brand-3"`}</inlineCode>{` to the segment. In this example, the segment control is picking its own colour.`}</p>
    <Example twoColumn mdxType="Example">
  <SegmentControl color={props.get("color")} id="fifth-input" options={["brand-1", "brand-2", "brand-3", "brand-4", "brand-5"]} value={props.get("color")} onChange={e => props.set("color", e.target.value)} mdxType="SegmentControl" />
    </Example>
    <h2>{`Icons support`}</h2>
    <p>{`You can use a mix of icons and text in this control. Icons are passed in the options array as font awesomne class name strings
To use icons you MUST pass an object style options with an icon field: for example `}<inlineCode parentName="p">{`{ value: 12, label: 'somethin', icon: 'fa fa-cog'}`}</inlineCode>{`
Icons and labels can be mixed: if no icon option is available in the option item, the label will be used instead. The icon overwrites the label`}</p>
    <Example twoColumn mdxType="Example">
  <SegmentControl id="sixth-input" options={[{
        value: 1,
        label: 'inside',
        icon: 'fas fa-list-ul'
      }, {
        value: 2,
        label: 'outside',
        icon: 'far fa-table'
      }, {
        value: 3,
        label: 'topside',
        icon: 'fa fa-cog'
      }, {
        value: 4,
        label: 'just text!'
      }]} value={props.get("icons")} onChange={e => props.set("icons", e.target.value)} mdxType="SegmentControl" />
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
