import React,
  { Fragment, createRef }  from 'react'
import styled              from 'styled-components'
import { debounce } from 'lodash'

import {BAButton}          from '../buttons'
import {withBAPortal}      from '../portal'
import withRootClose       from '../rootClose'
import autobind            from 'autobind-decorator'

import { FlexContainer } from '../blocks'

import { IterableTree } from '../../util/iterable-tree';
import { isEqual } from '../../util/sets-util';

import { focusNext, focusPrev } from './focus-tools';
import { PickerContainer, Search, ThingList, BAPalette } from './thing-picker-styled-components';
import { Leaf } from './leaf';
import { Branch } from './branch';

const nodeKey = (node, keyGen) => keyGen && typeof keyGen === "function" ? keyGen(node) : node.id || node._id

const noop = () => void 0

// REFACTOR ME INTO SOME DIFFERENT FILES PLS

function shortUUID() {
  return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const selectedPropToSet = (selectedProp, pickerMode, keyGenerator) => {
  const selected = pickerMode === BAThingPicker.modes.one && selectedProp != null
          ? [selectedProp]
          : selectedProp
  return  new Set((selected || []).map(item => nodeKey(item, keyGenerator)))
}

class BAThingPicker extends React.Component {

  displayName = "BAThingPicker"

  static modes = {
    one  : "one",
    many : "many",
    url  : "url"
  }

  static getInitialState (props) {
    const initiallySelected = props.initiallySelected ? props.initiallySelected : ( props.selected ? props.selected : null)

    const selected = props.mode === BAThingPicker.modes.one && initiallySelected != null
      ? [initiallySelected]
      : initiallySelected

    return {
      searchTerm : "",
      itemTree       : new IterableTree(props.items),
      selected       : new Set((selected || []).map(node => nodeKey(node, props.keyGen))),
      expanded       : new Set((props.expanded || []).map(node => nodeKey(node, props.keyGen))),
      searchMatches  : new Set(),
      searchToKeep   : new Set(),
      searchExpanded : new Set()
    }
  }

  static getDerivedStateFromProps (props, state) {

    const newTree = new IterableTree(props.items)
    const newState = { ... state }
    const isControlled = !!props.selected

    const getItemsFromOldTree = propName => {
      const oldIds = state[propName] ? Array.from(state[propName]) : []

      const newStateItems = newTree.filter(item => oldIds.includes(nodeKey(item, props.keyGen))) || []

      return new Set(newStateItems.map(item => nodeKey(item, props.keyGen)))
    }
    
    let hasChanges = false

    if (newTree.hash() != state.itemTree.hash()) {
      newState.itemTree = newTree
      
      if (!isControlled) {
        newState.selected = getItemsFromOldTree('selected')
        newState.expanded = getItemsFromOldTree('expanded')
      }
      
      hasChanges = true
    }

    const selectedPropsSet =  selectedPropToSet(props.selected, props.mode, props.keyGen)

    if (isControlled && !isEqual(state.selected, selectedPropsSet)) {
      newState.selected = selectedPropsSet

      hasChanges = true
    }

    return hasChanges ? newState : null
  }


  constructor (props) {
    super(props)
    this.name    = props.name || shortUUID()
    this.textKey = props.textKey || "text"
    this.state   = BAThingPicker.getInitialState(props)
    this.clearable = props.clearable || true
    this.handleSearch = debounce(this.onSearch, 500);
  }

  mode () {
    return BAThingPicker.modes[this.props.mode] || BAThingPicker.modes.many
  }

  toggle (key, node, callback = noop) {
    const item = nodeKey(node, this.props.keyGen)

    this.setState(
      state => {
        state[key].has(item)
          ? state[key].delete(item)
          : state[key].add(item)
        return state
      },
      callback
    )
  }

  isSelected (node) {
    if (node.disabled) return false

    return this._is("selected", node)
  }

  _is (key, node) {

    const item = nodeKey(node, this.props.keyGen)
    return this.state[key].has(item)
  }

  isExpanded (node) {
    return this.state.itemTree.depthOf(node) == 0 || this._is("expanded", node) || this._is("searchExpanded", node)
  }

  hasSelectedChildren (node) {
    const nodeTree = new IterableTree(node)
    for (const child of nodeTree.leaves()) {
      if (this.isSelected(child)) return true
    }
  }

  selectedChildren (node) {
    const nodeTree = new IterableTree(node)
    return nodeTree.leaves().filter(child => this.isSelected(child))
  }

  allChildrenSelected (node) {
    const nodeTree = new IterableTree(node)
    const leaves  = nodeTree.leaves().filter(l => !l.disabled)
    if (!leaves.length) return false
    
    let allSelected = true
    
    for (const child of leaves) {
      if (!this.isSelected(child)) allSelected = false
    }
    
    return allSelected
  }

  toggleSelectAll (node) {
    const nodeTree = new IterableTree(node)
    const newState = { ...this.state }
    const allBranchChildrenAreSelected = this.allChildrenSelected(node)
    
    // predict if this bulk deselect action would result in an empty state.selected
    // if so, you're looking at the parent node of the last remaining selections
    const enabledChildren = nodeTree.tree.children.filter(c=>!c.disabled).length
    const parentOfLastLeaf = this.state.selected.size <= enabledChildren

    if (!allBranchChildrenAreSelected) { // some remain to be toggled on, so start by doing so
      for (const child of nodeTree) {
        // console.log('child in nodeTree:', child);
        if (child.disabled) continue         
        if (!child.children) newState.selected.add(nodeKey(child, this.props.keyGen))
      }
    } else { // all children are selected, so do the opposite, i.e. deselect
      for (const child of nodeTree) {
        newState.selected.delete( nodeKey(child, this.props.keyGen) )
      }
      // there are rare cases where you don't want the picker to be cleared out entirely
      // in which case, just after it's cleared, conditionally re-add the first suitable one
      if (this.props.clearable === false && parentOfLastLeaf) { 
        newState.selected.add(nodeKey(nodeTree.tree.children.find(c=>!c.disabled), this.props.keyGen))
      }
    }

    if (this.props.selected) {
      this.persistSelected(newState)
    } else {
     
      const callback = this.props.onChange ? () => this.props.onChange(this.result()) : void 0
      
      this.setState(newState, callback)
    }
    console.log(`${newState.selected.size} children are toggled ON`);

    
  }

  select (node, callback = noop) {
    if (node.disabled) return
    
    switch (this.mode()) {
      case BAThingPicker.modes.many:
        return this.toggle("selected", node, callback)
      case BAThingPicker.modes.one:
        return this.setState({ selected : new Set([ nodeKey(node, this.props.keyGen) ]) }, callback)
    }
  }

  result () {
    const selected = this.selectedNodesArray()

    switch (this.mode()) {
      case BAThingPicker.modes.many:
        return selected
      case BAThingPicker.modes.one:
        return selected[0]
      case BAThingPicker.modes.url:
        return // you don't get to choose things this way!!
    }
  }

  @autobind
  toggleExpanded (node, callback = noop) {

    const key = nodeKey(node, this.props.keyGen)

    if (this.state.searchExpanded.has(key)) {
      this.setState(
        state => {
          state.searchExpanded.delete(key)
          state.expanded.delete(key)
          return state
        },
        callback
      )
    } else {
      this.toggle("expanded", node, callback)
    }
  }

  collapseParentIfOpen (node) {
    const parent = this.state.itemTree.parentOf(node)
    const key = nodeKey(parent, this.props.keyGen)

    if (this.isExpanded(parent)) {

      const callback = () => {
        const parentInput = document.querySelector(`[for=${ key }]`)
        if (parentInput) {
          parentInput.focus()
        }
      }

      this.toggleExpanded(parent, callback)
    }
  }

  nodeURL (node) {
    if (this.props.urlGenerator != null && typeof this.props.urlGenerator === "function") return this.props.urlGenerator(node)
    if (node.href != null) return node.href
    if (node.url != null)  return node.url
    if (node.URL != null)  return node.URL
    return null
  }

  onClickToggle (node) {
    
    const key = nodeKey(node, this.props.keyGen)

    if (this.props.selected) {
      const newState = Object.assign({} , this.state)
      
      if (newState.selected.has(key)) {
        newState.selected.delete(key)
      } else {
        newState.selected.add(key)
      }

      this.persistSelected(newState)
    } else {
      const callback = this.props.onChange ? () => this.props.onChange(this.result()) : void 0

      this.select(node, callback)
    }
  }

  selectedNodesArray (state = null) {
    const selectedIDs = Array.from(state ? state.selected : this.state.selected) || []

    return selectedIDs.map(id => {
      return this.state.itemTree.find(treeItem => nodeKey(treeItem, this.props.keyGen) === id)
    })
  }

  persistSelected (state) {
    const selected = this.selectedNodesArray(state)
   
    switch (this.mode()) {
      case BAThingPicker.modes.many:
        return this.props.onChange(selected)
      case BAThingPicker.modes.one:
        return this.props.onChange(selected[0])
      case BAThingPicker.modes.url:
        return // you don't get to choose things this way!!
    }
  }

  listItems () {
    const tree = this.state.itemTree
    const mode  = this.mode()

    const nodes = tree
      .filter(node => {
        let parent = tree.parentOf(node)
        if (parent == null) return true
        if (!this.searchFilter(node)) return false
        while (parent) {
          if (!this.isExpanded(parent)) return false
          parent = tree.parentOf(parent)
        }
        return true
      })
      .map(node => {

        const key = nodeKey(node, this.props.keyGen)

        const disallowClearing = this.props.clearable === false
          && mode === 'many' 
          && this.state.selected.size <2 
          && this.state.selected.has(key)
        
        const commonProps = {
          node,
          mode,
          key,
          // React consumes the `key` prop, so if we want to access it further
          // down the hierarchy, we need to pass it on a separate prop as well.
          uniqueId       : key, 
          name           : this.name,
          textKey        : this.textKey,
          url            : mode === BAThingPicker.modes.url ? this.nodeURL(node) : null,
          nodeMeta       : tree.metaOf(node),
          searchMatch    : this._is("searchMatches", node),
          searchTerm     : this.state.searchTerm,
          collapseParent : () => this.collapseParentIfOpen(node),
          tooltipPosition: this.props.tooltipPosition || 'right'
        }

        return node.children != null
          ? <Branch
              {...commonProps}
              expanded            = { this.isExpanded(node) }
              toggleExpanded      = { () => this.toggleExpanded(node) }
              toggleSelectAll     = { () => this.toggleSelectAll(node) }
              selectedChildren    = { this.selectedChildren(node) }
              allSelected         = { this.allChildrenSelected(node) }
            />
          : <Leaf
              {...commonProps}
              selected            = { this.isSelected(node)}
              toggleSelected      = { !disallowClearing ? () => this.onClickToggle(node) : void 0 }
            />
      })

    return nodes
  }

  searchFilter (node) {
    return this.state.searchTerm.length < 1 || this._is("searchToKeep", node)
  }

  @autobind
  onSearch (e) {
    const searchTerm = e.target.value 

    if (searchTerm.length < 1) {

      this.setState(state => {
        const newState = {
          ...state,
          searchTerm,
          searchMatches  : new Set(),
          searchToKeep   : new Set(),
          searchExpanded : new Set()
        }
        return newState
      })
      return
    }

    const tree = this.state.itemTree

    const searchMatchingNodes = tree.filter(node => (node[this.textKey] || "").toLowerCase().includes(searchTerm.toLowerCase()))

    const [parents, children] = searchMatchingNodes.reduce(
      (accumulator, node) => {
        const [parents, children] = accumulator
        const newChildren = tree.filter(child => tree.pathOf(child).includes(node))

        return [[...parents, ...tree.pathOf(node)], [...children, ...newChildren]]
      },
      [[], []]
    )

    const searchMatches  = new Set(searchMatchingNodes)
    const searchChildren = new Set(children)
    const searchParents  = new Set(parents)

    const searchToKeep = new Set([
      ...searchParents, 
      ...searchMatches, 
      ...searchChildren].map(item => nodeKey(item, this.props.keyGen))
    )

    this.setState(state => {
      const newState = {
        ...state,
        searchTerm,
        searchMatches,
        searchToKeep,
        searchExpanded : new Set(Array.from(searchParents).map(item => nodeKey(item, this.props.keyGen)))
      }
      return newState
    })
  }

  render () {
    const { prompt, cancel, confirm, showSearch = true, clearable = true } = this.props

    const cancelButton = cancel
    ? <BAButton className="left" color="hazard" onClick={cancel}>
        Cancel
      </BAButton>
    : null
      
    const confirmButton = confirm
    ? <BAButton className="right" onClick={() => confirm(this.result())}>
        OK
      </BAButton>
    : null
    
    const countAndClear = this.state.selected.size 
    ? <div>{this.state.selected.size} selected 
        {clearable ? <BAButton 
          bare 
          size="micro" 
          onClick={() => this.setState({ selected: new Set() })}
        >x</BAButton> : null}
      </div>
    : null 


    const footer = confirmButton || cancelButton
      ? <Fragment>
          <hr />
          <FlexContainer>
            { cancelButton }
            { countAndClear }
            { confirmButton }
          </FlexContainer>
        </Fragment>
      : null

    return <PickerContainer dark={this.props.dark} padded={this.props.padded}>
      <header>
        { prompt ? <h3>{prompt}</h3> : null }
        { showSearch ? (
          <Search
            placeholder = "🔎"
            onChange    = { (event)=>{
              event.persist()
              this.handleSearch(event)
            }}
          />
         ) : null }
      </header>
      <ThingList
        maxHeight={this.props.maxHeight}
        onKeyDown={e => {
          switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
              e.preventDefault()
          }
        }}
        onKeyUp={e => {
            switch (e.key) {
              case "ArrowDown":
                focusNext(e)
                break
              case "ArrowUp":
                focusPrev(e)
                break
            }
          }
        }
      >
      {this.listItems()}
      </ThingList>
      { footer }
    </PickerContainer>
  }

}

const BAFloatingPalette = styled(BAPalette)`
  &::before {
    content: "";
    position: absolute;
    width: 1em;
    height: 1em;
    display: block;
    background: var(--content-bg);
    border-left: 2px solid var(--border);
    border-bottom: 2px solid var(--border);
    transform:
      translate(
        calc(-0.5em - 2px),
        calc(${props => ((props.position || {}).offset || 0) * 2 }px - 0.5em - 2px) /* forgive me ðŸ¤® */
      )
      rotate(45deg);
  }
`

const BAThingPalette = props => <BAPalette 
  dark={props.dark} 
  position={props.position}
  zIndex={props.zIndex}
>
  <BAThingPicker {...props} />
</BAPalette>

const BAFloatingPaletteWithThingPicker = props => <BAFloatingPalette 
  dark={props.dark} 
  position={props.position}
  zIndex={props.zIndex}
>
  <BAThingPicker {...props} />
</BAFloatingPalette>


const BAFloatingThingPalette = withRootClose(withBAPortal(BAFloatingPaletteWithThingPicker))

class BAThingPickerButton extends React.Component {

  constructor (props) {
    super(props)
    this.btnTrigger = createRef()
    this.state = {
      showPalette : false,
      position : {
        x: 0, y: 0,
        offset : 0,
        position: ''
      }
    }
  }

  @autobind
  open() {

    const buttonRect = this.btnTrigger.current.getBoundingClientRect()
    const top        = this.props.position === 'fixed' ? buttonRect.top : buttonRect.top + window.pageYOffset
    const offset     = buttonRect.height / 2

    const position   = {
      x : `calc(${buttonRect.left + buttonRect.width}px + 1em)`, // CSS calc because we're mixing ems and px
      y : top  - offset + "px",
      offset, 
      position: this.props.position
    }

    this.setState({ position, showPalette : true })
  }

  render () {
    const confirm = selected => {
      this.setState({ showPalette : false }, () => {
        if (this.props.confirm) this.props.confirm(selected)
      })
    }
    const cancel = () => this.setState({ showPalette : false })
    const close  = () => this.setState({ showPalette : false }, () => {
      if (this.props.onClose) this.props.onClose(Array.from(this.state.selected))
    })

    const extraProps = {}
    if (this.props.onChange) extraProps.onChange = this.props.onChange
    if (this.props.mode) extraProps.mode = this.props.mode

    const icon = this.props.icon || <i className="fas fa-plus"></i>

    return <Fragment>
      <BAButton
        circle
        solid
        className = { this.state.showPalette ? "open" : "" }
        onClick   = { this.open }
        ref       = { this.btnTrigger }
        size      = { this.props.triggerSize || "medium" }
      >
        {icon}
      </BAButton>
      <BAFloatingThingPalette
        {...this.props}
        {...extraProps}
        position  = { this.state.position }
        isOpen    = { this.state.showPalette }
        confirm   = { confirm }
        cancel    = { cancel }
        onClose   = { close }
      />
    </Fragment>
  }
}

export {
  BAThingPicker,
  BAFloatingThingPalette,
  BAThingPalette,
  BAThingPickerButton,
  BAPalette,
  BAFloatingPalette
}
