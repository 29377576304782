import React from 'react'
import styled from 'styled-components'

export const StyledCard = styled.div`
  display: inline-block;
  min-height: 100px;
  min-width: 150px;
  max-width: ${({maxWidth}) => maxWidth || 'inherit'};
  box-shadow: 2px 2px 8px var(--dark-bg-trans);
  margin: 1rem;
  padding: 1rem 1rem 0.5rem 1rem ;
  display: ${({display}) => display || 'inline-block'};
  border-radius: 5px;
  background-color: var(--content-bg);
  

  :hover {
    opacity: 1;
    background: var(--highlight-lighter);
    transition: 300ms ease-in-out;
  }
`

export const CardDescriptor = styled.div`
  text-align: left;
`

export const CardStatistic = styled.div`
  font-size: var(--text-size-jumbo);
  line-height: var(--text-line-height-jumbo);
`

export const CardButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 0 0.4rem 0;  
`

export const BACard = ({children}) => {
    
  return (
    <StyledCard tabindex="0">
      {children}
    </StyledCard>
  )
}