import React, {Component,
              createContext} from 'react'
import styled                from 'styled-components'
import {generateId}          from './_shared/vars'
import {BAPortal}            from './portal'

const ModalContext = createContext({
  flashes        : [],
  addFlash       : () => {},
  hideFlash      : () => {}
})


class ModalProvider extends Component {
  constructor() {
    super()
    this.timeouts = {}
  }

  componentWillUnmount() {
    // clear any remaining timeouts to prevent data leaks
    Object.values(this.timeouts || {}).forEach(timeout => clearTimeout(timeout))
  }

  bulkAddFlashes = (flashesArray) => {
    let flashes = this.state.flashes.slice()

    for (let f of flashesArray) {
      let {message, color, iconClass, delay} = f 

      let id        = generateId()
      let newFlash  = {id, message, color, iconClass, delay}

      if (delay) this._setDelayForRemoval(id, delay)

      flashes.push(newFlash)
    }

    this.setState({flashes})
  }

  addFlash = ({message, color, iconClass, delay}) => {
    const id        = generateId()
    const newFlash  = {id, message, color, iconClass, delay}
    const flashes   = [...this.state.flashes, newFlash]
    
    this.setState({flashes})
    if (delay) this._setDelayForRemoval(id, delay)
  }

  _setDelayForRemoval(id, delay) {
    this.timeouts[id] = setTimeout(() => {
      this.hideFlash(id)
    }, delay)
  }

  // remove either a flash or a modal from its array in state.
  hideFlash = id => {
    const newArray = this.state.flashes.filter(item => item.id !== id)
    this.setState({flashes: newArray})
    if (this.timeouts[id]) clearTimeout(this.timeouts[id])
  }

  state = {
    flashes        : [],
    hideFlash      : this.hideFlash,
    addFlash       : this.addFlash,
    bulkAddFlashes : this.bulkAddFlashes
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

const ModalConsumer = ModalContext.Consumer

// ModalRoot: a ModalConsumer containing both Flash and Modal rendering containers.
const ModalRoot = () => (
  <ModalConsumer>
    {({ flashes, hideFlash }) => (
      <BAFlashContainer
        flashes   = {flashes}
        hideFlash = {id => hideFlash(id)}
      />
    )}
  </ModalConsumer>
)


// FLASH COMPONENTS -------------------------------------------

const StyledFlashContainer = styled.div`
position       : fixed;
width          : 300px;
display        : flex;
flex-direction : column;
top            : 45px;
left           : 0;
right          : 0;
margin-left    : auto;
`

const BAFlashContainer = ({flashes, hideFlash}) => (
  <BAPortal isOpen>
    <StyledFlashContainer>
      {flashes.length
        ? flashes.map(f => (
          <BAFlash
            key       = {f.id}
            message   = {f.message}
            color     = {f.color}
            iconClass = {f.iconClass}
            onClick   = {() => {
              if (f.onClick) f.onClick()
              hideFlash(f.id)
            }}
          />
      )) : null}
    </StyledFlashContainer>
  </BAPortal>
)

const styledHeight = '35px'
const styledPadding = ' 0.5em 2rem 0.5rem 1rem'

const StyledFlash = styled.div`
padding       : ${styledPadding};
border-radius : 0.2rem;
display       : block;
align-items   : center;
opacity       : 0.8;
height        : ${styledHeight};
border        : 1px dashed white;
margin        : 4px 4px 4px 0;
background    : ${props => `var(--${props.color || 'text-main'}`};
color         : white;
cursor        : pointer;
white-space   : nowrap;
overflow      : hidden;
text-overflow : ellipsis;
`


const BAFlash = props => (
  <StyledFlash {...props}>
    {props.iconClass
      ? <span className='margin-right'><i className={props.iconClass}/></span>
      : null}
    <span>{props.message}</span>
  </StyledFlash>
)


// FLASH EXAMPLE -------------------------------------------------

const flashWords = [
  'FLASH!',
  'AHHHHHH....',
  'SAVIOUR OF THE UNIVERSE!',
  'FLASH!',
  'AHHHHHH....',
  'SAVES EVERY ONE OF US'
]

const getRandomColor = () => {
  const colors = [
    "confirm", "hazard", "", "warning", "highlight"
  ]
  return colors[Math.floor((Math.random()*5))]
}

const addExampleFlash = (addFlash, flashes) => {
  addFlash({
    message   : flashWords[flashes.length] || 'enough.',
    color     : getRandomColor(),
    iconClass : 'fa fa-bolt',
    delay     : 3000
  })
}

export {
  ModalProvider,
  ModalRoot,
  ModalConsumer,
  addExampleFlash
}
