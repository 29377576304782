/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example'
import { ExampleOne, ExampleTwo, ExampleThree, ExampleFour, ExampleFive } from './withErrorBoundaryHelpers'

export const meta = {
  name: "Error Handling HOCs",
  category: "HOCs, Hooks, & Functions",
  tags: [],
  components: [],
  functions: ["withErrorBoundary", "withConfiguredErrorBoundary"],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`WithErrorBoundary and WithConfiguredErrorBoundary`}</h1>
    <p>{`These Higher Order Components let you handle scripting errors without crashing the front-end.`}</p>
    <p>{`The simple `}<inlineCode parentName="p">{`withErrorBoundary`}</inlineCode>{` HOC can wrap anything with zero config, and will display the results of `}<inlineCode parentName="p">{`error.toString()`}</inlineCode>{` for the user.`}</p>
    <p>{`If you're wrapping code that throws useful errors, or if you're working on a feature where it's OK for the user to learn that `}<inlineCode parentName="p">{`undefined is not a function`}</inlineCode>{` crack on with that.`}</p>
    <h2>{`Simply present the thrown error to the user`}</h2>
    <p>{`Consider the following example:`}</p>
    <pre><code parentName="pre" {...{}}>{`const unexpectedlyUndefinedCollection = undefined

const Thrower = () => <div>
  {unexpectedlyUndefinedCollection.map((item, index) => <div key={index}>{item}</div>)}
  </div>

const ExampleOne = withErrorBoundary(Thrower)
`}</code></pre>
    <p>{`In this contrived example we have a component which maps over an array rendering a division containing each item, but the array is unexpectedly undefined. When we try to render it, we'll see the contents of the error reported to the user.`}</p>
    <Example twoColumn mdxType="Example">
  <ExampleOne mdxType="ExampleOne" />
    </Example>
    <h2>{`Throw user-friendly errors to get the most out of this strategy`}</h2>
    <p>{`You may not want to let error messages leak through to the user, but if you know you're working with an API that fails with useful errors, this approach is fine.`}</p>
    <p>{`Consider this example:`}</p>
    <pre><code parentName="pre" {...{}}>{`const getTheWeatherData = () => {
  // this function simulates an API call which fails with a useful error message
  throw new Error("The weather API is unavailable")
}


const ComponentWhichThrowsUsefulErrors = () => {
  const weather = getTheWeatherData()
  return <article>Hi there!</article>
}

const ExampleTwo = withErrorBoundary(ComponentWhichThrowsUsefulErrors)
`}</code></pre>
    <p>{`In this example we have a component which makes a call which we know throws useful errors. We can usefully display these errors directly to the user, so the simple `}<inlineCode parentName="p">{`withErrorBoundary`}</inlineCode>{` HOC works well.`}</p>
    <Example twoColumn mdxType="Example">
  <ExampleTwo mdxType="ExampleTwo" />
    </Example>
    <h2>{`Configuration Options`}</h2>
    <p>{`If you need a bit more finesse over the error experience, you can pass a configuration object to the higher order component. This is useful if your component handles and displays error props.`}</p>
    <p>{`The configuration object can have the following properties, all of which are optional:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`key`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`value type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`behaviour`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`errorPropKey`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When passed, the HOC will pass any error caught to the wrapped component on this prop-name. If no error is caught, `}<inlineCode parentName="td">{`props[errorPropKey]`}</inlineCode>{` will be undefined `}<br /><br />{` ⚠️ note ⚠️ it's up to you to make sure your component doesn't continue throwing the same error when it gets something on `}<inlineCode parentName="td">{`props[errorPropKey]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`errorPropGenerator`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(error: Error) => unknown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Take whatever error is caught and use it to generate a value that will be passed to the wrapped component on `}<inlineCode parentName="td">{`props[errorPropKey]`}</inlineCode>{` `}<br /><br />{` ⚠️ note ⚠️ If no `}<inlineCode parentName="td">{`errorPropKey`}</inlineCode>{` is present, this function `}<em parentName="td">{`must`}</em>{` generate a valid react child (string, null, component) that can be rendered as the error message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onError`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`(error: Error, info: React.ErrorInfo) => void`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This callback will be executed if an error is caught. Use this to hook into error reporting systems, bugbot, etc.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`shouldRetry`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If true, the component will make a second attempt to render the wrapped component on a 3000ms timeout.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`errorMessage`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If passed, the component will render this error message if `}<em parentName="td">{`any`}</em>{` error is caught. This has no effect if an `}<inlineCode parentName="td">{`errorPropKey`}</inlineCode>{` is present.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Further Examples`}</h2>
    <h3>{`Providing a pre-defined error message`}</h3>
    <p>{`Let's take a component which throws an unhelpful error during render, and configure the error boundary a few different ways.`}</p>
    <pre><code parentName="pre" {...{}}>{`const Thrower = props => {
  throw new Error("I am an unhelpful message")
  return "Oops, i will never get returned"
}
`}</code></pre>
    <p>{`if we wrap it with a configuration that has an `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{`, we can avoid showing the user the unhelpful message`}</p>
    <pre><code parentName="pre" {...{}}>{`const config = {
  errorMessage: "we're terribly sorry, there seems to have been some kind of problem"
}

const ExampleThree = withConfiguredErrorBoundary(config)(Thrower)
`}</code></pre>
    <p>{`When we try to render this wrapped component, we'll get a friendly error message:`}</p>
    <Example twoColumn mdxType="Example">
  <ExampleThree mdxType="ExampleThree" />
    </Example>
    <h3>{`Generating a message in response to the circumstances surrounding the error`}</h3>
    <p>{`We can make this message dynamic, using the `}<inlineCode parentName="p">{`errorPropGenerator`}</inlineCode>{` configuration optional`}</p>
    <pre><code parentName="pre" {...{}}>{`const config = {
  errorPropGenerator (error) {
    return \`We're terribly sorry, An error was encountered at \${new Date().toLocaleString()}: \${error.toString()}\`
  }
}

const ExampleFour = withConfiguredErrorBoundary(config)(Thrower)
`}</code></pre>
    <p>{`Now the error will get a timestamp, and a feed-out of the error description`}</p>
    <Example twoColumn mdxType="Example">
  <ExampleFour mdxType="ExampleFour" />
    </Example>
    <h3>{`Passing a new prop into the component which threw`}</h3>
    <p>{`If we have a more complex component like a Report output, we may already have a mechanism for displaying errors or warnings. In such cases, instead of simply rendering a message `}<em parentName="p">{`instead of`}</em>{` the component, we may want to catch the error, and pass it back into the component on a given prop. We can use the errorPropKey to do this.`}</p>
    <p>{`We'll need to make sure we wrap a component that handles such a prop, otherwise this pattern could cause the error to be thrown again, causing the prop to be passed again, and so on. Here's a contrived example of a component which handles its own error display:`}</p>
    <pre><code parentName="pre" {...{}}>{`const HandlingThrower = props => {
  if (props.warnings != null) return \`⚠️⚠️⚠️⚠️\${props.warnings}⚠️⚠️⚠️⚠️\`
  throw new Error("I always throw if there are no warnings")
  return "I'll never get this far"
}
`}</code></pre>
    <p>{`Wrapping this with a configured error boundary that includes an `}<inlineCode parentName="p">{`errorPropKey`}</inlineCode>{` let's us handle the error and pass it back into the component`}</p>
    <pre><code parentName="pre" {...{}}>{`const config = {
  errorPropKey: "warnings"
}
const ExampleFive = withConfiguredErrorBoundary(config)(HandlingThrower)
`}</code></pre>
    <p>{`When this component throws, the error boundary will catch the error, and pass it back in on the warnings prop.`}</p>
    <Example twoColumn mdxType="Example">
  <ExampleFive mdxType="ExampleFive" />
    </Example>
    <h3>{`Clearing the state error`}</h3>
    <p>{`Under some scenario we may want the HOC component to be able to reset its error state on demand`}</p>
    <p>{`For this, the HOC passes down a function called `}<inlineCode parentName="p">{`clearError`}</inlineCode>{` which can be invoked by the wrapped component to clear the error state`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
