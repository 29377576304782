/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example.jsx'

export const meta = {
  name: "BA Styleguide Home 🏠",
  category: "Style Guide",
  tags: ["home", "index", "help", "about", "readme"],
  components: [],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Welcome`}</h1>
    <p>{`Browse the components on the left to begin.`}</p>
    <h2>{`Getting set up with `}<inlineCode parentName="h2">{`ba-component-lib`}</inlineCode>{` on your local machine and running the styleguide`}</h2>
    <ul>
      <li parentName="ul">{`Clone the repo from `}<a parentName="li" {...{
          "href": "https://bitbucket.org/brightanalytics/ba-component-lib/src/master/"
        }}>{`BitBucket`}</a></li>
      <li parentName="ul">{`navigate to `}<inlineCode parentName="li">{`ba-component-lib`}</inlineCode>{` and run `}<inlineCode parentName="li">{`npm install`}</inlineCode>{` from the command line.`}</li>
      <li parentName="ul">{`run `}<inlineCode parentName="li">{`npm run dev`}</inlineCode>{` and go to `}<inlineCode parentName="li">{`localhost://1234`}</inlineCode></li>
    </ul>
    <p>{`This does 2 things:`}</p>
    <ul>
      <li parentName="ul">{`The script file `}<inlineCode parentName="li">{`list-pages.js`}</inlineCode>{` gets called, which generates a file called `}<inlineCode parentName="li">{`pages.js`}</inlineCode>{` containing relevant exports from all `}<inlineCode parentName="li">{`mdx`}</inlineCode>{` files in `}<inlineCode parentName="li">{`styleguide/pages`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`ParcelJS bundles everything needed to see the Styleguide pages, starting at `}<inlineCode parentName="li">{`index.html`}</inlineCode>{` - and hosts a dev server with hot reloading at `}<inlineCode parentName="li">{`localhost://1234`}</inlineCode>{`.`}</li>
    </ul>
    <h2>{`Importing a component from `}<inlineCode parentName="h2">{`ba-component-lib`}</inlineCode>{` into another project`}</h2>
    <ul>
      <li parentName="ul">{`Create your component in the folder `}<inlineCode parentName="li">{`/lib/components`}</inlineCode>{` in `}<inlineCode parentName="li">{`ba-component-lib`}</inlineCode></li>
      <li parentName="ul">{`Import it into `}<inlineCode parentName="li">{`ba-component-lib`}</inlineCode>{`'s entry point file, lib/index.js - and export it from there with the other components.`}</li>
      <li parentName="ul">{`You can now use `}<inlineCode parentName="li">{`import {MyComponent} from 'ba-component-lib'`}</inlineCode>{` in any project that has the package installed.`}</li>
    </ul>
    <h2>{`Using `}<inlineCode parentName="h2">{`npm link`}</inlineCode>{` to connect your local copy of `}<inlineCode parentName="h2">{`ba-component-lib`}</inlineCode>{` to other repos while developing`}</h2>
    <p><strong parentName="p">{`In the component library repo`}</strong></p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`npm run build:prod`}</inlineCode>{` - To build the current local version of the component library. (This needs to be re-run on every code change)`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`npm link`}</inlineCode>{` - To then create a global symlink to the component library.`}</li>
    </ol>
    <p><strong parentName="p">{`In the app repo`}</strong></p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`npm link ./local_path_to_the_component_library`}</inlineCode>{` e.g. npm link ../ba-component-lib this tells the app to use the symlinked version you created earlier.`}</li>
      <li parentName="ol">{`You may also need to use the component libraries react dependency. To do this you run: `}<inlineCode parentName="li">{`npm link ./local_path_to_the_component_library/node_modules/react`}</inlineCode>{` e.g. npm link ../ba-component-lib/node_modules/react`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Your project's `}<inlineCode parentName="li">{`node_modules`}</inlineCode>{` folder now contains a direct link to `}<inlineCode parentName="li">{`ba-component-lib`}</inlineCode></li>
      <li parentName="ul">{`When you make a change in `}<inlineCode parentName="li">{`ba-component-lib`}</inlineCode>{`, run `}<inlineCode parentName="li">{`npm run build:prod`}</inlineCode>{` - the contents of the `}<inlineCode parentName="li">{`dev-build`}</inlineCode>{` folder will update and your project should get the updated library too.`}</li>
    </ul>
    <h2>{`Exporting `}<inlineCode parentName="h2">{`ba-component-lib`}</inlineCode>{` for use in production`}</h2>
    <ul>
      <li parentName="ul">{`run `}<inlineCode parentName="li">{`npm run build-prod`}</inlineCode>{`. Webpack will update the contents of 'dist'.`}</li>
      <li parentName="ul">{`increment the package version number in `}<inlineCode parentName="li">{`package.json`}</inlineCode></li>
      <li parentName="ul">{`Follow the usual Git Pull Request process for getting the code merged into the master remote branch.`}</li>
      <li parentName="ul">{`Hit `}<inlineCode parentName="li">{`npm update ba-component-lib`}</inlineCode>{` in repos that use the library, and you should download the updated code.`}</li>
    </ul>
    <h2>{`Building the Styleguide for release - TODO`}</h2>
    <h2>{`Documentation Guidelines - TODO`}</h2>
    <p><strong parentName="p">{`Adding a new component`}</strong></p>
    <ul>
      <li parentName="ul">{`Create a new folder in `}<inlineCode parentName="li">{`lib/components`}</inlineCode></li>
      <li parentName="ul">{`Add the component file to the folder`}</li>
      <li parentName="ul">{`Create an MDX file in the same folder (MDX files are now stored alongside the component to help with organisation)`}</li>
      <li parentName="ul">{`The MDX file needs to have an exported meta e.g.`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`export const meta = {
  name: "Alert",              // the component name
  category: "Components",     // which category it fits into
  tags: [],                   // any additional search tags
  components: ["BATable"],    // a complete list of all components in the file
  functions: [],
  values: {}
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`Import the component and add it to the list of exports in `}<inlineCode parentName="li">{`lib/index.ts`}</inlineCode></li>
      <li parentName="ul">{`Reload the app and your new component should be in the menu 🥳`}</li>
      <li parentName="ul">{`Bump the version number in `}<inlineCode parentName="li">{`package.json`}</inlineCode></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
