/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example            from '../lib/example'
import BADropdown,
      {BADropdownExample} from '/lib/components/dropdown'
import BAPopperMenu       from '/lib/components/PopperMenu/PopperMenu'
import BAPopperIconMenu   from '/lib/components/PopperMenu/PopperIconMenu'
import {BAButton}         from '/lib/components/buttons'

export const meta = {
  name: "Dropdowns",
  category: "Components",
  tags: [],
  components: ["BADropdown", "BAPopperMenu", "BAPopperIconMenu"],
  functions: [],
  values: {
    selected: {
      value: 'op1',
      label: 'option 1'
    },
    selected_icon: {
      value: 'op9',
      label: 'timeline',
      disabled: false,
      icon: 'fa fa-tasks'
    }
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BADropdown `}<br /><small>{`A component that renders a Toggle Button and dropdown content for it to trigger.`}</small></h1>
    <p>{`Useful for dropdown menus such as those on reports and in the Header Nav.`}</p>
    <p>{`Is wrapped with the `}<inlineCode parentName="p">{`withRootClose`}</inlineCode>{` Higher Order Component (HOC), which controls its visible state.`}</p>
    <p>{`This means when the user clicks outside of the Toggle Button and the dropdown content, the dropdown content will hide.`}</p>
    <h2>{`The `}<inlineCode parentName="h2">{`withPortal`}</inlineCode>{` prop`}</h2>
    <p>{`The dropdown can optionally be rendered through a BAPortal component.
This makes it appear at the top of the DOM with absolute positioning,
so it will not affect the layout of other content.`}</p>
    <p>{`RootClosing will still work with the portal thanks to the use of a ref-setting function passed down by withRootClose.`}</p>
    <h2>{`Example with the `}<inlineCode parentName="h2">{`withPortal`}</inlineCode>{` prop`}</h2>
    <Example mdxType="Example">
  <BADropdownExample withPortal mdxType="BADropdownExample" />
    </Example>
    <h2>{`Example without the `}<inlineCode parentName="h2">{`withPortal`}</inlineCode>{` prop`}</h2>
    <Example mdxType="Example">
  <BADropdownExample mdxType="BADropdownExample" />
    </Example>
    <p>{`The imported code for the above examples is as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`const BADropdownExampleButton = ({onClick}) => (
  <BAButton
    size="medium"
    solid
    onClick={onClick}
  >
    puppy
  </BAButton>
)

const BADropdownExample = ({withPortal}) => (
  <BADropdown
    withPortal    = {withPortal}
    toggleButton  = {BADropdownExampleButton}
    portalOffsets = {[50, 50]}
  >
    <div style={{
        zIndex     : '100000000',
        height     : '500px',
        width      : '500px',
        background : "url('http://media.bassblog.pro/upload/data/6/6feb5cd0681d0b5e.svg')"
      }}>
    </div>
  </BADropdown>
)
`}</code></pre>
    <h2>{`Positioning the dropdown contents with the `}<inlineCode parentName="h2">{`portalOffsets`}</inlineCode>{` prop`}</h2>
    <p>{`When using a `}<inlineCode parentName="p">{`withPortal`}</inlineCode>{` BADropdown, you may find its content a bit out of place.`}</p>
    <p>{`Its position comes from the `}<inlineCode parentName="p">{`left`}</inlineCode>{` and `}<inlineCode parentName="p">{`top`}</inlineCode>{` values from calling `}<inlineCode parentName="p">{`getBoundingClientRect`}</inlineCode>{` on the ToggleButton's container.`}</p>
    <p>{`First bear in mind the portal's container (div#portal-root) must have fixed positioning for this to work!`}</p>
    <p>{`By adding `}<inlineCode parentName="p">{`portalOffsets`}</inlineCode>{` as a prop you can give it an array of 2 numerical values to nudge the content.`}</p>
    <h2>{`Adding the ToggleButton to BADropdown`}</h2>
    <p>{`Define a button-rendering function as in the example above, omitting its click handler, and pass it down as the `}<inlineCode parentName="p">{`toggleButton`}</inlineCode>{` prop.
It will be rendered by `}<inlineCode parentName="p">{`BADropdown`}</inlineCode>{` with a click handler that toggles the dropdown's visible state.`}</p>
    <h2>{`Additional Props Acted Upon by the `}<inlineCode parentName="h2">{`withRootClose`}</inlineCode>{` HOC`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`isOpen`}</inlineCode>{`, Boolean: if you want to control the `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` state of your dropdown content from a parent component,
pass it down as this prop. This will supplant the `}<inlineCode parentName="p">{`false`}</inlineCode>{` value `}<inlineCode parentName="p">{`withRootClose`}</inlineCode>{` starts with by default.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`onClose`}</inlineCode>{`, Function: again, if the component's `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` state is being controlled by a parent, OR if you simply have other
functionality that you want to trigger when the component closes, pass that down in this function prop.
`}<inlineCode parentName="p">{`withRootClose`}</inlineCode>{` will call that instead of / as well as its own state setting function.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`closeAfterDelay`}</inlineCode>{`, Boolean: on top of listening for clicks outside the Toggle Button and the dropdown content,
withRootClose will also listen for the mouse hovering away from them for 1.5 seconds.`}</p>
      </li>
    </ul>
    <h1>{`BAPopperMenu`}</h1>
    <p>{`After introducing BAPopper to the repo, and in replacing a React Bootstrap drop-up menu component (RBS.ButtonToolbar),
we built a menu component which supports enabled / disabled options, as well as a `}<inlineCode parentName="p">{`width`}</inlineCode>{` prop:`}</p>
    <Example mdxType="Example">
  <BAPopperMenu theme="light" textSize="text-medium" buttonSize="medium" currentSelection={props.get('selected')} options={[{
        value: 'op1',
        label: 'option 1'
      }, {
        value: 'op2',
        label: 'option 2 wiiiiddeee',
        disabled: false
      }, {
        value: 'op3',
        label: 'option 3 (disabled)',
        disabled: true
      }, {
        value: 'op4',
        label: 'option 4',
        disabled: false
      }]} onChange={option => props.set('selected', option)} width="250px" mdxType="BAPopperMenu" />
    </Example>
    <p>{`The PopperMenu can be left aligned:`}</p>
    <Example mdxType="Example">
  <BAPopperMenu theme="light" textSize="text-medium" buttonSize="medium" currentSelection={props.get('selected')} align="left" zIndex='var(--z-index-layer-6)' width="150px" options={[{
        value: 'op1',
        label: 'option 1'
      }, {
        value: 'op2',
        label: 'option 2',
        disabled: false
      }, {
        value: 'op3',
        label: 'option 3 (disabled)',
        disabled: true
      }, {
        value: 'op4',
        label: 'option 4',
        disabled: false
      }]} onChange={option => props.set('selected', option)} mdxType="BAPopperMenu" />
    </Example>
    <p>{`This PopperMenu can be disabled completely:`}</p>
    <Example mdxType="Example">
  <BAPopperMenu theme="light" textSize="text-medium" buttonSize="medium" currentSelection={props.get('selected')} disabled options={[{
        value: 'op1',
        label: 'option 1'
      }, {
        value: 'op2',
        label: 'option 2',
        disabled: false
      }, {
        value: 'op3',
        label: 'option 3 (disabled)',
        disabled: true
      }, {
        value: 'op4',
        label: 'option 4',
        disabled: false
      }]} onChange={option => props.set('selected', option)} mdxType="BAPopperMenu" />
    </Example>
    <p>{`So that it can close when an option is selected, it controls its own 'isOpen' state and passes this down to the rootClose higher order component.`}</p>
    <p>{`The PopperMenu can be assigned a max height:`}</p>
    <Example mdxType="Example">
  <BAPopperMenu theme="light" textSize="text-medium" buttonSize="medium" currentSelection={props.get('selected')} options={[{
        value: 'op1',
        label: 'option 1'
      }, {
        value: 'op2',
        label: 'option 2'
      }, {
        value: 'op3',
        label: 'option 3'
      }, {
        value: 'op4',
        label: 'option 4'
      }, {
        value: 'op5',
        label: 'option 5'
      }, {
        value: 'op6',
        label: 'option 6'
      }, {
        value: 'op7',
        label: 'option 7'
      }, {
        value: 'op8',
        label: 'option 8'
      }, {
        value: 'op9',
        label: 'option 9'
      }]} onChange={option => props.set('selected', option)} maxHeight={120} mdxType="BAPopperMenu" />
    </Example>
    <h1>{`BAPopperIconMenu`}</h1>
    <p>{`A more advanced version of the PopperMenu including icons support and more opinionated about layout positioning, also supporting enabled / disabled options and can be entirely disabled.`}</p>
    <Example mdxType="Example">
  <BAPopperIconMenu theme="light" textSize="text-medium" buttonSize="medium" currentSelection={props.get('selected_icon')} align="left" options={[{
        value: 'op1',
        label: 'statement',
        disabled: false,
        icon: 'fa fa-calculator'
      }, {
        value: 'op2',
        label: 'table',
        disabled: false,
        icon: 'fa fa-table'
      }, {
        value: 'op3',
        label: 'pie chart',
        disabled: false,
        icon: 'fa fa-chart-pie'
      }, {
        value: 'op4',
        label: 'series chart',
        disabled: false,
        icon: 'fa fa-chart-bar'
      }, {
        value: 'op6',
        label: 'hierarchy',
        disabled: true,
        icon: 'fa fa-sitemap'
      }, {
        value: 'op7',
        label: 'scatterplot',
        disabled: false,
        icon: 'fas fa-braille'
      }, {
        value: 'op8',
        label: 'heatmap',
        disabled: true,
        icon: 'fa  fa-map'
      }, {
        value: 'op9',
        label: 'timeline',
        disabled: false,
        icon: 'fa fa-tasks'
      }, {
        value: 'op10',
        label: 'funnel',
        disabled: true,
        icon: 'fa fa-wifi'
      }]} onChange={option => props.set('selected_icon', option)} mdxType="BAPopperIconMenu" />
    </Example>
    <p>{`The Popper Icon Menu can of course be forced to a width  and in that case may benefits from left alignment, like the following example`}</p>
    <Example mdxType="Example">
  <BAPopperIconMenu theme="light" textSize="text-medium" buttonSize="medium" currentSelection={props.get('selected_icon')} width="250px" align="left" options={[{
        value: 'op1',
        label: 'statement',
        disabled: false,
        icon: 'fa fa-calculator'
      }, {
        value: 'op2',
        label: 'table',
        disabled: false,
        icon: 'fa fa-table'
      }, {
        value: 'op3',
        label: 'pie chart',
        disabled: false,
        icon: 'fa fa-chart-pie'
      }, {
        value: 'op4',
        label: 'series chart',
        disabled: false,
        icon: 'fa fa-chart-bar'
      }, {
        value: 'op6',
        label: 'hierarchy',
        disabled: true,
        icon: 'fa fa-sitemap'
      }, {
        value: 'op7',
        label: 'scatterplot',
        disabled: false,
        icon: 'fas fa-braille'
      }, {
        value: 'op8',
        label: 'heatmap',
        disabled: true,
        icon: 'fa  fa-map'
      }, {
        value: 'op9',
        label: 'timeline',
        disabled: false,
        icon: 'fa fa-tasks'
      }, {
        value: 'op10',
        label: 'funnel',
        disabled: true,
        icon: 'fa fa-wifi'
      }]} onChange={option => props.set('selected_icon', option)} mdxType="BAPopperIconMenu" />
    </Example>
    <p>{`That's all for dropdown (forcing some padding here!)`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
