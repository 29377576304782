import {keyframes}         from 'styled-components'
// an extension to the standard styled div -
// creates a styled div wrapped in a <Transition /> tag.
// MUST be placed inside of a <TransitionGroup /> if you
// want it to work on mount/unmount. Also the timeout prop below needs to
// match the transition millisecond value i.e. transition: all 300ms ease-in.

const fadeIn = keyframes`
  from {opacity: 0.01;}
  to   {opacity: 1;}
`

export {
  fadeIn
}
