/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from "../lib/example";
import { InputGroup } from "/lib/components/forms/InputGroup";
import { FlexContainer } from "/lib/index";
import Select, { Creatable } from "/lib/components/forms/selects";
import { PositionedPortal } from "/lib/components/portal";
export const meta = {
  name: "Select Controls",
  category: "Components",
  tags: [],
  components: ["Select"],
  functions: [],
  values: {
    first: "left",
    second: "down",
    third: 3,
    fourth: [],
    color: "brand-3",
    creatableValues: [{
      label: 'one',
      value: 'one'
    }],
    selectedCreatableValue: {
      label: 'one',
      value: 'one'
    },
    multiSelectedCreatableValues: [{
      label: 'one',
      value: 'one'
    }]
  },
  portalRef: React.createRef()
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Selects`}</h1>
    <p>{`Selects are used to pick one option from a list. They support typeahead, and`}</p>
    <p>{`Selects operate on a list of `}<inlineCode parentName="p">{`options`}</inlineCode></p>
    <p>{`Options can either be a list of strings, or a list of objects shaped `}<inlineCode parentName="p">{`{ value, label }`}</inlineCode></p>
    <p>{`so for a list of options `}<inlineCode parentName="p">{`["left", "middle", "right"]`}</inlineCode>{` you get a select like this:`}</p>
    <Example twoColumn mdxType="Example">
  <Select id="first-input" name="first-input" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
    </Example>
    <p>{`Selects that only have two options will ever-so-slightly enlarge the selected select, so that it is obvious which select is active.`}</p>
    <Example twoColumn mdxType="Example">
  <Select id="second-input" name="second-input" options={["up", "down", "circling around and around"]} value={props.get("second")} onChange={val => props.set("second", val)} mdxType="Select" />
    </Example>
    <h2>{`Non-string values`}</h2>
    <p>{`You can use a list of react-select style option objects for a select control too. This is perfect if you need values that aren't strings, or if your values and labels need to differ in format.`}</p>
    <Example twoColumn mdxType="Example">
  <Select id="third-input" name="third-input" closeOnSelect={false} onBlurResetsInput={false} options={[{
        value: 1,
        label: "inside"
      }, {
        value: 2,
        label: "outside"
      }, {
        value: 3,
        label: "topside"
      }, {
        value: 4,
        label: "underside"
      }, {
        value: 5,
        label: "a really just unimaginably long value -- how does it deal with wrapped text?"
      }]} value={props.get("third")} onChange={val => props.set("third", val)} mdxType="Select" />
    </Example>
    <h2>{`Events`}</h2>
    <p>{`Selects fire an onChange event that passes the `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop through. If you specify your select with a list of Strings, this will be the string. If your select is specified with a list of `}<inlineCode parentName="p">{`{label, value}`}</inlineCode>{` objects, it will be the `}<inlineCode parentName="p">{`value`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`val => doSomethingWith( val )
`}</code></pre>
    <h2>{`Input Groups`}</h2>
    <p>{`Selects work nicely within the `}<inlineCode parentName="p">{`<InputGroup />`}</inlineCode>{` component, which allows you to group them together with a label, and build them into more complex form layouts. If unconstrained, for example by an Input Group, select controls will be full-width.`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroup mdxType="InputGroup">
    <label htmlFor="fourth-input">Please choose a side</label>
    <Select id="fourth-input" name="fourth-input" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
  </InputGroup>
    </Example>
    <h2>{`Multi select`}</h2>
    <p>{`By specifying a `}<inlineCode parentName="p">{`multi`}</inlineCode>{` prop equal to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, you can allow the user to select more than one option from the dropdown.
In multi select mode onChange will return an array of selected values.`}</p>
    <Example twoColumn mdxType="Example">
  <InputGroup className="full-w" mdxType="InputGroup">
    <label htmlFor="fourth-input">Pick some options</label>
    <Select id="fourth-input" name="fourth-input" options={[{
          label: "goose",
          value: 1
        }, {
          label: "octopus",
          value: 2
        }, {
          label: "meerkat",
          value: 3
        }]} value={props.get("fourth")} onChange={val => props.set("fourth", val)} multi={true} mdxType="Select" />
  </InputGroup>
    </Example>
    <h2>{`Creatable`}</h2>
    <p>{`Allows new options to be created in the dropdown. This is useful if you want to allow users to create new options on the fly.`}</p>
    <Example mdxType="Example">
  <div style={{
        width: 200
      }}>
    <Creatable options={props.get('creatableValues')} value={props.get("selectedCreatableValue")} onChange={(val, action) => {
          if (action === 'create-option') {
            props.set('creatableValues', [...props.get('creatableValues'), val]);
          }

          props.set("selectedCreatableValue", val);
        }} mdxType="Creatable" />
  </div>
    </Example>
    <h2>{`Multi Creatable`}</h2>
    <p>{`Same as creatable, but allows multiple options to be selected.`}</p>
    <Example mdxType="Example">
  <div style={{
        width: 200
      }}>
    <Creatable multi options={props.get('creatableValues')} value={props.get("multiSelectedCreatableValues")} onChange={(val, action) => {
          props.set("multiSelectedCreatableValues", val);
        }} mdxType="Creatable" />
  </div>
    </Example>
    <h2>{`Portals`}</h2>
    <p>{`Selects sometimes render inside parent components that have `}<inlineCode parentName="p">{`overflow: hidden`}</inlineCode>{` set. This can cause the dropdown to be cut off. `}</p>
    <p>{`To avoid this, you can put it inside a `}<inlineCode parentName="p">{`PositionedPortal`}</inlineCode>{` component.`}</p>
    <p>{`Illustration of the problem:`}</p>
    <div>
  <Example mdxType="Example">
    <FlexContainer mdxType="FlexContainer">
      <table>
        <tbody>
          <tr>
            <td style={{
                  overflow: 'hidden',
                  border: '1px solid coral',
                  paddingTop: '5px'
                }}>
              <InputGroup className="flex" size="medium" mdxType="InputGroup">
                <label htmlFor="portal-select">Cut off Select</label>
                <Select id="portal-select" name="portal-select" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </FlexContainer>
  </Example>
    </div>
    <p>{`Illustration of the solution.`}</p>
    <p>{`Note that now the dropdown is rendered outside of the parent component, and is not cut off.`}</p>
    <p>{`The dropdown is rendered as a child of the `}<inlineCode parentName="p">{`body`}</inlineCode>{` element, so it will not be affected by any parent components with `}<inlineCode parentName="p">{`overflow: hidden`}</inlineCode>{` set.`}</p>
    <p>{`The absolute positioning of the div now containing the dropdown means that the outer container no longer knows anything about its size - so it needs to be given some padding or a fixed height.`}</p>
    <div>
  <Example mdxType="Example">
    <FlexContainer mdxType="FlexContainer">
      <table>
        <tbody>
          <tr>
            <td style={{
                  overflow: 'hidden',
                  border: '1px solid lime',
                  padding: '5px 10px 30px 10px'
                }} id='portal-cell'>
              <InputGroup className="flex" size="medium" mdxType="InputGroup">
                <label htmlFor="portal-select">Portal Select</label>
                  <PositionedPortal mdxType="PositionedPortal">
                    <Select id="portal-select" name="portal-select" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} usePortal mdxType="Select" />
                </PositionedPortal>
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </FlexContainer>
  </Example>
    </div>
    <h2>{`Sizes`}</h2>
    <p>{`You can create Selects at different form sizes by passing a size prop to the containing InputGroup.
These should sit nicely inline with text inputs, etc.`}</p>
    <Example mdxType="Example">
  <p>micro</p>
  <FlexContainer mdxType="FlexContainer">
    <InputGroup className="flex" size="micro" mdxType="InputGroup">
      <label htmlFor="multi-select-micro">Pick some micro sized options</label>
      <Select id="multi-select-micro" name="multi-select-micro" options={[{
            label: "goose",
            value: 1
          }, {
            label: "octopus",
            value: 2
          }, {
            label: "meerkat",
            value: 3
          }]} value={props.get("fourth")} onChange={val => props.set("fourth", val)} multi={true} mdxType="Select" />
    </InputGroup>
    <InputGroup className="flex" size="micro" mdxType="InputGroup">
      <label htmlFor="text-input-micro">A regular text input</label>
      <input type="text" id="text-input-micro" />
    </InputGroup>
    <InputGroup className="flex" size="micro" mdxType="InputGroup">
      <label htmlFor="mono-select-micro">Mono Select</label>
      <Select id="mono-select-micro" name="mono-select-micro" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
    </InputGroup>
  </FlexContainer>
  <p>small</p>
  <FlexContainer mdxType="FlexContainer">
    <InputGroup className="flex" size="small" mdxType="InputGroup">
      <label htmlFor="multi-select-small">Pick some small sized options</label>
      <Select id="multi-select-small" name="multi-select-small" options={[{
            label: "goose",
            value: 1
          }, {
            label: "octopus",
            value: 2
          }, {
            label: "meerkat",
            value: 3
          }]} value={props.get("fourth")} onChange={val => props.set("fourth", val)} multi={true} mdxType="Select" />
    </InputGroup>
    <InputGroup className="flex" size="small" mdxType="InputGroup">
      <label htmlFor="text-input-small">A regular text input</label>
      <input type="text" id="text-input-small" />
    </InputGroup>
    <InputGroup className="flex" size="small" mdxType="InputGroup">
      <label htmlFor="mono-select-small">Mono Select</label>
      <Select id="mono-select-small" name="mono-select-small" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
    </InputGroup>
  </FlexContainer>
  <p>medium</p>
  <FlexContainer mdxType="FlexContainer">
    <InputGroup className="flex" size="medium" mdxType="InputGroup">
      <label htmlFor="multi-select-medium">
        Pick some medium sized options
      </label>
      <Select id="multi-select-medium" name="multi-select-medium" options={[{
            label: "goose",
            value: 1
          }, {
            label: "octopus",
            value: 2
          }, {
            label: "meerkat",
            value: 3
          }]} value={props.get("fourth")} onChange={val => props.set("fourth", val)} multi={true} mdxType="Select" />
    </InputGroup>
    <InputGroup className="flex" size="medium" mdxType="InputGroup">
      <label htmlFor="text-input-medium">A regular text input</label>
      <input type="text" id="text-input-medium" />
    </InputGroup>
    <InputGroup className="flex" size="medium" mdxType="InputGroup">
      <label htmlFor="mono-select-medium">Mono Select</label>
      <Select id="mono-select-medium" name="mono-select-medium" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
    </InputGroup>
  </FlexContainer>
  <p>large</p>
  <FlexContainer mdxType="FlexContainer">
    <InputGroup className="flex" size="large" mdxType="InputGroup">
      <label htmlFor="multi-select-large">Pick some large options</label>
      <Select id="multi-select-large" name="multi-select-large" options={[{
            label: "goose",
            value: 1
          }, {
            label: "octopus",
            value: 2
          }, {
            label: "meerkat",
            value: 3
          }]} value={props.get("fourth")} onChange={val => props.set("fourth", val)} multi={true} mdxType="Select" />
    </InputGroup>
    <InputGroup className="flex" size="large" mdxType="InputGroup">
      <label htmlFor="text-input-large">A regular text input</label>
      <input type="text" id="text-input-large" />
    </InputGroup>
    <InputGroup className="flex" size="large" mdxType="InputGroup">
      <label htmlFor="mono-select-large">Mono Select</label>
      <Select id="mono-select-large" name="mono-select-large" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
    </InputGroup>
  </FlexContainer>
  <p>jumbo</p>
  <FlexContainer mdxType="FlexContainer">
    <InputGroup className="flex" size="jumbo" mdxType="InputGroup">
      <label htmlFor="multi-select-jumbo">Pick dem</label>
      <Select id="multi-select-jumbo" name="multi-select-jumbo" options={[{
            label: "goose",
            value: 1
          }, {
            label: "octopus",
            value: 2
          }, {
            label: "meerkat",
            value: 3
          }]} value={props.get("fourth")} onChange={val => props.set("fourth", val)} multi={true} mdxType="Select" />
    </InputGroup>
    <InputGroup className="flex" size="jumbo" mdxType="InputGroup">
      <label htmlFor="text-input-jumbo">A regular text input</label>
      <input type="text" id="text-input-jumbo" />
    </InputGroup>
    <InputGroup className="flex" size="jumbo" mdxType="InputGroup">
      <label htmlFor="mono-select-jumbo">Mono Select</label>
      <Select id="mono-select-jumbo" name="mono-select-jumbo" options={["left", "middle", "right"]} value={props.get("first")} onChange={val => props.set("first", val)} mdxType="Select" />
    </InputGroup>
  </FlexContainer>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
