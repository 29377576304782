/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import { BATooltip, BAPopper } from "../../lib/components/tooltip";
import { BAButton } from "../../lib/components/buttons";
import Example from "../lib/example";

export const meta = {
  name: "Tool tips",
  category: "Components",
  tags: [],
  components: ["BATooltip", "BAPopper"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tooltips`}</h1>
    <p>{`BATooltip wraps around this library, `}<a parentName="p" {...{
        "href": "github.com/atomiks/tippy.js-react"
      }}>{`TippyJS/React`}</a>{` and provides it with a few default props.`}</p>
    <p>{`A full list of possible props is `}<a parentName="p" {...{
        "href": "atomiks.github.io/tippyjs/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`The main ones (with defaults reconfigured through BATooltip) are:`}</p>
    <ol>
      <li parentName="ol">{`content - can be either a string or JSX`}</li>
      <li parentName="ol">{`trigger - defaults to 'hover' but can be 'click'`}</li>
      <li parentName="ol">{`arrow - shows the arrow. Defaults to true.`}</li>
      <li parentName="ol">{`size - can be 'small', 'medium' or 'large'`}</li>
      <li parentName="ol">{`theme - defaults to 'dark', there is also 'light' and you can define your own using CSS - look in `}{`_`}{`tooltip.scss to see how.`}</li>
      <li parentName="ol">{`flip - allows the tooltip to reposition itself when colliding with certain boundaries. We have disabled this by default as it doesn't always work fantastically well.`}</li>
    </ol>
    <Example mdxType="Example">
  <BATooltip content="hello" mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">Default</BAButton>
  </BATooltip>
  <BATooltip content="hello" placement="bottom" mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">Bottom</BAButton>
  </BATooltip>
  <BATooltip content="hello" placement="left" mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">Left</BAButton>
  </BATooltip>
  <BATooltip content="hello" placement="right" mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">Right</BAButton>
  </BATooltip>
  <BATooltip content={<div>
        I am <strong style={{
          color: "yellow"
        }}>Yellow</strong>
      </div>} mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">Styled Text</BAButton>
  </BATooltip>
  <BATooltip content="hello" size="large" mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">Large</BAButton>
  </BATooltip>
  <BATooltip content="hello" arrow={false} mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">No Arrow</BAButton>
  </BATooltip>
  <BATooltip content="hello" trigger="click" mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">On Click</BAButton>
  </BATooltip>
  <BATooltip content="hello" theme="light" mdxType="BATooltip">
    <BAButton className="margin-right" mdxType="BAButton">Light theme</BAButton>
  </BATooltip>
    </Example>
    <h1>{`Popovers`}</h1>
    <p>{`While similar to tooltips, popovers are typically for interactive content such as controls UI, and as such appear onClick by default.`}</p>
    <p>{`BAPopover wraps another library, react-popper. TippyJS also uses it for positioning.
Neat things about Popper:`}</p>
    <ul>
      <li parentName="ul">{`it does arrow positioning pretty well in most cases`}</li>
      <li parentName="ul">{`it automatically repositions itself to another 'placement' direction if the window is too small for it.`}</li>
    </ul>
    <p>{`We have wrapped it in a styled component which makes it easy to define themes.
We have added to it the withRootClose higher order component, much in the same way as BADropdown.`}</p>
    <p>{`BAPopper takes mostly the same props as BATooltip, including:`}</p>
    <ul>
      <li parentName="ul">{`trigger - defaults to 'click' but can be 'hover'`}</li>
      <li parentName="ul">{`hideTimeout - if event is 'hover' this will delay the disappearance`}</li>
      <li parentName="ul">{`theme - defaults to 'dark', 'light' also exists`}</li>
      <li parentName="ul">{`content - String or markup`}</li>
      <li parentName="ul">{`placement`}</li>
      <li parentName="ul">{`flip - allow popper to reposition itself (when hitting boundaries of window/content etc) - it does it quite liberally so this is disabled by default.`}</li>
    </ul>
    <Example mdxType="Example">
  <BAPopper content={<div>
        <BAButton mdxType="BAButton">AAAHHHHHHH</BAButton>
      </div>} timeout={500} trigger="hover" placement="top" theme="light" mdxType="BAPopper">
    <BAButton className="margin-right" mdxType="BAButton">Top</BAButton>
  </BAPopper>
  <BAPopper content="AN UNNECESSARILY LONG SENTENCE INCORPORATING EVERYBODY'S FAVOURITE WORDS" placement="bottom" theme="light" mdxType="BAPopper">
    <BAButton className="margin-right" mdxType="BAButton">Bottom</BAButton>
  </BAPopper>
  <BAPopper content={<div>
        <BAButton mdxType="BAButton">AAAHHHHHHH</BAButton>
      </div>} placement="right" theme="light" mdxType="BAPopper">
    <BAButton className="margin-right" mdxType="BAButton">Right</BAButton>
  </BAPopper>
  <BAPopper content={<div>
        <BAButton mdxType="BAButton">AAAHHHHHHH</BAButton>
      </div>} placement="left" theme="light" mdxType="BAPopper">
    <BAButton mdxType="BAButton">Left</BAButton>
  </BAPopper>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
