import React, {Component,
                Fragment}  from 'react'
import styled, {css}       from 'styled-components'
import {closeButtonStyles} from './modal'
import {BAButton}          from './buttons'

// TODO need to normalise the user's input so that it matches the tree options...
//
// const units = [
//   'week',
//   'month',
//   'quarter',
//   'financial quarter',
//   'year',
//   'financial year'
// ]
const optionTree = {

  // 'THIS'

  'this': {
    'week': {
      'leaf': {
        type: 'This',
        unit: 'week',
        generateString: unit => `Whole ${unit} including future dates`
      },
      'to date': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'date',
          unit: 'week',
          options: ['plusToday']
        }
      },
      'to end of last week': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'end of last week',
          unit: 'week',
        }
      }
    },
    'month': {
      'leaf': {
        type: 'This',
        unit: 'month',
        generateString: unit => `Whole ${unit} including future dates`
      },
      'to date': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'date',
          unit: 'month',
          options: ['plusToday']
        }
      },
      'to end of last week': {
        'leaf' : {
          type: 'ToDate',
          toEnd: 'end of last week',
          unit: 'month',
        }
      }
    },
    'quarter': {
      'leaf': {
        type: 'This',
        unit: 'quarter',
        generateString: unit => `Whole ${unit} including future dates`
      },
      'to date': {
        leaf: {
          type: 'ToDate',
          toEnd: 'date',
          unit: 'quarter',
          options: ['plusToday']
        }
      },
      'to end of last week': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'end of last week',
          unit: 'quarter',
        }
      }
    },
    'financial quarter': {
      'leaf': {
        type: 'This',
        unit: 'financial quarter',
        generateString: unit => `Whole ${unit} including future dates`
      },
      'to date': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'date',
          unit: 'financial quarter',
          options: ['plusToday']
        }
      },
      'to end of last week': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'end of last week',
          unit: 'financial quarter',
        }
      }
    },
    'year': {
      'leaf': {
        type: 'This',
        unit: 'year',
        generateString: unit => `Whole ${unit} including future dates`
      },
      'to date': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'date',
          unit: 'year',
          options: ['plusToday']
        }
      },
      'to end of last week': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'end of last week',
          unit: 'year',
        }
      }
    },
    'financial year': {
      'leaf': {
        type: 'This',
        unit: 'financial year',
        generateString: unit => `Whole ${unit} including future dates`
      },
      'to date': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'date',
          unit: 'financial year',
          options: ['plusToday']
        }
      },
      'to end of last week': {
        'leaf': {
          type: 'ToDate',
          toEnd: 'end of last week',
          unit: 'financial year',
          options: ['plusToday']
        }
      }
    }
  },

  // END OF 'THIS'

  'last': {
    '7 Days': {
      'leaf': {
        type: 'Last',
        unit: 'days',
        number: '7'
      },
    },
    '<number>': {
      'Days': {

      }
    }
  },

  // END OF 'LAST'

  'next': {

  },

  'today': {

  },

  'yesterday': {

  },

  'between': {

  },

  'date-range': {

  },

  'from': {

  }

}
//
// const initialOptions = [year, unit, number]
//
// nextList() {
//   if ()
// }
//
// class DateSpec {
//   constructor() {
//     this.chosenNodes = []
//     this.initialOptions = [year, unit, number]
//   }
//
//   nextList() {
//     if (!this.chosenNodes.length) return this.initialOptions
//   }
//
//   getLastNode() {
//     return this.chosenNodes[this.chosenNodes.length - 1]
//   }
// }


const DatepickerContainer = styled.div`
margin-left: 2rem;
`

const StyledDatepickerResult = styled.div`
font-size     : var(--text-large);
display       : flex;
align-items   : center;
margin-bottom : 1rem;
.category {margin-left: 1.3rem;}
.result  {margin-left: 0.1rem;}
.result {color: var(--confirm);}
`

const DatepickerResult = ({whenValue}) => (
  <StyledDatepickerResult>
    <em>Sending:</em>
    {whenValue ? (
      <Fragment>
        {whenValue.type   && <span className="category">type: <span className="result">{whenValue.type}</span></span>}
        {whenValue.unit   && <span className="category">unit: <span className="result">{whenValue.unit}</span></span>}
        {whenValue.number && <span className="category">number: <span className="result">{whenValue.number}</span></span>}
        <div className="margin-left-auto">
          <BAButton
            size      = "small"
            solid
          >
            Apply
          </BAButton>
        </div>
      </Fragment>) : null}
  </StyledDatepickerResult>
)

const DatepickerInputRow = styled.div`
padding: 0 1rem;
border: 2px dashed var(--text-main);
display: flex;
align-items: center;
input, div {font-size: var(--text-size-large);}
margin-bottom: 1rem;
`

const inputStyles = css`
outline    : none;
border     : none;
color      : var(--text-main);
background : var(--bg);
::placeholder {
  color: var(--text-main);
  opacity: 0.7;
}
`

const StyledDatepickerInput = styled.input`
${inputStyles}
width      : 300px;
height     : 60px;
padding    : 0.5rem 1rem;
border-radius: 0.6rem;
`

const DatepickerInput = props => (
  <StyledDatepickerInput {...props}
    innerRef={ref => props.refSetter(ref)}
  />
)

const StyledNodeBlock = styled.div`
margin-right  : 0.5rem;
border        : 2px solid var(--text-main);
padding       : 0.5rem ${({remove}) => remove ? '0.5rem' : '1rem'} 0.5rem 1rem;
border-radius : 1rem ${({remove}) => remove ? '0.5rem' : '1rem'} 1rem 1rem;

button.close-button {
  ${closeButtonStyles};
  font-size: var(--text-size-medium);
  position : relative;
  bottom   : 0.5rem;
  left     : 0.5rem;
}

input {
  ${inputStyles};
  max-width: 3rem;
  font-size:  var(--text-size-medium);
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
`

// CHOSEN NODES - for displaying which nodes have already been chosen.

// only the last one is removable (or we'll screw our place in the tree)-
// indicated by the presence of the remove prop.
// TODO figure out a way to make
const NodeBlock = ({node, remove, onChange}) => {
  const {type, value} = node

  let nodeContent = null
  switch (type) {
    case '<number>': nodeContent = (
      <input
        autoFocus
        type     = "number"
        value    = {value || ''}
        onChange = {e => onChange(e.target.value)}
      />
    )
      break
    case 'string': nodeContent = value
  }

  return (
    <StyledNodeBlock remove={remove}>
      {nodeContent}
      {remove ? <button
        className = "close-button"
        onClick   = {remove}
      >
        <i className="fas fa-times"/>
      </button> : null}
    </StyledNodeBlock>
  )
}

// NODE OPTIONS - for displaying what nodes are available next to choose.

const NodeOptionList = styled.div`
margin-left: 2rem;
width: 400px;
`

const NodeOption = styled.div`
padding: 0.1rem 0.3rem;
cursor: pointer;
max-width: 200px;
&:hover {
  background: var(--text-main);
  color: white;
}
`

export default class Datepicker extends Component {

  constructor() {
    super()
    this.state = {
      value       : '',
      chosenNodes : []
    }
  }

  // get next possible autocompletes based on place in the options tree.
  getCurrentNodes() {
    let placeInTree = optionTree
    for (let node of this.state.chosenNodes) {
      const key = node.type === 'string' ? node.value : node.type
      if (placeInTree[key]) placeInTree = placeInTree[key]
    }

    const whenValueDict = [
      'leaf',
      'type',
      'unit',
      'number',
      'options',
      'toEnd',
      'generateString'
    ]

    const allNodes        = Object.keys(placeInTree)

    const invalidTreeKeys = whenValueDict
    const leafNodes       = allNodes.includes('leaf') ? placeInTree['leaf'] : null
    const validNodes      = allNodes.filter(option => !invalidTreeKeys.includes(option))
    const filteredNodes   = validNodes.filter(option => option.includes(this.state.value))

    return {
      visibleNodes: filteredNodes,
      leafNodes
    }
  }

  // add a new node to our list of chosen nodes.
  addNode(nodeString) {
    let newNode
    if (nodeString === '<number>') {
      newNode = {type: '<number>', value: ''}
    }

    else newNode = {type: 'string', value: nodeString}

    this.setState({
      chosenNodes: [...this.state.chosenNodes, newNode],
      value: ''
    })

    this.Input.focus()
  }

  removeNode(nodeString) {
    this.setState({
      chosenNodes: this.state.chosenNodes.filter(node => node !== nodeString)
    })
  }

  handleNodeValueChange(index, value) {
    const nodes = [...this.state.chosenNodes]
    nodes[index]['value'] = value
    this.setState({chosenNodes: nodes})
  }

  // TODO handle the following keypresses:
  // - tab and enter for taking the currently selected nodes in the dropdown
  // - delete when there's an empty input and previous nodes
  handleTextInput(value) {
    // when user hits space
    if (value.endsWith(' ') && this.getCurrentNodes().visibleNodes.includes(value.trim())) {
      this.addNode(value.trim())
      this.setState({value: ''})
    }

    else this.setState({value})
  }

  render() {
    const {visibleNodes, leafNodes} = this.getCurrentNodes()
    const {chosenNodes}  = this.state

    return (
      <DatepickerContainer>

        {/* RESULT READOUT */}
        <DatepickerResult whenValue={leafNodes} />

        {/* INPUT ROW */}
        <DatepickerInputRow>

          {chosenNodes.map((node, i) => <NodeBlock
            key      = {`node-block-${i}`}
            node     = {node}
            onChange = {val => this.handleNodeValueChange(i, val)}
            remove   = {i == chosenNodes.length - 1 ? () => this.removeNode(node) : null}
          />)}

          <DatepickerInput
            autoFocus
            refSetter   = {ref => this.Input = ref}
            placeholder = "start typing"
            value       = {this.state.value}
            onChange    = {e => this.handleTextInput(e.target.value)}
          />

        </DatepickerInputRow>

        {/* AVAILABLE NODES READOUT */}
        <NodeOptionList>
          {visibleNodes.map((option, i) => <NodeOption
            key     = {`node-option-${i}`}
            onClick = {() => this.addNode(option)}
          >
            {option}
          </NodeOption>)}
        </NodeOptionList>

      </DatepickerContainer>
    )
  }
}
