/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from "../lib/example";
import PortalClose from "/lib/components/PortalClose/PortalClose";
import { PortalCloseChild } from './PortalCloseHelpers'

export const meta = {
  name: "Portal Close",
  category: "Deprecated Components",
  tags: [],
  components: ["PortalClose"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Portal Close (deprecated)`}</h1>
    <p>{`Passes `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` & `}<inlineCode parentName="p">{`setIsOpen`}</inlineCode>{` props to children.
If it's open it renders a full width & height invisible, clickable div behind the child element.
When you click the background div it will set state to isOpen: false and remove the background div again.`}</p>
    <PortalClose mdxType="PortalClose">
  <PortalCloseChild mdxType="PortalCloseChild" />
    </PortalClose>
    <h2>Grey Portal Background</h2>
    <PortalClose greyPortalBackground mdxType="PortalClose">
  <PortalCloseChild mdxType="PortalCloseChild" />
    </PortalClose>
    <h2>{`Reasons for deprecation`}</h2>
    <ul>
      <li parentName="ul">{`this does not appear to be used anywhere in our cluster`}</li>
      <li parentName="ul">{`this does not appear to offer any functionality not already provided by the BAPortal and RootClose components`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
