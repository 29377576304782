import React from 'react'
import { BAActionLink, BAButton, BATabButton, BAButtonsTab, BAFileInput } from "/lib/components/buttons.jsx"

const variants = [
  "bare",
  "circle",
  "disabled",
  "rounded",
  "solid",
  "uppercase",
]

const powerset = arr => arr.reduce((a, v) => a.concat(a.map(r => r.concat(v))), [[]]);

const combinations = powerset(variants)
  .sort((a,b) => {
    if (a.length === b.length) return a[0] > b[0] ? 1 : -1
    else return a.length > b.length ? 1 : -1
  })
  .map(combination => {
  const props = {}
  for (const variant of combination) {
    props[variant] = true
  }
  return props
})

const buttonColors = [
  "default",
  "highlight",
  "confirm",
  "warning",
  "hazard"
]

export function ButtonExplorer () {
  return <table style={{ textAlign: "left", borderSpacing: "0.5em 0.3em" }}>
    <thead>
      <tr>
        <th></th>
        {
          buttonColors.map(color => <th key={color}>{color}</th>)
        }
      </tr>
    </thead>
    <tbody>
      {
        combinations.map(baseProps => {
          const variantName = Object.keys(baseProps).join(" ") || "default"
          return <tr key={variantName}>
              <th>{variantName}</th>
              {
                buttonColors.map(color => {
                  const props = {...baseProps}
                  if (color != "default") props.color = color
                  const text = props.circle
                    ? "C"
                    : "Click me"
                  
                  return <td key={variantName + "_" + color}>
                    <BAButton {...props}>{text}</BAButton>
                  </td>
                })
              }
            </tr>
        })
      }
    </tbody>
  </table>
}

export function ActionLinkExplorer () {
  return <table style={{ textAlign: "left", borderSpacing: "0.5em 0.3em" }}>
    <thead>
      <tr>
        <th></th>
        {
          buttonColors.map(color => <th key={color}>{color}</th>)
        }
      </tr>
    </thead>
    <tbody>
      {
        combinations.map(baseProps => {
          const variantName = Object.keys(baseProps).join(" ") || "default"
          return <tr key={variantName}>
              <th>{variantName}</th>
              {
                buttonColors.map(color => {
                  const props = {...baseProps}
                  if (color != "default") props.color = color
                  const text = props.circle
                    ? "C"
                    : "Click me"
                  
                  return <td key={variantName + "_" + color}>
                    <BAActionLink href="#" {...props}>{text}</BAActionLink>
                  </td>
                })
              }
            </tr>
        })
      }
    </tbody>
  </table>
}


export function TabButtonExplorer () {
  return <table style={{ textAlign: "left", borderSpacing: "0.5em 0.3em" }}>
    <thead>
      <tr>
        <th></th>
        {
          buttonColors.map(color => <th key={color}>{color}</th>)
        }
      </tr>
    </thead>
    <tbody>
      {
        combinations.map(baseProps => {
          const variantName = Object.keys(baseProps).join(" ") || "default"
          return <tr key={variantName}>
              <th>{variantName}</th>
              {
                buttonColors.map(color => {
                  const props = {...baseProps}
                  if (color != "default") props.color = color
                  const text = props.circle
                    ? "C"
                    : "Click me"
                    
                  const text2 = props.circle
                  ? "2"
                  : "Or me"
                  
                  const text3 = props.circle
                  ? "3"
                  : "Or me i guess"
                  
                  return <td key={variantName + "_" + color}>
                    <BAButtonsTab>
                      <BATabButton {...props}>{text}</BATabButton>
                      <BATabButton {...props}>{text2}</BATabButton>
                      <BATabButton {...props}>{text3}</BATabButton>
                    </BAButtonsTab>
                  </td>
                })
              }
            </tr>
        })
      }
    </tbody>
  </table>
}