/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '/styleguide/lib/example'
import BAAlert from './baalert'
export const meta = {
  name: "Alert",
  category: "Components",
  tags: [],
  components: ["BAAlert"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BAAlert`}</h1>
    <p><strong parentName="p">{`An alert displays a short, important message in a way that attracts the user's attention without interrupting the user's task.`}</strong></p>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`What is it?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`required`}</em>{` the message you'd like to alert the users to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The icon used depending on the type of message. `}<em parentName="td">{`defaultValue = `}<inlineCode parentName="em">{`"fa-exclamation-triangle"`}</inlineCode></em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`textColour`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`text colour variable for the message. This must be an existing colour variable. `}<em parentName="td">{`defaultValue = `}<inlineCode parentName="em">{`"--text-main"`}</inlineCode></em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`borderColour`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`border colour variable for the message. This must be an existing colour variable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Object`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`an optional action message which consists of `}<inlineCode parentName="td">{`url: *String*`}</inlineCode>{` and `}<inlineCode parentName="td">{`text: *String*`}</inlineCode>{` values.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`align`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`String`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a property to set the message align within the alert. `}<em parentName="td">{`defaultValue = `}<inlineCode parentName="em">{`"center"`}</inlineCode></em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`callback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Function`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a function to be executed when user clicks the action element (i.e to set state on the parent component outputing the error message).`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Here is are some examples:`}</p>
    <Example mdxType="Example">
  <BAAlert icon="fa-info-circle" message="Oops. There was an error, " textColour="--brand-3" action={{
        url: "https://tenor.com/view/wilson-contreras-slide-cubs-baseball-okay-gif-11592219",
        text: "take me to safety."
      }} borderColour="--hazard" align="left" callback={() => alert('callback')} mdxType="BAAlert" />
    </Example>
    <Example mdxType="Example">
  <BAAlert message="An alert with no optional props set" mdxType="BAAlert" />
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
