/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example  from '/styleguide/lib/example'
import ReactLargeTree from './react-large-tree.jsx'
import { dimensions, metrics, datasources } from '/styleguide/fixtures/things-tree.js'

export const meta = {
  name: "React Large Tree",
  category: "Components",
  tags: [],
  components: ["ReactLargeTree"],
  functions: [],
  values: {
    dimensions,
    metrics,
    datasources,
    dark: false,
    selectedDimensions: [],
    selectedDimension: null
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`React Large Tree`}</h1>
    <h2>{`A (hopefully) Simple Sortable Tree Component, for `}<em parentName="h2">{`Fairly Large Trees`}</em></h2>
    <p>{`This is an attempt to build a tree-component that can happily render up to thousands of nodes with drag and drop support for reorganisation. For simplicity, accessibility, and jank-avoidance, this component does not (yet) implement internal paging/virtualisation… so there will be a performance bottleneck above a certain number of nodes. We've tested it into the tens of thousands and it's okay. It breaks down with hundreds of thousands of nodes… `}</p>
    <h3>{`Usage`}</h3>
    <p>{`This component expects a `}<inlineCode parentName="p">{`content`}</inlineCode>{` prop, with a tree object, a bit like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`const content = {
  label: 'home',
  hasContextMenu: false,
  children: [
    { label: 'first child' },
    { label: 'second child' },
    { 
      label: 'third child',
      children: [
        { label: 'first sub-child' },
        { label: 'second sub-child' }
      ]
    }
  ]
}
`}</code></pre>
    <p>{`Children can be nested as deeply as you like. Each child should have some unique property. At some point I'll write an extension that can decorate your tree with unique props internally if you don't supply one, but for now, some unique property on each child pls!`}</p>
    <p>{`Children with an `}<inlineCode parentName="p">{`href`}</inlineCode>{` property will be rendered as a link.`}</p>
    <p>{`Children with an `}<inlineCode parentName="p">{`iconClass`}</inlineCode>{` property will be rendered with an `}<inlineCode parentName="p">{`<i class="[iconClass]"></i>`}</inlineCode>{` element.`}</p>
    <p>{`Children with `}<inlineCode parentName="p">{`hasContextMenu: false`}</inlineCode>{` will not show a context menu trigger, even if the broader tree is supplied with context menu scaffolding. This property defaults to true.`}</p>
    <p>{`DOM output is a flat structure, as this makes for speedier updates with very large lists of content. `}</p>
    <p>{`so the following simple use case would provide the following output:`}</p>
    <pre><code parentName="pre" {...{}}>{`import ReactLargeTree from \`react-large-tree\`


//your component etc.
//.
//.

//inside your render function:

const content = {
  label: 'home',
  children: [
    {
      label: 'page 1',
      href : '/page-1',
      iconClass: 'page'
    }
  ]
}

// in this case the labels are all unique, so we supply label as the uniqueKey
<ReactLargeTree content={content} uniqueKey="label" /> 

`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`<ol>
  <li>Home</li>
  <li><i class="page"></i><a href="/page-1">page 1</a></li>
</ol>
`}</code></pre>
    <h2>{`What else can it do?`}</h2>
    <p>{`React large tree supports:`}</p>
    <ul>
      <li parentName="ul">{`search`}</li>
      <li parentName="ul">{`an inline renaming workflow`}</li>
      <li parentName="ul">{`drag and drop reordering`}</li>
      <li parentName="ul">{`multiple selection drag and drop`}</li>
      <li parentName="ul">{`a contextual menu per node`}</li>
      <li parentName="ul">{`a fontawesome style icon per node`}</li>
      <li parentName="ul">{`drag locking / unlocking`}</li>
    </ul>
    <h2>{`Props`}</h2>
    <div className="doc-table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`key`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`type`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`required`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`default value`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`usage`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`content`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Node`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✔`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`a tree, as described above`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`uniqueKey`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`string`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✔`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`the key for a unique ID on every node`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`canDragChildInto`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`(Node, Node) => boolean`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`(a, b) => true`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`determine whether a drag option is legit.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`locked`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`false`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Lock the tree to prevent dragging. pro-tip: lock the tree during async operations, like saving the result of a move to a database.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`labelKey`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`string`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`"label"`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`the key to use for the label on each node.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`editingChild`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`string`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`render an input in place of the node matching this unique id`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`handleRename`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`(uid: string, newName: string) => void`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`use this to update a value following a rename. You'll probably want to clear the `}<inlineCode parentName="td">{`editChild`}</inlineCode>{` prop to remove the input at the same time`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`handleContextMenu`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`(arg0: Node, arg1: DOMRect) => void`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`DEPRECATED. get the node and position to render your own context menu next to the tree.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`contextMenuOptionsForNode`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`(node: Node, close: () => void, expand?: () => void) => MenuItem[]`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Let the tree manage its own context menus. provide a getter which takes a node, a "close context menu" function, and an "expand node" function (or null if the node is a leaf), returning a list of context menu options for whatever node is selected. You can use the `}<inlineCode parentName="td">{`close`}</inlineCode>{` function in your actions to close the context menu as part of your actions if you want.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`nodesToHighlight`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`string[]`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`[]`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`a list of node-uid values to highlight. use this to highlight the path to a location within the tree, highlight all nodes of a given type, or create exciting night-rider effects`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`multiDrag`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`false`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`enable dragging multiple items at a time. multi-drag only supports selecting multiple siblings, as dragging from multiple destinations at different points in the hierarchy is a tricky behaviour to define.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`childMoved`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`(arg0: MoveAction) => void`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`handle a drag and drop move action. See note on move action definitions.`}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>{`The MoveAction is an object describing a move.
If multi drag is off it looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`interface MoveAction {
 childId: string; // <-- id of moved child
 from: string;
 into: string;
 atIndex: number;
}
`}</code></pre>
    <p>{`if multi drag is on, it looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`interface MoveAction {
 childId: string[]; // <-- array of ids for moved children
 from: string;
 into: string;
 atIndex: number;
}
`}</code></pre>
    <p>{`Note that if multi-drag is enabled, moving a single child will still give you an
array, holding the id of the single moved item.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Basic Usage`}</p>
  <ReactLargeTree content={metrics} canDragChildInto={(childNode, parentNode) => parentNode.children != null && parentNode.id != "Metric_category-12"} nodesToHighlight={["Metric-10", "Metric_category-6", "Metric_category-5"]} editingChild={"Metric-10"} locked={false} labelKey="name" uniqueKey="id" handleContextMenu={console.log} contextMenuOptionsForNode={(node, close, expand) => {
      return [{
        type: "LINK",
        label: "a link",
        href: "#"
      }, {
        type: "ACTION",
        label: "a button",

        onClick() {
          alert("You pushed the button");
          close();
        }

      }, {
        type: "DIVIDER"
      }, {
        type: "ACTION",
        label: "another button",

        onClick() {
          alert("You pushed the other button");
          close();
        }

      }, {
        type: "ACTION",
        label: "A disabled button",

        onClick() {
          alert("You shouldn't be able to click that");
          close();
        },

        disabled: true
      }, {
        type: "ACTION",
        label: "Expand the node!",

        onClick() {
          expand();
          close();
        },

        disabled: !node.children
      }, {
        type: "LINK",
        label: "A disabled link",
        href: "#",
        disabled: true
      }, {
        type: "ACTION",
        label: "Custom highlight button",

        onClick() {
          alert("you clicked it!");
          close();
        },

        highlightBackground: "hazard"
      }, {
        type: "LINK",
        label: "Custom highlight link",
        href: "#",
        highlightBackground: "confirm"
      }];
    }} handleRename={console.log} multiDrag mdxType="ReactLargeTree" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
