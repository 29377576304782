import React from 'react'
import '../styles/baLogo.scss'

// The small one with the 4 v's
export const BALogo = ({size, monoColor}) => (
  <svg
    width            = {size || "30px"}
    height           = {size || "30px"}
    viewBox          = "0 0 44.1 46.3"
    enableBackground = "new 0 0 44.1 46.3"
  >
    <g>
      <path
        fill={monoColor || "#79B829"}
        d={`M29.7,40.3l-5.3-14.1c0-0.1-0.1-0.1-0.2-0.1h-3.2c-0.2,0-0.3,0.2-0.2,0.3l5.7,15.2c0.3,0.7,0.9,1.1,1.6,1.1 \
  C29.2,42.7,30.1,41.5,29.7,40.3`}
      />
      <path
        fill={monoColor || "#A1CD69"}
        d={`M23.6,26.1H20c-0.1,0-0.2,0.1-0.2,0.1l-4.1,10.9l-1.2,3.2c-0.4,1.1,0.4,2.4,1.6,2.4c0.7,0,1.4-0.4,1.6-1.1 \
  l1.7-4.4L23.6,26.1z`}
      />
      <path
        fill={monoColor || "#4283D1"}
        d={`M14.5,6l5.3,14.1c0,0.1,0.1,0.1,0.2,0.1h3.2c0.2,0,0.3-0.2,0.2-0.3L17.7,4.7c-0.3-0.7-0.9-1.1-1.6-1.1 \
  C14.9,3.6,14.1,4.8,14.5,6`}
      />
      <path
        fill={monoColor || "#7BA8DF"}
        d={`M20.6,20.2h3.6c0.1,0,0.2-0.1,0.2-0.1l4.1-10.9L29.7,6c0.4-1.1-0.4-2.4-1.6-2.4c-0.7,0-1.4,0.4-1.6,1.1 \
  l-1.7,4.4L20.6,20.2z`}
      />
      <path
        fill={monoColor || "#E9514F"}
        d={`M4.9,30.7L19,25.4c0.1,0,0.1-0.1,0.1-0.2V22c0-0.2-0.2-0.3-0.3-0.2L3.7,27.5c-0.7,0.3-1.1,0.9-1.1,1.6 \
  C2.5,30.3,3.8,31.2,4.9,30.7`}
      />
      <path
        fill={monoColor || "#F08584"}
        d={`M19.1,24.7v-3.6c0-0.1-0.1-0.2-0.1-0.2L8.1,16.8l-3.2-1.2c-1.1-0.4-2.4,0.4-2.4,1.6c0,0.7,0.4,1.4,1.1,1.6 \
  l4.4,1.7L19.1,24.7z`}
      />
      <path
        fill={monoColor || "#836D9C"}
        d={`M39.3,15.6l-14.1,5.3c-0.1,0-0.1,0.1-0.1,0.2v3.2c0,0.2,0.2,0.3,0.3,0.2l15.2-5.7c0.7-0.3,1.1-0.9,1.1-1.6 \
  C41.6,16,40.4,15.1,39.3,15.6`}
      />
      <path
        fill={monoColor || "#A899BA"}
        d={`M25,21.6v3.6c0,0.1,0.1,0.2,0.1,0.2L36,29.5l3.2,1.2c1.1,0.4,2.4-0.4,2.4-1.6c0-0.7-0.4-1.4-1.1-1.6 \
  L36,25.8L25,21.6z`}
      />
    </g>
  </svg>
)

export const BALogoFull = ({size, alt}) => {
  const altClass = alt ? "cls-13" : "cls-3"
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 225.58 39.38"
      width={size}
    >
      <defs>
        <clipPath id="clip-path" transform="translate(-.63 -.95)">
          <rect className="cls-1" x="0.63" y="0.95" width="225.58" height="39.38" />
        </clipPath>
      </defs>
      <g className="cls-2">
        <path
          className={ altClass }
          d="M58,21v4.6h3.76a2.15,2.15,0,0,0,2.1-2.29A2.21,2.21,0,0,0,61.7,21H58Zm3.2-2.58a1.82,1.82,0,0,0,1.78-2,1.75,1.75,0,0,0-1.88-1.93H58v3.92h3.2ZM64,19.52A4.1,4.1,0,0,1,67.1,23.4a4.88,4.88,0,0,1-5.15,5H55.16a0.23,0.23,0,0,1-.23-0.23V12a0.23,0.23,0,0,1,.23-0.23h6.28c2.91,0,4.77,1.73,4.77,4.5A3.28,3.28,0,0,1,64,19.52"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M81.9,28.38H79.44a0.23,0.23,0,0,1-.23-0.23V17.36a0.23,0.23,0,0,1,.23-0.23H81.9a0.23,0.23,0,0,1,.23.23V28.15a0.23,0.23,0,0,1-.23.23M80.68,11a1.77,1.77,0,1,1,0,3.54,1.77,1.77,0,1,1,0-3.54"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M90.36,19.71a3.06,3.06,0,0,0,0,6.12,3,3,0,0,0,3.06-3.08,2.92,2.92,0,0,0-3.06-3M90.07,31.32c2.84,0,3.59-2.12,3.42-4.48a4,4,0,0,1-3.59,1.78,5.64,5.64,0,0,1-5.63-5.83,5.66,5.66,0,0,1,5.63-5.9,4.09,4.09,0,0,1,3.59,1.76V17.36a0.23,0.23,0,0,1,.23-0.23h2.41a0.23,0.23,0,0,1,.23.23v9.41c0,5-2.38,7.22-6.28,7.22a5.89,5.89,0,0,1-5.26-3.11,0.23,0.23,0,0,1,.1-0.31l2-1a0.23,0.23,0,0,1,.3.1,3.44,3.44,0,0,0,2.89,1.65"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M101.91,28.38H99.42a0.23,0.23,0,0,1-.23-0.23V11.25A0.23,0.23,0,0,1,99.42,11h2.48a0.23,0.23,0,0,1,.23.23v7.62c0.53-1.37,2.38-2,3.49-2,2.91,0,4.53,1.93,4.53,5.23v6a0.23,0.23,0,0,1-.23.23h-2.48a0.23,0.23,0,0,1-.23-0.23V22.27a2.37,2.37,0,0,0-2.38-2.5,2.5,2.5,0,0,0-2.7,2.63v5.77a0.23,0.23,0,0,1-.23.23"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M117.19,17.14h2.16a0.23,0.23,0,0,1,.23.23v1.9a0.23,0.23,0,0,1-.23.23h-2.16v8.66a0.23,0.23,0,0,1-.23.23H114.5a0.23,0.23,0,0,1-.23-0.23V19.5h-1.82a0.23,0.23,0,0,1-.23-0.23v-1.9a0.23,0.23,0,0,1,.23-0.23h1.82V13.08a0.23,0.23,0,0,1,.23-0.23H117a0.23,0.23,0,0,1,.23.23v4.06Z"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M72,28.38H69.48a0.23,0.23,0,0,1-.23-0.23V17.36a0.23,0.23,0,0,1,.23-0.23H72a0.23,0.23,0,0,1,.23.23v2.42c0.58-2,1.9-2.89,3.18-2.89a4,4,0,0,1,1.7.33,0.22,0.22,0,0,1,.12.25l-0.42,2.21a0.23,0.23,0,0,1-.29.17A4.41,4.41,0,0,0,75,19.64c-2,0-2.82,1.78-2.82,4.77v3.74a0.23,0.23,0,0,1-.23.23"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M145.89,28.38H143.4a0.23,0.23,0,0,1-.23-0.23V17.36a0.23,0.23,0,0,1,.23-0.23h2.48a0.23,0.23,0,0,1,.23.23V18.8a3.84,3.84,0,0,1,3.35-1.9c2.62,0,4.19,1.66,4.19,4.84v6.42a0.23,0.23,0,0,1-.23.23h-2.48a0.23,0.23,0,0,1-.23-0.23V22.27c0-1.64-.87-2.5-2.14-2.5a2.29,2.29,0,0,0-2.46,2.63v5.77a0.23,0.23,0,0,1-.23.23"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M162.27,25.78a3,3,0,1,0,0-6.07,3,3,0,1,0,0,6.07m3.08,1.16a4.35,4.35,0,0,1-3.49,1.66,5.69,5.69,0,0,1-5.73-5.85,5.68,5.68,0,0,1,5.73-5.85,4.29,4.29,0,0,1,3.49,1.64V17.36a0.23,0.23,0,0,1,.23-0.23h2.48a0.23,0.23,0,0,1,.23.23V28.15a0.23,0.23,0,0,1-.23.23h-2.48a0.23,0.23,0,0,1-.23-0.23V26.94Z"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M171.84,11h2.48a0.23,0.23,0,0,1,.23.23V28.38h-2.94V11.25a0.23,0.23,0,0,1,.23-0.23"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M181.19,33.87h-2.62a0.23,0.23,0,0,1-.21-0.31l2.45-6-4.25-10.06a0.23,0.23,0,0,1,.21-0.32h2.63a0.23,0.23,0,0,1,.21.14l2.74,6.86,2.76-6.86a0.23,0.23,0,0,1,.21-0.14H188a0.23,0.23,0,0,1,.21.32L181.4,33.73a0.23,0.23,0,0,1-.21.14"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M196.67,17.14h-2.16V13.08a0.23,0.23,0,0,0-.23-0.23h-2.46a0.23,0.23,0,0,0-.23.23v4.06h-1.28a0.23,0.23,0,0,0-.21.14l-0.79,1.9a0.23,0.23,0,0,0,.21.32h2.07v8.89h2.91V19.5h2.16a0.23,0.23,0,0,0,.23-0.23v-1.9a0.23,0.23,0,0,0-.23-0.23"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M202,28.38h-2.91v-11a0.23,0.23,0,0,1,.23-0.23h2.46a0.23,0.23,0,0,1,.23.23v11ZM200.58,11a1.77,1.77,0,1,1,0,3.54,1.77,1.77,0,1,1,0-3.54"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M210.7,16.9a5.84,5.84,0,0,1,4.52,2,0.23,0.23,0,0,1,0,.33l-2,1.48a0.23,0.23,0,0,1-.31,0,2.92,2.92,0,0,0-2.2-.94,3,3,0,0,0-3.16,3,3.1,3.1,0,0,0,3.16,3.08,2.93,2.93,0,0,0,2.2-1,0.23,0.23,0,0,1,.31,0l2,1.46a0.23,0.23,0,0,1,0,.33,6,6,0,1,1-4.55-9.7"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ altClass }
          d="M217.42,20.24c0-1.81,1.9-3.35,4.14-3.35,2,0,4.15.9,4.37,3.29a0.23,0.23,0,0,1-.23.25h-2.34a0.23,0.23,0,0,1-.23-0.17,1.42,1.42,0,0,0-1.5-1,1.18,1.18,0,0,0-1.42,1.11,1.29,1.29,0,0,0,1.18.94l1.88,0.46c2.6,0.67,2.94,2.48,2.94,3.37,0,2.29-2.26,3.47-4.6,3.47-2.14,0-4.33-1.26-4.51-3.54a0.23,0.23,0,0,1,.23-0.24h2.3a0.23,0.23,0,0,1,.23.17,2,2,0,0,0,2,1.25,1.33,1.33,0,0,0,1.47-1.13c0-.58-0.41-0.87-1.23-1.06l-1.54-.39c-2.91-.79-3.18-2.12-3.18-3.44"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ alt ? "cls-4" : "cls-3" }
          d="M138.21,27.61l-5.9-15.81h3.1a0.23,0.23,0,0,1,.21.15l5.46,14.59a1.53,1.53,0,1,1-2.87,1.07"
          transform="translate(-.63 -.95)"
        />
        <path
          className={ alt ? "cls-13" : "cls-4" }
          d="M127.5,28.6h0a1.53,1.53,0,0,1-1.44-2.07l5.46-14.6a0.23,0.23,0,0,1,.21-0.15h3.1l-5.9,15.82a1.53,1.53,0,0,1-1.44,1"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-5"
          d="M27.52,38L22.24,23.75A0.22,0.22,0,0,0,22,23.61H18.82a0.22,0.22,0,0,0-.21.3L24.29,39.2a1.73,1.73,0,0,0,1.61,1.13A1.75,1.75,0,0,0,27.52,38"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-6"
          d="M21.18,23.61H18a0.22,0.22,0,0,0-.21.15l-4.07,11L12.48,38a1.75,1.75,0,0,0,1.61,2.37,1.73,1.73,0,0,0,1.61-1.13l1.66-4.47,4-10.82a0.22,0.22,0,0,0-.21-0.3"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-7"
          d="M12.48,3.32l5.27,14.21a0.22,0.22,0,0,0,.21.15h3.22a0.22,0.22,0,0,0,.21-0.3L15.71,2.08A1.73,1.73,0,0,0,14.1.95a1.75,1.75,0,0,0-1.61,2.37"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-8"
          d="M18.82,17.68H22a0.22,0.22,0,0,0,.21-0.14l4.08-11,1.2-3.23A1.75,1.75,0,0,0,25.9.95a1.73,1.73,0,0,0-1.61,1.13L22.63,6.55l-4,10.82a0.22,0.22,0,0,0,.21.3"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-9"
          d="M3,28.28l14-5.36a0.22,0.22,0,0,0,.14-0.21V19.44a0.22,0.22,0,0,0-.3-0.21L1.75,25A1.75,1.75,0,0,0,.63,26.64,1.73,1.73,0,0,0,3,28.28"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-10"
          d="M17.08,21.84V18.57a0.22,0.22,0,0,0-.14-0.21L6.14,14.22,3,13A1.73,1.73,0,0,0,.63,14.64h0a1.75,1.75,0,0,0,1.11,1.64L6.14,18l10.64,4.08a0.22,0.22,0,0,0,.3-0.21"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-11"
          d="M37,13l-14,5.36a0.22,0.22,0,0,0-.14.21v3.27a0.22,0.22,0,0,0,.3.21l15-5.77a1.75,1.75,0,0,0,1.12-1.64A1.73,1.73,0,0,0,37,13"
          transform="translate(-.63 -.95)"
        />
        <path
          className="cls-12"
          d="M22.92,19.44v3.27a0.22,0.22,0,0,0,.14.21l10.8,4.14L37,28.28a1.73,1.73,0,0,0,2.33-1.64A1.75,1.75,0,0,0,38.26,25l-4.4-1.69L23.22,19.23a0.22,0.22,0,0,0-.3.21"
          transform="translate(-.63 -.95)"
        />
      </g>
    </svg>
  )
}
