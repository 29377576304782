import React, {Component} from 'react'
import {BATooltip}        from './tooltip'

import styled from 'styled-components'

const NavBreadcrumbs = styled.div`
  
  i {
    color: var(--header-font-color);
    opacity: .65;
    margin-right: 5px;

    &.show-overlay-icon {
      font-size: var(--text-size-medium);
      line-height: inherit;
      color: var(--white);
      opacity:1;
      margin-right: 5px;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }
  
  .breadcrumb-overlay-hidden {
    display: none;
  }

  .breadcrumb-overlay-show {
    display: block;
    position: fixed;
    background-color: var(--header-color);
    opacity: .9;
    max-width: 200px;
    padding: 0 0.5rem;
    overflow: hidden;
    border-radius: 0 5px 5px 5px;
  }

  .breadcrumb-container {
    display: flex;
    align-items: center;
  }

  a.crumb, span.crumb {
    display: inline-block;
    color: inherit;
    font-size: var(--text-size-small);
    opacity: .8;
  }

  li a.crumb, li span.crumb {
    opacity: 1;
  }

  .breadcrumbs {
    display: inline-block;
    max-width: 800px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: ltr;
        padding-left: 6px;

    a.crumb, span.crumb {

      &::after {
        content: "/";
        display: inline-block;
        margin: 0 7px 0 0;
        color: var(--text-light);
      }

      &:last-child {
        color: var(--header-font-color);
        opacity: .8;
        &:after {
          content: none;
        }
      }
    }
  }
`

const Breadcrumb = ({ crumb, popover, shortened }) => {

  return <>
  { 
    crumb.link ? 
      <a
        href      = {crumb.link || "#"}
        className = {crumb.current ? "current crumb" : 'crumb'}
        key       = {popover ? `popoverLink-${ crumb.label }` : `breadcrumb-${ crumb.label }`}
      >
        {crumb.icon ? <i className={`${crumb.icon}`} /> : null}
        { shortened ? crumb.label.split("").slice(0, 16).join("") + "..." : crumb.label}&lrm; &lrm;
      </a> :
      <span className="crumb">
        {crumb.icon ? <i className={`${crumb.icon}`} /> : null}
        {shortened ? crumb.label.split("").slice(0, 16).join("") + '...' : crumb.label }&lrm; &lrm;
      </span>
        
        
  }
  </>
}

export default class Breadcrumbs extends Component {

  constructor() {
    super()

    this.timeout = null
    this.state   = { showOverlay: false }
  }

  renderBreadcrumbOverlay() {
    // dropdown - appears when breadcrumbs are truncated
    const popoverCrumbs = this.props.breadcrumbs.map(bc => bc)
    popoverCrumbs.reverse()

    return (
      <div
        className     = {`breadcrumb-overlay-${this.state.showOverlay ? "show" : "hidden"}`}
        id            = "breadcrumb-overlay"
        onMouseEnter  = {() => {
          clearTimeout(this.timeout)
          this.setState({ showOverlay: true })
        }}
        onMouseLeave  = {() => this.hideOverlay()}
      >
        <ul className = "list-style-none">
          {popoverCrumbs.map((breadcrumb, i) => (
            <li key = {`popoverCrumb-${i}`}>
              {this.renderTruncatedBreadcrumb(breadcrumb, i, true)}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderTruncatedBreadcrumb(breadcrumb, i, popover) {

    const shortened = breadcrumb.label ? breadcrumb.label.length > 20 : null

    return shortened ? <BATooltip
        key       = {`breadcrumb-${i}-tooltip`}
        content   = {breadcrumb.label}
        placement = "bottom"
      >
        <Breadcrumb crumb={ breadcrumb} popover={ popover } shortened={ shortened } /> 
      </BATooltip> :
      <Breadcrumb crumb={ breadcrumb} popover={ popover } shortened={ false } />
    
  }

  hideOverlay() {
    this.timeout = setTimeout(() => {
      this.setState({ showOverlay: false })
    }, 1500)
  }

  showOverlay() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({ showOverlay: true })
    }, 250)
  }

  componentWillUnmount() {
    // clear the modal timeout before the component unmounts
    // otherwise you can hit a no-op error trying to set state on an unmounted component
    clearTimeout(this.timeout)
  }

  render() {
    const {breadcrumbs} = this.props
    
    return (
      <NavBreadcrumbs>
        <span className="breadcrumb-container">
          {breadcrumbs.length > 1 &&
            <i
              className     = "show-overlay-icon fa fa-caret-down"
              id            = "breadcrumb-overlay-icon"
              onMouseEnter  = {() => this.showOverlay()}
              onMouseLeave  = {() => this.hideOverlay()}
            />}
          <span className="breadcrumbs"> 
            { breadcrumbs.map((breadcrumb) => <Breadcrumb key={ breadcrumb.label } crumb={ breadcrumb} popover={false } shortened={ false } />) }
          </span>
        </span>
        {this.renderBreadcrumbOverlay()}
      </NavBreadcrumbs>
    )
  }
}
