import React, {Component}      from 'react'
import reactElementToJSXString from 'react-element-to-jsx-string'
import SyntaxHighlighter       from 'react-syntax-highlighter/prism'
import {tomorrow}              from 'react-syntax-highlighter/styles/prism'
import {BAButton}              from '/lib/components/buttons'

const stripMDXBullshit = (child) => {
  if (!child) return child // handle null and undefined
  if (typeof child == "string") return child // handle string child
  if (typeof child == "number") return child // handle numbers
  let safeChild = {...child}
  if (typeof safeChild.type == "object" && safeChild.type.displayName == "MDXCreateElement") {
    const displayName = typeof safeChild.props.originalType == "string"
      ? safeChild.props.originalType
      : safeChild.props.originalType.displayName || safeChild.props.originalType.name
    
    safeChild.type = {
      ...safeChild.type,
      displayName 
    }
    safeChild.props = {
      ...safeChild.props
    }    
    delete safeChild.props.originalType
    delete safeChild.props.mdxType
  }
  if (Array.isArray(safeChild.props.children)) {
    safeChild.props.children = safeChild.props.children.map(stripMDXBullshit)
  } else if (safeChild.props.children) {
    safeChild.props.children = stripMDXBullshit(safeChild.props.children)
  }
  return safeChild
}

const stringOfChildren = children => {
  if (!Array.isArray(children)) children = [children]
    
  return children.reduce(
  (result, child) => {

      // reactElementToJSXString doesn't play nicely with MDX, which pulls some
      // funny business with element props internally. 
      //
      // stripMDXBullshit takes a clone of the react render tree, and walks down
      // removing weird MDX specific bits and pieces.
      const safeChild = stripMDXBullshit(child)
      
      result += reactElementToJSXString(safeChild)
      result += "\n"
      return result
    },
  ""
  )
}

class Example extends Component {
  constructor (props) {
    super(props)
    this.state = {viewSource : false}
  }

  render () {
    if (this.props.twoColumn) {
      // render the example and the code highlighted
      return (
        <div className="grid-container g1-1">
          <div className={'example ' + (this.props.className || "")}>
            {this.props.children}
          </div>
          <SyntaxHighlighter
            className='syntax'
            language='javascript'
            style={tomorrow}
          >
            {stringOfChildren(this.props.children || [])}
          </SyntaxHighlighter>
        </div>
      )
    } else {
      // render just the example and a 'View Source' btn
      const toggleViewSource = () => this.setState({viewSource : !this.state.viewSource})

      const toggleViewSourceButton = (
        <BAButton
          size      = "small"
          solid     = {this.state.viewSource}
          onClick   = {toggleViewSource}
          className = "float-right"
        >
          {this.state.viewSource
            ? "hide source"
            : "show source"}
        </BAButton>
      )

      return (
        <div className="example-section">
          {toggleViewSourceButton}
          <div className={`${this.props.className || ""}`} style={{clear: "both"}}>
          {this.props.children}
          </div>
          {this.state.viewSource ? (
            <SyntaxHighlighter
              language='javascript'
              style={tomorrow}
            >
              {stringOfChildren(this.props.children)}
            </SyntaxHighlighter>
          ) : null}
        </div>
      )
    }
  }
}

export default Example
