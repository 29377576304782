/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import {BALogo, BALogoFull} from '../../lib/components/baLogo'
import Example from '../lib/example.jsx'

export const meta = {
  name: "BALogo",
  category: "Components",
  tags: [],
  components: ["BALogo", "BALogoFull"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BALogo - a component that renders the company logo.`}</h1>
    <p>{`Takes a `}<inlineCode parentName="p">{`size`}</inlineCode>{` prop (px). defaults to 30.`}</p>
    <Example twoColumn mdxType="Example">
  <BALogo size={100} mdxType="BALogo" />
  <BALogo size={75} mdxType="BALogo" />
  <BALogo size={30} mdxType="BALogo" />
    </Example>
    <h2>{`BALogoFull`}</h2>
    <Example twoColumn mdxType="Example">
  <BALogoFull size="66%" mdxType="BALogoFull" />
    </Example>
    <p>{`When using the logo on a dark background e.g. `}<inlineCode parentName="p">{`var(--brand-1)`}</inlineCode>{`, you can specify an `}<inlineCode parentName="p">{`alt`}</inlineCode>{` parameter to use a white font.`}</p>
    <Example twoColumn mdxType="Example">
  <div style={{
        background: "var(--brand-1)",
        padding: "1rem"
      }}>
    <BALogoFull size="66%" alt mdxType="BALogoFull" />
  </div>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
