import styled from "styled-components";
import React, { Component, Fragment } from "react";
import { toReactSelectOption } from "./selects";

import {
  generateId,
  getFontSize,
  geMinHeight,
  getMinWidth
} from "../_shared/vars";

export const ToggleInput = styled.div`
  display    : ${({ block }) => (block ? "block" : "inline-block")};
  clear      : both;
  position   : relative;
  ${geMinHeight}
  ${getMinWidth}
  ${getFontSize}
  --line-height  : calc(var(--form-element-line-height) + 0.4rem);
  --scale-factor : 1.5em;
  --top : calc( ( var(--line-height) - var(--scale-factor) ) / 3 );

  min-height : var(--line-height);

  label {
    margin-left  : calc(var(--scale-factor) * 1.2);
    margin-right : var(--scale-factor);
    margin-bottom: 0;

    cursor       : pointer;
    font-size    : 1em;
    line-height  : var(--line-height);
    &:focus {
      outline: none;
      &::before {
        box-shadow: 0 0 3px 0 var(--text-main);
      }
    }
  }

  input {
    position  : absolute;
    cursor    : pointer;
    left      : 0;
    top       : var(--top);
    width     : var(--scale-factor);
    height    : var(--scale-factor);
    z-index   : 1;
    margin    : 0;
    zoom      : 1;
    filter    : alpha(opacity=0);
    opacity   : 0;
  }

  input[type=radio] + label::before {
    content               : "";
    border                : 2px solid;
    background            : transparent;
    width                 : var(--scale-factor);
    height                : var(--scale-factor);
    position              : absolute;
    box-sizing            : border-box;
    top                   : var(--top);
    transform             : translate3d(0,0,0);
    left                  : 0;
    -webkit-border-radius : 50%;
    -moz-border-radius    : 50%;
    border-radius         : 50%;
    transition: all 0.2s ease-out;
  }


  input[type=checkbox] + label::before {
    background   : transparent;
    border       : 2px solid;
    content      : "";
    height       : var(--scale-factor);
    width        : var(--scale-factor);
    left         : 0;
    margin       : 0;
    padding      : 0;
    position     : absolute;
    top          : var(--top);
    box-sizing   : border-box;
    transform    : translate3d(0,0,0);
  }

  input[type=checkbox] + label::after {
    box-sizing        : border-box;
    content           : "";
    border            : solid;
    border-width      : 0 0 0.2em 0.2em;
    background        : transparent;
    border-top-color  : transparent;
    width             : calc(var(--scale-factor) / 3);
    height            : calc(var(--scale-factor) / 5);
    position          : absolute;
    top               : calc(var(--line-height) / 3);
    left              : calc(var(--scale-factor) / 3);
    -moz-transform    : rotate(-45deg);
    -o-transform      : rotate(-45deg);
    -webkit-transform : rotate(-45deg);
    -ms-transform     : rotate(-45deg);
    transform         : rotate(-45deg);
    zoom              : 1;
    filter            : alpha(opacity=0);
    opacity           : 0;
  }

  input:checked + label::after {
    zoom    : 1;
    filter  : alpha(opacity=100);
    opacity : 1;
  }

  input[type=radio]:checked + label::before {
    --border-pos: calc(var(--scale-factor) / 6);
    --negative-border-pos: calc(0px - var(--border-pos));
    background: var(--text-main);
    box-shadow:
    inset var(--border-pos)   var(--border-pos) 0px var(--content-bg),
    inset var(--negative-border-pos) var(--negative-border-pos) 0px var(--content-bg),
    inset var(--negative-border-pos)  var(--border-pos) 0px var(--content-bg),
    inset var(--border-pos)  var(--negative-border-pos) 0px var(--content-bg),
    inset var(--border-pos)  0 0px var(--content-bg),
    inset var(--negative-border-pos) 0 0px var(--content-bg),
    inset 0  var(--border-pos) 0px var(--content-bg),
    inset 0 var(--negative-border-pos) 0px var(--content-bg);
  }

  input:disabled + label {
    zoom    : 1;
    filter  : alpha(opacity=50);
    opacity : 0.5;
    cursor  : default;
  }
`;

class BARadio extends Component {

  displayName = "BARadio"

  constructor(props) {
    super(props);
    this.id = props.id || generateId();
  }
  render() {
    const {
      id,
      label,
      value,
      name,
      checked,
      disabled = false,
      inline = true,
      onChange,
      size,
      minw = null
    } = this.props;

    const _id = id || this.id;

    const extraProps = {};
    if (this.props.className) extraProps.className = this.props.className;

    return (
      <ToggleInput size={size} minw={minw} block={!inline} {...extraProps}>
        <input
          tabIndex={0}
          id={_id}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        {<label htmlFor={_id}>{label || ""}</label>}
      </ToggleInput>
    );
  }
}

const BARadioGroup = ({
  options,
  onChange,
  value,
  name,
  inline = true,
  disabled = false,
  size,
  minw = null
}) => {
  let hasErrors = false;
  if (!name)
    console.warn("BARadioGroup needs a `name` prop"), (hasErrors = true);
  if (!options)
    console.warn("BARadios needs an array of options"), (hasErrors = true);
  if (hasErrors) return null;

  const _options = options.map(toReactSelectOption);

  return (
    <Fragment>
      {_options.map(opt => (
        <BARadio
          id={name + "_" + opt.value.replace(" ", "_")}
          inline={inline}
          key={name + "_" + opt.value.replace(" ", "_")}
          label={opt.label}
          disabled={resolveDisabledState(opt)}
          name={name}
          onChange={onChange}
          value={opt.value}
          checked={value == opt.value}
          size={size}
          minw={minw}
        />
      ))}
    </Fragment>
  );
};

function resolveDisabledState(opt) {
  if (!opt.hasOwnProperty("disabled")) return false
  if (typeof opt.disabled === "function") return opt.disabled(opt.value)
  return opt.disabled
}

BARadioGroup.displayName = "BARadioGroup"

export { BARadio, BARadioGroup };
