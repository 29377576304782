/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from "../lib/example.jsx";
import Grid from "/lib/components/Grid/Grid";
import {
  InputWithDescription,
  InputWithValidation,
  TextAreaWithDescription,
  RadioGroupWithDescription,
  SelectWithDescription,
  CheckboxWithDescription,
  GroupWithDescription,
} from './Form-helpers'

export const meta = {
  name: "Forms",
  category: "Deprecated Components",
  tags: [],
  components: ["Form", "Input", "TextArea", "Group", "Select", "Checkbox", "RadioGroup"],
  functions: ["withDescription"]
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Form = makeShortcode("Form");
const Checkbox = makeShortcode("Checkbox");
const Group = makeShortcode("Group");
const RadioGroup = makeShortcode("RadioGroup");
const Select = makeShortcode("Select");
const Input = makeShortcode("Input");
const TextArea = makeShortcode("TextArea");
const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* NOTE: See Form.test.tsx to see usage/ how to use this with hooks */
    }
    <Example mdxType="Example">
      <div>
  <h3>See Form.test.tsx to see usage/ how to use this with hooks</h3>
      </div>
      <div style={{
        background: 'white',
        padding: 10
      }}>
  <Form mdxType="Form">
      <InputWithValidation validation={value => ({
            isValid: typeof value === 'string' && value.length > 3,
            onErrorMessage: 'Value must be a string that is longer than 3 characters'
          })} label="With Validation" value={props.get("input_with_validation")} handleChange={value => props.set("input_with_validation", value)} mdxType="InputWithValidation" />
    <Grid gridGap="1rem" mdxType="Grid">
      <GroupWithDescription noMarginBottom descriptionText="This is a group of checkboxes" label="Hello Checkboxes" mdxType="GroupWithDescription">
        <Checkbox handleChange={checked => props.set("check-box-1", checked)} checked={props.get("check-box-1")} label="Hello, World! Checkbox" mdxType="Checkbox" />
        <Checkbox handleChange={checked => props.set("check-box-2", checked)} checked={props.get("check-box-2")} label="Hello, World! Checkbox 2" mdxType="Checkbox" />
      </GroupWithDescription>
    </Grid>
    <Group mdxType="Group">
      <RadioGroupWithDescription direction="column" label="Roles" checkedValue={props.get("rank2")} handleChange={value => props.set("rank2", value)} options={["Junior", "Senior", "Legendary", "Incandescent"]} descriptionText="A radio group used for toggling radio buttons" mdxType="RadioGroupWithDescription" />
      <RadioGroup label="Roles" checkedValue={props.get("rank3")} handleChange={value => props.set("rank3", value)} options={["Junior", "Senior", "Legendary", "Incandescent"]} mdxType="RadioGroup" />
      <SelectWithDescription label="Hello, world!" value={props.get("appelation")} handleChange={value => props.set("appelation", value)} options={Array.from(Array(100000).keys())} descriptionText="A select used for selecting things" mdxType="SelectWithDescription" />
      <InputWithDescription label="Name" placeholder="Name" value={props.get("name")} handleChange={value => props.set("name", value)} descriptionText="An input used for inputting things" mdxType="InputWithDescription" />
      <TextAreaWithDescription label="Personality" placeholder="Personality" value={props.get("personality")} handleChange={value => props.set("personality", value)} descriptionText="An text area" mdxType="TextAreaWithDescription" />
    </Group>
    <Group direction="row" className="hello" mdxType="Group">
      <Select label="Hello, world!" value={props.get("appelation_2")} handleChange={value => props.set("appelation_2", value)} options={["Dr.", "Ms.", "Professor", "Viscount"]} mdxType="Select" />
      <Input label="Name" placeholder="Name" value={props.get("name_2")} handleChange={value => props.set("name_2", value)} mdxType="Input" />
      <TextArea label="Personality" placeholder="Personality" value={props.get("personality_1")} handleChange={value => props.set("personality_1", value)} mdxType="TextArea" />
    </Group>
    <Group mdxType="Group">
      <InputWithDescription label="Age" placeholder="Age" value={props.get("age")} handleChange={value => props.set("age", value)} descriptionText="An input used for inputting things" mdxType="InputWithDescription" />
      <TextArea label="Personality" placeholder="Personality" value={props.get("personality_2")} handleChange={value => props.set("personality_2", value)} mdxType="TextArea" />
    </Group>
  </Form>
      </div>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
