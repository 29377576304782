/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from "/styleguide/lib/example";
import Accordion from "./Accordion";

export const meta = {
  name: "Accordion",
  category: "Components",
  tags: [],
  components: ["Accordion"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Accordion`}</h1>
    <p>{`Simple wrapper, takes a panel property and it will build an accordion where the passed panels are toggled with an animated accordion `}</p>
    <p>{`To drive the active / unactive icon, accepts an iconType prop `}{`['ba' | 'svg']`}{` giving choice of svg or font awesome toggle icon (defaults to 'ba')`}</p>
    <p>{`To have a panel open by default, use prop 'defaultActiveIndex' and pass the index of the panel to be defaulted to open`}</p>
    <h2>{`Example using default icon`}</h2>
    <Example mdxType="Example">
  <Accordion panels={[{
        header: <h2>Hello</h2>,
        content: <h2>World</h2>
      }, {
        header: <h2>Howdy</h2>,
        content: <h2>Partner</h2>
      }]} mdxType="Accordion" />
    </Example>
    <h2>{`Example using svg icon`}</h2>
    <Example mdxType="Example">
  <Accordion iconType='svg' panels={[{
        header: <h2>Hello</h2>,
        content: <h2>World</h2>
      }, {
        header: <h2>Howdy</h2>,
        content: <h2>Partner</h2>
      }]} mdxType="Accordion" />
    </Example>
    <h2>{`Example with default open panel`}</h2>
    <Example mdxType="Example">
  <Accordion defaultActiveIndex={1} panels={[{
        header: <h2>Hello</h2>,
        content: <h2>World</h2>
      }, {
        header: <h2>Howdy</h2>,
        content: <h2>Partner</h2>
      }]} mdxType="Accordion" />
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
