// this function can be added directly to styled components css.
export const disabledStyles = ({ disabled }) =>
  disabled ? "opacity: 0.5; pointer-events: none;" : "";

export const formMinWidth = ({ minWidth }) => {
  if (!minWidth) return "";
  switch (typeof minWidth) {
    case "number":
      return `min-width: ${minWidth}px;`;
    case "boolean":
      return "min-width: 170px;";
    default:
      return "";
  }
};

export const formMinHeight = ({ minHeight }) => {
  if (!minHeight) return "";
  switch (typeof minHeight) {
    case "number":
      return `min-height: ${minHeight}px;`;
    case "boolean":
      return "min-height: 170px;";
    default:
      return "";
  }
};

// function that looks at props for size prop and adds a font-size property, defaulting to small.
export const getFontSize = ({ size }) =>
  size
    ? `font-size: var(--text-size-${size});`
    : `font-size: var(--form-element-size);`;

export const geMinHeight = ({ size }) => {
  if (size === "micro") return `min-height : 1.5rem;`;
  return `min-height : 2.5rem;`;
};

export const getMinWidth = ({ minw }) => {
  if (minw) {
    return `min-width : ${minw + "px"}`;
  }
  return ``;
};

export const generateId = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
