// Entry point for the component lib. Put everything here to be exported.

// NOTE: This is useful and will probably want to be put in at some point
// It sets css all to a common across all browsers standard.
// However it was breaking button spacing in the bright-ux app so commented it out for now.
// import "normalize.css/normalize.css";
import "./styles/style.scss";
import "tippy.js/dist/tippy.css";

import * as FormComponents from "./components/deprecated_Form/index";

import withValidation from "./components/HOC/withValidation/withValidation";

import Icon from "./components/Icon/Icon";
import IconButton from "./components/IconButton/IconButton";
import Grid from "./components/Grid/Grid";
import GridItem from "./components/Grid/Subcomponents/GridItem";
import PortalClose from "./components/PortalClose/PortalClose";
import {
  BAActionLink,
  BAButton,
  BATabButton,
  BAButtonsTab,
  BAFileInput,
} from "./components/buttons";
import SegmentControl from "./components/segment-control";
import { BAPortal, withBAPortal, PositionedPortal } from "./components/portal";
import { useComputedPosition } from "./components/hooks/useComputedPosition";
import BADropdown from "./components/dropdown";
import BAPopperMenu from "./components/PopperMenu/PopperMenu";
import BAPopperIconMenu from "./components/PopperMenu/PopperIconMenu";
import withRootClose from "./components/rootClose";
import {
  BAModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
} from "./components/modal";
import Select, {
  Creatable,
  toReactSelectOption,
} from "./components/forms/selects";
import { FlexContainer } from "./components/blocks";
import {
  InputGroup,
  InputGroupWithErrorMessage,
} from "./components/forms/InputGroup";
import { BARadio, BARadioGroup } from "./components/forms/radios";
import { BACheck } from "./components/forms/checkboxes";
import { ModalProvider, ModalRoot, ModalConsumer } from "./components/flash";
import Page from "./components/page";
import { BATooltip, BAPopper } from "./components/tooltip";
import {
  BAThingPicker,
  BAFloatingThingPalette,
  BAThingPalette,
  BAThingPickerButton,
  BAPalette,
  BAFloatingPalette,
} from "./components/thing-picker/thing-picker";
import { BAGrid, BACell } from "./components/grid";
import { IterableTree } from "./util/iterable-tree";

import { BALogo, BALogoFull } from "./components/baLogo";
import Breadcrumbs from "./components/breadcrumbs";
import StyledNav from "./components/header";
import LoadSpinner from "./components/LoadSpinner/LoadSpinner";

import withLoadingDisplay, {
  withConfiguredLoadingDisplay,
} from "./components/HOC/withLoadingDisplay/withLoadingDisplay";

import ReactLargeTree from "./components/react-large-tree/react-large-tree";
import Accordion from "./components/Accordion/Accordion";

import MessageDispatcher from "./util/MessageDispatcher.js";
import NotificationDisplay from "./components/Notifications/NotificationDisplay.jsx";

import { TabRegion } from "./components/tabbed-ui/TabbedRegion";
import { Tab } from "./components/tabbed-ui/Tab";
import Sparkline from "./components/Sparkline/Sparkline";
import {
  BACard,
  StyledCard,
  CardDescriptor,
  CardStatistic,
  CardButtons,
} from "./components/card.jsx";
import withConfiguredErrorBoundary, {
  withErrorBoundary,
} from "./components/HOC/withErrorBoundary/withConfiguredErrorBoundary";

import useRootClose from "./components/hooks/useRootClose";
import BAPagination from "./components/Pagination/Pagination";
import BATabularData from "./components/TabularData/BATabularData";
import BAAlert from "./components/Alert/baalert";

export * from "./components/color-picker";

export {
  PortalClose,
  withValidation,
  Grid,
  GridItem,
  FormComponents,
  Accordion,
  Icon,
  IconButton,
  BAActionLink,
  BAButton,
  BATabButton,
  BAButtonsTab,
  BAFileInput,
  SegmentControl,
  Select,
  Creatable,
  toReactSelectOption,
  BAPortal,
  withBAPortal,
  PositionedPortal,
  useComputedPosition,
  BADropdown,
  BAPopperMenu,
  BAPopperIconMenu,
  withRootClose,
  BAModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  ModalProvider,
  ModalRoot,
  ModalConsumer,
  MessageDispatcher,
  NotificationDisplay,
  BAThingPicker,
  BAFloatingThingPalette,
  BAThingPalette,
  BAThingPickerButton,
  BAPalette,
  BAFloatingPalette,
  ReactLargeTree,
  FlexContainer,
  InputGroup,
  InputGroupWithErrorMessage,
  IterableTree,
  BATooltip,
  BAPopper,
  BARadio,
  BACheck,
  BARadioGroup,
  Page,
  BAGrid,
  BACell,
  BALogo,
  BALogoFull,
  Breadcrumbs,
  StyledNav,
  LoadSpinner,
  TabRegion,
  Tab,
  Sparkline,
  BACard,
  StyledCard,
  CardDescriptor,
  CardStatistic,
  CardButtons,
  withLoadingDisplay,
  withConfiguredLoadingDisplay,
  withConfiguredErrorBoundary,
  withErrorBoundary,
  useRootClose,
  BAPagination,
  BATabularData,
  BAAlert,
};
