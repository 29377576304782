/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example           from '../lib/example'
import {ModalProvider,
        ModalRoot,
        ModalConsumer,
        addExampleFlash} from '/lib/components/flash'
import {BAButton}        from '/lib/components/buttons'

export const meta = {
  name: "Flash",
  category: "Deprecated Components",
  tags: [],
  components: ["ModalProvider", "ModalRoot", "ModalConsumer"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Flashes `}<br /><small>{`Ephemeral, self-deleting messages that appear in the top right of the screen.`}</small></h1>
    <p>{`⚠️ SUPERSEDED by the `}<inlineCode parentName="p">{`<NotificationDisplay />`}</inlineCode>{` component ⚠️`}</p>
    <p>{`For delivering messages to the user that don't need to interrupt their workflow.`}</p>
    <Example mdxType="Example">
  <ModalProvider mdxType="ModalProvider">
    <ModalRoot mdxType="ModalRoot" />
    <ModalConsumer mdxType="ModalConsumer">
      {({
            addFlash,
            flashes
          }) => <BAButton onClick={() => addExampleFlash(addFlash, flashes)} mdxType="BAButton">
          Flash me!
        </BAButton>}
    </ModalConsumer>
  </ModalProvider>
    </Example>
    <h2>{`Quickly how it works`}</h2>
    <p>{`This feature is implemented using the Context API from React 16.`}</p>
    <p>{`This allows us to define a value that can be made available throughout the ReactDOM hierarchy...
Without having to pass it down as a prop.`}</p>
    <p>{`(it can be anything, including an object full of functions and data).`}</p>
    <p>{`We have defined a piece of React Context like so:`}</p>
    <pre><code parentName="pre" {...{}}>{`const ModalContext = createContext({
  flashes        : [],
  addFlash       : () => {},
  hideFlash      : () => {}
})
`}</code></pre>
    <p>{`To make it available to components anywhere in the app, we've created a `}<inlineCode parentName="p">{`Provider`}</inlineCode>{` component:`}</p>
    <pre><code parentName="pre" {...{}}>{`<ModalContext.Provider value={} />
`}</code></pre>
    <p>{`This value is what gets passed a`}</p>
    <p>{`And stuck it in a React Component class that can manage the `}<inlineCode parentName="p">{`Provider`}</inlineCode>{`'s value prop in its own state.`}</p>
    <pre><code parentName="pre" {...{}}>{`class ModalProvider extends Component {

  state = {
    flashes        : [],
    addFlash       : () => {},
    hideFlash      : () => {}
  }

  // ... state changing methods ...

  render() {
    return (
      <ModalContext.Provider value={this.state} />
    )
  }
}
`}</code></pre>
    <h2>{`Modal Root`}</h2>
    <p>{`Whatever's in the `}<inlineCode parentName="p">{`ModalProvider`}</inlineCode>{`'s' `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop will now be available to any `}<inlineCode parentName="p">{`ModalContext.Consumer`}</inlineCode>{` component and anything nested underneath it.`}</p>
    <p>{`So we can create a `}<inlineCode parentName="p">{`ModalRoot`}</inlineCode>{` component that does our rendering like so:`}</p>
    <pre><code parentName="pre" {...{}}>{`const ModalConsumer = ModalContext.Consumer

const ModalRoot = () => (
  <ModalConsumer>
    {({ flashes, hideFlash }) => (
      <BAFlashContainer
        flashes   = {flashes}
        hideFlash = {id => hideFlash(id)}
      />
    )}
  </ModalConsumer>
)

`}</code></pre>
    <p><em parentName="p">{`REMEMBER: `}<inlineCode parentName="em">{`ModalProvider`}</inlineCode>{` must be at a higher level in the ReactDOM for a `}<inlineCode parentName="em">{`ModalConsumer`}</inlineCode>{` to have access to it.`}</em></p>
    <p>{`A good place for the `}<inlineCode parentName="p">{`ModalProvider`}</inlineCode>{` therefore is at the root level.`}</p>
    <p>{`At some point we will refactor the dashboard app to use a single root `}<inlineCode parentName="p">{`<App />`}</inlineCode>{` component.
This will mean we only need one `}<inlineCode parentName="p">{`<ModalProvider />`}</inlineCode>{` and one `}<inlineCode parentName="p">{`<ModalRoot />`}</inlineCode>{`.
At the moment though, it will need to be added to every path's root level component to be available to its children.`}</p>
    <pre><code parentName="pre" {...{}}>{`render() {
  <div className="folder-page">
    <ModalProvider>
      <ModalRoot />

    { /* Rest of Folder page */ }

    </ModalProvider
  </div>
}

`}</code></pre>
    <h2>{`How to create a Flash Trigger and call `}<inlineCode parentName="h2">{`addFlash`}</inlineCode></h2>
    <p><inlineCode parentName="p">{`ModalProvider`}</inlineCode>{` contains a `}<inlineCode parentName="p">{`addFlash`}</inlineCode>{` function in its `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop.`}</p>
    <p>{`This means that `}<inlineCode parentName="p">{`ModalConsumer`}</inlineCode>{` can also be used to trigger flashes.`}</p>
    <p>{`We have set the `}<inlineCode parentName="p">{`addFlash`}</inlineCode>{` method to take an object with 4 properties:`}</p>
    <ol>
      <li parentName="ol">{`message (text to appear on the flash)`}</li>
      <li parentName="ol">{`color`}</li>
      <li parentName="ol">{`iconClass (e.g. 'far fa-skull')`}</li>
      <li parentName="ol">{`delay (milliseconds until self-destruction)`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`
return (
  <ModalConsumer>
    {({ addFlash }) => (
      <BAButton
        onClick={() => addFlash({
          message   : 'You clicked me!',
          color     : var(--highlight),
          iconClass : 'fa fa-times',
          delay     : 3000,
        })}
      >
        Trigger Modal
      </BAButton>
    )}
  </ModalConsumer>
)
`}</code></pre>
    <p>{`There you have it:`}</p>
    <ol>
      <li parentName="ol">{`Add the `}<inlineCode parentName="li">{`<ModalProvider>`}</inlineCode>{` and `}<inlineCode parentName="li">{`<ModalRoot />`}</inlineCode>{` wrappers somewhere higher up the ReactDOM tree.
(They may already be there in which case there's no need.)`}</li>
      <li parentName="ol">{`Wrap your trigger with a `}<inlineCode parentName="li">{`<ModalConsumer>`}</inlineCode>{` and call `}<inlineCode parentName="li">{`addFlash`}</inlineCode>{` as explained just above.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
