import React, { Component } from "react";
import styled, { css } from "styled-components";
import { disabledStyles } from "./_shared/vars.js";

// component definition ---------------------------------------------------

const getStyles = ({ color, solid, bare }) => {
  if (!color) color = "text-main"; // default

  const styles = {
    normal: `var(--${color})`,
    hover: `var(--${color}-dark)`,
    active: `var(--${color}-darker)`
  };

  styles["txt"] = solid ? "white" : `var(--${color}-darker)`
  styles["hoverTxt"] =  "white";
  
  styles["bg"] = solid ? `var(--${color})` : "none"
  styles["hoverBg"] =  `var(--${color}-dark)`

  styles["border"] = bare ? "1px solid transparent" : `1px solid var(--${color})`
  styles["hoverBorder"] = bare ? "1px solid transparent" : `1px solid var(--${color})`

  switch (color) {
    case "warning":
      styles["txt"] = "var(--text-dark)"
      styles["hoverTxt"] = "white"

      styles["bg"] = solid ? `var(--warning-light)` : "none"
      styles["hoverBg"] = `var(--warning-darker)`
      
      styles["border"] = bare ? "1px solid transparent" : `1px solid var(--warning-light)`
      styles["hoverBorder"] = `1px solid var(--warning-darker)`
      break
    
    case "highlight":
      styles["txt"] = "var(--text-dark)"
      styles["hoverTxt"] = "white"
      
      styles["bg"] = solid ? `var(--highlight-light)` : "none"
      styles["hoverBg"] = `var(--highlight-darker)`
      
      styles["border"] = bare ? "1px solid transparent" : `1px solid var(--highlight-light)`
      styles["hoverBorder"] = `1px solid var(--highlight-darker)`
      break
    
    case "confirm":
      styles["txt"] = "var(--text-dark)"
      styles["hoverTxt"] = "white"
      
      styles["bg"] = solid ? `var(--confirm-light)` : "none"
      styles["hoverBg"] = `var(--confirm-darker)`
      
      styles["border"] = bare ? "1px solid transparent" : `1px solid var(--confirm-light)`
      styles["hoverBorder"] = `1px solid var(--confirm-darker)`
      break
  }

  return styles;
};

const getBorderRadius = props => {
  if (props.rounded) return "1em";
  if (props.circle) return "100%";
  return "3px";
};

/**
 * BAButton
 *
 */

export const getSizingRules = size =>
  size
    ? `font-size: var(--text-size-${size}); line-height: var(--button-line-height-${size});`
    : `font-size: var(--text-size-medium); line-height: var(--button-line-height-medium);`; // default to medium

const getCircleSizingRules = size => `
    font-size:   calc( var(--text-size-${size || "medium"}) * 1);
    line-height: calc( var(--button-line-height-${size || "medium"}) * 1);
  `;

const circleStyles = props => {
  if (!props.circle) return "";
  const size = props.size || "medium";
  return `
    display: ${props.block ? "block" : "inline-block"};
    width: calc(2 * var(--text-size-${size}));
    height: calc(2 * var(--text-size-${size}));
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

  `;
};

const buttonStyles = css`
  ${({ size, circle }) =>
    circle ? getCircleSizingRules(size) : getSizingRules(size)}
  padding       : 0 0.66em;
  color         : ${props => getStyles(props).txt};
  background    : ${props => getStyles(props).bg};
  border        : ${props => getStyles(props).border};
  &:hover {
    border      : ${props => getStyles(props).hoverBorder};
    background  : ${props => getStyles(props).hoverBg};
    color       : ${props => getStyles(props).hoverTxt};
  }
  &:active {
    border      : ${props => props.bare ? `1px solid transparent` : `1px solid ${getStyles(props).active}`};
    background  : ${props => getStyles(props).active};
    color       : white;
  }
  &:focus-visible {
    outline: 2px solid ${props => getStyles(props).normal};
    outline-offset: 2px;
  }
  transition    : all 300ms ease;
  cursor        : pointer;
  border-radius : ${props => getBorderRadius(props)};
  text-transform: ${props => (props.uppercase ? "uppercase" : "none")};
  ${disabledStyles}
  ${circleStyles}

  ${props =>
    props.iconOnly && props.size === "micro" ? "line-height: 2em;" : ""}
`;

const BAButton = styled.button`
  ${buttonStyles}
  
  /* If css props (styled-components css obj) passed in allow it to overide button styles */
  ${({ css }) => css}
`;

BAButton.displayName = "BAButton";

/**
 * BATabButton
 *
 */
const tabButtonStyles = css`
  flex-grow: 1;
  border-left: none;
  border-radius: 0;
  margin:0;
  &:focus {
    outline:none;
  }
  &:focus-visible {
    box-shadow: 
      inset -1px -1px 0 0 ${props => getStyles(props).normal},
      inset 1px 1px 0 0 ${props => getStyles(props).normal};
    
  }
  &:first-child, &:first-child:hover {
    border-radius: 3px 0 0 3px;
    border-left: 1px solid ${props => getStyles(props).normal};
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &:hover {
    border-left: none;
  }
`;


const BATabButton = styled(BAButton)`
  ${tabButtonStyles}
`;

BATabButton.displayName = "BATabButton";

/**
 * BAButtonsTab
 *
 */
const buttonsTabStyles = css`
  display: flex;
`;
const BAButtonsTab = styled.div`
  ${buttonsTabStyles}
`;

BAButtonsTab.displayName = "BAButtonsTab";

/**
 * BAActionLink
 *
 */

const BAActionLink = styled.a`
  ${buttonStyles}
  text-decoration : none;
  display: inline-block;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

BAActionLink.displayName = "BAActionLink";
BAActionLink.defaultProps = { tabIndex: 0 }

const FileInputContainer = styled.div`
  position: relative;
  display: inline-block;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  span {
    text-decoration: underline;
  }
`;

class BAFileInput extends Component {
  constructor() {
    super();
    this.state = { value: "" };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
    if (this.props.onChange) this.props.onChange(e);
  }

  render() {
    return (
      <FileInputContainer>
        <div className="flex-row align-center">
          <BAButton {...this.props}>
            {this.props.children}
            <input
              type="file"
              name={this.props.name}
              className={this.props.className}
              onChange={e => this.handleChange(e)}
              disabled={this.props.disabled}
              accept={this.props.accept}
            />
          </BAButton>

          {this.props.readout && this.state.value ? (
            <span className="margin-left">{this.state.value}</span>
          ) : null}
        </div>
      </FileInputContainer>
    );
  }
}

BAFileInput.displayName = "BAFileInput";

export { BAActionLink, BAButton, BATabButton, BAButtonsTab, BAFileInput };
