/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '/styleguide/lib/example'
import BATabularData from './BATabularData'
export const meta = {
  name: "Tabular Data",
  category: "Components",
  tags: ["Table"],
  components: ["BATabularData"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A simple styled table component`}</h1>
    <p>{`The BATabularData component is a styled `}<inlineCode parentName="p">{`<table>`}</inlineCode>{` which can be used as normal with `}<inlineCode parentName="p">{`thead`}</inlineCode>{`, `}<inlineCode parentName="p">{`tbody`}</inlineCode>{`, `}<inlineCode parentName="p">{`th`}</inlineCode>{`, and `}<inlineCode parentName="p">{`td`}</inlineCode>{` tags.`}</p>
    <h2>{`Props`}</h2>
    <p><inlineCode parentName="p">{`minWidth`}</inlineCode>{`  -  number (optional), a minumum width in pixels
`}<inlineCode parentName="p">{`numericalColumns - Array<number> (optional), the child order of any columns holding numerical data.
`}</inlineCode>{`borderless`}<inlineCode parentName="p">{`- boolean (optional), render the table without borders`}</inlineCode>{`fullwidth\` - boolean (optional), render the table at full width`}</p>
    <p>{`Here is an example with a minimum width, borders, and a numerical column in the third position`}</p>
    <Example mdxType="Example">
  <BATabularData minWidth={350} numericalColumns={[3]} mdxType="BATabularData">
    <thead>
      <tr>
        <th>id</th>
        <th>Name</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {[{
            id: 1,
            name: 'one',
            result: 500
          }, {
            id: 2,
            name: 'two',
            result: 2499
          }, {
            id: 3,
            name: 'three',
            result: 324
          }, {
            id: 4,
            name: 'four',
            result: 103
          }].map(item => {
            return <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.result}</td>
          </tr>;
          })}
    </tbody>
  </BATabularData>
    </Example>
    <p>{`Here is an example of a borderless table with no minimum width, and a numerical column at the third position`}</p>
    <Example mdxType="Example">
  <BATabularData borderless numericalColumns={[3]} mdxType="BATabularData">
    <thead>
      <tr>
        <th>id</th>
        <th>Name</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {[{
            id: 1,
            name: 'one',
            result: 500
          }, {
            id: 2,
            name: 'two',
            result: 499
          }, {
            id: 3,
            name: 'three',
            result: 2324
          }, {
            id: 4,
            name: 'four',
            result: 12103
          }].map(item => {
            return <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.result}</td>
          </tr>;
          })}
    </tbody>
  </BATabularData>
    </Example>
    <p>{`Here is an example of a full width table with some LONG content:`}</p>
    <Example mdxType="Example">
  <BATabularData borderless fullwidth mdxType="BATabularData">
    <thead>
      <tr>
        <th>id</th>
        <th>Name</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {[{
            id: 1,
            name: 'one',
            result: 500
          }, {
            id: 2,
            name: 'two',
            result: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dignissim suspendisse in est ante in nibh mauris. Urna cursus eget nunc scelerisque viverra mauris in aliquam sem. Quis hendrerit dolor magna eget est lorem ipsum. Cursus sit amet dictum sit amet justo donec enim. Ut lectus arcu bibendum at varius vel pharetra vel turpis. Facilisi morbi tempus iaculis urna id volutpat lacus laoreet. Justo eget magna fermentum iaculis eu non diam. Platea dictumst quisque sagittis purus. Lorem donec massa sapien faucibus et molestie. Vulputate eu scelerisque felis imperdiet proin fermentum. Nam libero justo laoreet sit amet cursus. Nulla malesuada pellentesque elit eget. Sagittis eu volutpat odio facilisis mauris. Vulputate mi sit amet mauris. Ullamcorper eget nulla facilisi etiam dignissim diam quis. Urna nunc id cursus metus aliquam. Viverra maecenas accumsan lacus vel facilisis volutpat est velit egestas.`
          }, {
            id: 3,
            name: 'three',
            result: 324
          }, {
            id: 4,
            name: 'four',
            result: 103
          }].map(item => {
            return <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.result}</td>
          </tr>;
          })}
    </tbody>
  </BATabularData>
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
