/* eslint-disable @typescript-eslint/camelcase */
import React, {Component, useState}        from 'react'
import styled, { keyframes } from 'styled-components'
import {Router,
        RouteController}         from './lib/Router'
import { components } from '../pages'

import { InputGroup } from '../lib/index'

import '/lib/styles/style.scss'
import './styles/styleguide.scss'
import 'tippy.js/dist/tippy.css'
import SyntaxHighlighter from 'react-syntax-highlighter/prism'
import {tomorrow} from 'react-syntax-highlighter/styles/prism'
import {MDXProvider} from '@mdx-js/react'

const MDXComponents = {
  // pre: props => <SyntaxHighlighter {...props} style={tomorrow} />,
  code: props => {
    const _props = {
      ...props,
      style: tomorrow
    }
    const language = props.className 
      ? props.className.replace("language-", "")
      : "tsx"
    if (language) _props.language = language
    return <SyntaxHighlighter {..._props} />
  }
       
}

const routeNames = Object.keys(components)

const defaultMeta = {
  name: "",
  category: "Components",
  tags: [],
  components: [],
  functions: [],
  values: {}
}

const metaForComponent = componentName => {
  const component = components[componentName] || {}

  const friendlyName = componentName
    .replace(/_/g, " ")

  const meta = {
    url: "/" + componentName,
    ...defaultMeta,
    name: friendlyName,
    ...component.meta
  }
  return meta
}

const metaForURL = url => {
  const componentName = url.replace(/\//g, "")
  return metaForComponent(componentName)
}

const routeMetas = routeNames
  .map(metaForComponent)
  .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 )


const StyleguideLayout = styled.div`
display               : grid;
grid-template-columns : 240px auto;
grid-gap              : 15px;
margin                : 0 2rem;
color                 : var(--text-main);

nav {
  position      : fixed;
  height        : 100%;
  padding-top   : 2rem;
  grid-column   : 1 / 2;
  overflow-y    : scroll;
  padding-right : 2rem;
}

main {
  grid-column : 2 / 3;
  padding     : 0 2rem;

  p {max-width: 45rem;}

  /* code sections */
  pre {
    background: black;
    code {
      color: white;
    }
  }
}

  main > div > table {
    font-size: var(--text-size-small);
    border-collapse: collapse;
    th {
      text-align: left;
    }
    td, th {
      padding: 0.5rem;
      border: 1px solid var(--confirm-lighter);
    }
  }

`

const bounce = keyframes`
  0% { transform: translateX(0); }
  35% { transform: translateX(0); }
  38% { transform: translateX(-2px); }
  40% { transform: translateX(0); }
  50% { transform: translateX(5px); }
  60% { transform: translateX(0); }
  100% { transform: translateX(0); }
`

const Nav = styled.nav`
a {
  color: var(--text-main);
  text-decoration: none;
}
.logo-title {
  cursor      : pointer;
  display     : flex;
  align-items : center;
  font-size   : var(--text-size-large);
  img {
    margin-bottom: 5px;
  }
}
ul {
  margin-right : 1rem;
  font-size    :  var(--text-size-medium);
  padding      : 0;
  list-style   : none;
}
li {
  display     : block;
  line-height : var(--text-line-height-medium);
  margin      : 0.2rem 0;
  padding     : 0.1rem 0.5rem 0 1rem;
  a {
    display: block;
    text-decoration: none;
    &.subhead {
      margin-left: 1rem;
      &::before {
        content: "- "
      }
    }
  }
  &:hover {
    color: var(--highlight);
    a { color: var(--highlight); }
  }
  &.current::before, &:hover::before {
    display: block;
    content: "→";
    float: left;
    margin-left: -1rem;
    animation: ${bounce} 5s infinite ease-in-out;
  }

}
`
const veg = ["🌶", "🌽", "🍅", "🍆", "🥒", "🥕", "🥔", "🍠", "🥦"]
const randomVeg = () => veg[Math.floor(Math.random() * 9)] || "🌶"


const URLFromWindow = () => {
  if (typeof window === "undefined") return null
  let currentURL = window.location.href.replace(window.location.origin, "")
  if (currentURL == "/") currentURL = "/index"
  return currentURL
}

/**
 * reducer function to group routes into an object keyed by category
 * @param {*} accumulator 
 * @param {*} meta 
 */
const groupRoutes = (accumulator, meta) => {
  if (!accumulator[meta.category]) accumulator[meta.category] = []
  accumulator[meta.category].push(meta)
  return accumulator
}


const valuesFromURL  = url => metaForURL(url).values


const NavItem = ({ meta, isCurrent }) => <li
  className = {isCurrent ? "current" : ""}
  >
  <a href={meta.url}>
  {meta.name}
  </a>
</li>

const PageNavigation = ({currentURL}) => {

  const [searchTerm, setSearchTerm] = useState("")

  const routeGroups = routeMetas
    .filter(meta => {
      if (!searchTerm.trim()) return true
      const subTerms = searchTerm.toLowerCase().split(" ")
      let matches = 0
      for (const subTerm of subTerms) {
        if (meta.name.toLowerCase().includes(subTerm)) matches++
        for (const entry of [...meta.tags, ...meta.components, ...meta.functions]) {
          if (entry.toLowerCase().includes(subTerm)) matches++
        }
      }
      return matches >= subTerms.length
    })
    .reduce(groupRoutes, {})

  const navs = Object
    .entries(routeGroups)
    .sort((a, b) => {
      if (a[0] == "Style Guide") return -1
      if (b[0] == "Style Guide") return 1
      if (a[0].includes("Deprecated")) return 1
      if (b[0].includes("Deprecated")) return -1
      return a[0] > b[0] ? 1 : -1
    })
    .map(
      ([groupName, groupMetas]) => <React.Fragment key={groupName}>
        <p className="font-semibold">{groupName}</p>
        {
          groupMetas.map(
            meta => <NavItem 
              key={meta.url} 
              meta={meta} 
              isCurrent={meta.url === currentURL } 
            />
          )
        }
      </React.Fragment>
    )

  return (
    <Nav>
      <a href="/">
        <div className="logo-title">
          <div id="ba-logo" className="margin-right font-semibold" />
          BA Styleguide {randomVeg()}
        </div>
      </a>
      <InputGroup className="margin-top">
        <input placeholder="🕵️‍♀️ search" onChange={e => setSearchTerm(e.target.value)} />
      </InputGroup>
      <ul>
        {navs}
      </ul>
    </Nav>
  )
}


// STYLEGUIDE COMPONENT BEGINS
export default class Styleguide extends Component {

  constructor (props) {
    const url = props.url || URLFromWindow()
    super(props)
    this.state = {
      currentURL : url,
      values     : valuesFromURL(url)
    }
  }

  setStateForURL (url) {
    this.setState({
      currentURL: url,
      values : valuesFromURL(url)
    })
  }

  render() {
    return (
      <StyleguideLayout>
        <PageNavigation currentURL={this.state.currentURL} />
        
        <MDXProvider components={MDXComponents}>
          <main>
            <RouteController
              onChange = {url => this.setStateForURL(url)}
              router   = {Router}
              get      = {key => this.state.values[key]}
              set      = {(key, value) => this.setState({ values : { ...this.state.values, [key] : value }})}
            />
          </main>
        </MDXProvider>
      </StyleguideLayout>
    )
  }
}
