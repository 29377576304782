/**
 * Filter a tree, returning the filtered tree, and a list of nodes to expand to reveal the results
 */
export function filterTree (tree, searchTerm, expandedForSearchOverride, searchKey, uniqueKey) {
  
  if (!tree.children) { return [tree, expandedForSearchOverride || []] }
  if (!searchTerm || searchTerm === '') { return [tree, expandedForSearchOverride || []] }
  
  let expandedForSearch  = []

  /** 
   * This implementation could stack overflow for very large trees. I've not seen this happen,
   * but it might be better implemented as a while loop
   */
  function filterChildren (node) {
	let newNode = Object.assign({}, node)
	let expanded = false;
	if (!newNode[searchKey]) {
	  return false
	}

	if (newNode.children) {
	  newNode.children = newNode.children.map((child) => {
		return filterChildren(child)
	  }).filter((child) => {
		return child !== false
	  });
	}

	if (newNode.children && newNode.children.length > 0) {
	  expanded = true
	}

	if (!newNode[searchKey] || newNode[searchKey].toLowerCase().includes(searchTerm.toLowerCase())) {
	  expanded = true
	}

	if (expanded === true) {
	  expandedForSearch.push(newNode[uniqueKey])
	  return newNode
	} else {
	  return false
	}
  }

  const newtree = filterChildren(tree)
  const expanded = expandedForSearchOverride 
	? expandedForSearchOverride 
	: expandedForSearch

  return [newtree, expanded] 
}


export function pathToChild(uniqueKey, childUnique, tree) {
  let pathToChild = null;

  function walkPath(node, path = []) {
	if (pathToChild) {
	  return; // we have won already
	}
	else {
	  if (node[uniqueKey] === childUnique) {
		pathToChild = path;
		return;
	  }
	  else {
		const newPath = [].concat(path).concat([node[uniqueKey]]);
		if (node.children && node.children.length) {
		  node.children.forEach((child) => { walkPath(child, newPath); });
		}
	  }
	}
  }

  walkPath(tree);

  return pathToChild;
}
