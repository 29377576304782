import React, {PureComponent}  from 'react'
import {BAPortal}              from './portal'
import withRootClose           from './rootClose'
import {BAButton}              from './buttons'

// This component renders a button and a dropdown list for it to toggle.
class BADropdownComponent extends PureComponent {

  toggleDropdown() {
    // both of these functions are passed down from the RootClose component.
    if (this.props.isOpen) {
      this.props.hide()
      this.props.onClose && this.props.onClose()
    }
    else {
      this.props.show()
      this.props.onOpen && this.props.onOpen()
    }
  }

  getToggleButtonPos() {  
    if (this.ToggleButton && this.ToggleButton.getBoundingClientRect) {
      return this.ToggleButton.getBoundingClientRect()
    }
    return null
  }

  renderDropdownMenu() {
    let dropdownMenuClassList = ["ba-dropdown-menu-content"]
    if (this.props.menuClass) dropdownMenuClassList.push(this.props.menuClass)
    const buttonPos = this.getToggleButtonPos()

    // compose absolute position styling for dropdown content container if using portal.
    const portalStyle = {}
    if (this.props.withPortal) {
      portalStyle['position'] = 'absolute'
      portalStyle['zIndex']   = 'var(--z-index-layer-4)'
      if (this.props.portalOffsets && buttonPos) {
        portalStyle['left'] = buttonPos.left + window.pageXOffset + this.props.portalOffsets[0]
        portalStyle['top']  = buttonPos.top + window.pageYOffset + this.props.portalOffsets[1]
      }
    }
    // withRootClose passes down a ref-setter to this dropdown content container,
    // so that it can tell when we've clicked outside it.
    const dropdownContent = (
      <div
        ref          = {ref => this.props.setRefFromWithRootClose(ref)}
        onMouseEnter = {this.props.mouseEnterMethod}
        onMouseLeave = {this.props.mouseLeaveMethod}
        className    = {dropdownMenuClassList.join(" ")}
        style        = {portalStyle}
      >
        {this.props.renderMenuContent
          ? this.props.renderMenuContent() // rendering contents via a function passed down means the content will render only when the toggle button's clicked,
          : this.props.children            // reducing click handlers on menu items etc.
        }
      </div>
    )

    return this.props.withPortal ? (
      <BAPortal isOpen>
        {dropdownContent}
      </BAPortal>
    ) : dropdownContent
  }


  renderToggleButton() {
    let toggleButtonClassList = ["ba-dropdown-toggle"]
    if (this.props.toggleButtonClass) toggleButtonClassList.push(this.props.toggleButtonClass)

    // NOTE - the containing div here is to give us a consistent boudingClientRect regardless of the togglebutton's rotation.
    // the mouseEnterMethod and mouseLeaveMethod props come from withRootClose - but will only trigger if this baDropdown has a closeAfterDelay prop.

    const {toggleButton: ToggleButton} = this.props

    return (
      <div
        ref           = {ref => this.ToggleButton = ref}
        onMouseEnter  = {this.props.mouseEnterMethod}
        onMouseLeave  = {this.props.mouseLeaveMethod}
      >
      {/* Can pass down the entire button as a prop, or a toggleButtonClassList and a toggleButtonIcon */}
      {ToggleButton ? (
        <ToggleButton
          onClick={() => {this.toggleDropdown()}}
        />
      ) : (
        <button
          onClick   = {this.toggleDropdown.bind(this)}
          className = {toggleButtonClassList.join(" ")}
          id        = {this.props.toggleButtonId}
        >

        {this.props.buttonContent
          ? this.props.buttonContent
          : null}

        {this.props.toggleButtonIcon
          ? <i className  = {this.props.toggleButtonIcon} />
          : null}

        </button>)}
      </div>
    )
  }


  render() {
    let dropdownClassList = ["ba-dropdown"]
    if (this.props.dropdownClass) dropdownClassList.push(this.props.dropdownClass)

    return (
      <div
        className = {dropdownClassList.join(" ")}
        tabIndex  = {0}
      >
        {this.renderToggleButton()}

        {this.props.isOpen
          ? this.renderDropdownMenu()
          : null}

      </div>
    )
  }
}

const BADropdown = withRootClose(BADropdownComponent)

// EXAMPLE FOR THE STYLEGUIDE........

const BADropdownExampleButton = ({onClick}) => (
  <BAButton
    size    = "medium"
    onClick = {onClick}
    solid
  >
    puppy
  </BAButton>
)
const background =
  "url('https://img.buzzfeed.com/buzzfeed-static/static/2015-12/8/21/campaign_images/webdr09/21-cute-dog-pictures-to-help-you-put-off-whatever-2-17537-1449629310-18_dblbig.jpg')"

const BADropdownExample = ({withPortal}) => (
  <BADropdown
    withPortal    = {withPortal}
    toggleButton  = {BADropdownExampleButton}
    portalOffsets = {[50, 50]}
  >
    <div style={{
        zIndex     : '100000000',
        height     : '400px',
        width      : '400px',
        background
      }}>
        <BAButton
          solid
          color="confirm"
          size ="small"
        >Aww. Click Outside Me</BAButton>
    </div>
  </BADropdown>
)

export default BADropdown
export {BADropdownExample}
