import React from "react";
import styled from "styled-components";
import { getSizingRules } from './buttons'

import { toReactSelectOption } from "./forms/selects";

const Segments = styled.div`
  display: flex;
  flex-direction: row;
  button {
    ${({size}) => getSizingRules(size)}
  }
  button + button {
    border-left: 1px solid var(-- ${(props) => props.color || "text-main"});
  }
  button.selected + button {
    border-left: none;
    padding-left: calc(1rem + 1px);
  }
  &.duo button.selected {
    font-weight: 600;
    border-width: 3px;
    margin-top: -2px;
    margin-bottom: -2px;
  }
`;

const Segment = styled.button`
  margin: 0;
  white-space: nowrap;
  background: ${(props) =>
    props.selected ? `var(--${props.color || "text-main"})` : "white"};
  color: ${(props) =>
    props.selected ? "white" : `var(--${props.color || "text-main"})`};
  outline: none;
  border: none; // reset regular browser borders
  border-top: 1px solid ${(props) => `var(--${props.color || "text-main"})`};
  border-bottom: 1px solid ${(props) => `var(--${props.color || "text-main"})`};
  &:not(:nth-of-type(1)) {
    border-left: 1px solid
      ${(props) =>
        props.selected
          ? "transparent"
          : `var(--${props.color || "text-main"})`};
  }

  &:nth-of-type(1) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid ${(props) => `var(--${props.color || "text-main"})`};
  }
  &:nth-last-of-type(1) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 1px solid ${(props) => `var(--${props.color || "text-main"})`};
  }

  display: block;
  font-size: 1rem;
  padding: 0 1rem;
  line-height: 2.4rem;
  flex: 1 1 150px;
`;

const noop = () => void 0;

const Option = (props) => (
  <Segment
    color={props.color}
    tabIndex="0"
    className={props.selected ? "selected" : ""}
    selected={props.selected}
    onClick={props.onClick}
    optionCount={props.optionCount}
  >
    {props.option.icon ? (
      <i className={`${props.option.icon}`} />
    ) : (
      props.option.label
    )}
  </Segment>
);

const SegmentControl = ({
  options = [],
  onChange = noop,
  value = "",
  name = "name",
  id = "id",
  className = "",
  color = null,
  size = "medium"
}) => {
  const _options = options.map(toReactSelectOption);
  const _onChange = (val) =>
    onChange({
      target: {
        name,
        id,
        value: val,
      },
    });
  const isSelected = (opt) => opt.value == value;
  const select = (opt) => _onChange(opt.value);
  const extraClassNames = options.length <= 2 ? " duo" : "";
  return (
    <Segments className={className + extraClassNames} size={size}>
      {_options.map((opt) => (
        <Option
          color={color}
          key={opt.value ? opt.value : opt}
          option={opt}
          selected={isSelected(opt)}
          onClick={() => select(opt)}
          optionCount={options.length}
        />
      ))}
    </Segments>
  );
};

export default SegmentControl;
