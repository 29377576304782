import React, { useCallback } from "react"
import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(-110%); }
  50% { opacity: 1; }
  100% { transform: translateY(0); }
`

const fadeOut = keyframes`
  0% { transform: translateX(0); }
  50% { opacity: 1; }
  100% { opacity: 0; transform: translateX(110%); }
`

const NotificationBlock = styled.div`
  padding: 0.75rem;
  background: var(--text-dark);
  color: white;
  margin: 1rem;
  border-radius: 0.25rem;
  border: solid teal 2px;
  &.fade {
    animation: ${fadeOut} 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  &.visible {
    animation: ${fadeIn} 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  ${({ notificationCss }) => notificationCss}
`

export const Notification = ({ message, meta, dispatch }) => {
  const className = meta.className || "appearing"

  const pause = useCallback(() => {
    dispatch({ type: "pauseMessage", payload: message })
  }, [dispatch, message])

  const unPause = useCallback(() => {
    dispatch({ type: "runMessage", payload: message })
  }, [dispatch, message])

  return (
    <NotificationBlock
      notificationCss={message.notificationCss}
      onMouseEnter={pause}
      onMouseLeave={unPause}
      className={className}
    >
      {message.message}
    </NotificationBlock>
  )
};

Notification.displayName = "Notification"
