/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Example from '../lib/example.jsx'
import {BAGrid, BACell} from '/lib/components/grid'
export const meta = {
  name: "BA Grid",
  category: "Components",
  tags: [],
  components: ["BAGrid", "BACell"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BAGrid`}</h1>
    <p><img parentName="p" {...{
        "src": "http://ekladata.com/TYnXNzyh19Ou_A1klp5tsQa4EtI.jpg",
        "alt": "TYnXNzyh19Ou_A1klp5tsQa4EtI.jpg"
      }}></img></p>
    <p>{`BAGrid and BACell very simply provide Styled Component wrappers around the CSS Grid parent and child relationship.`}</p>
    <p>{`It was conceived with the intention of keeping the full flexibility of CSS Grid but enabling us to set properties dynamically by passing them in as props in JS.`}</p>
    <p>{`If you plan to use it but aren't yet acquainted with how CSS Grid works, there's a good explanation on `}<a parentName="p" {...{
        "href": "https://css-tricks.com/snippets/css/complete-guide-grid/"
      }}>{`CSS Tricks.`}</a></p>
    <strong>Let's demo a quick example.</strong>
    <h2>{`BAGrid`}</h2>
    <p>{`We start with a `}<inlineCode parentName="p">{`BAGrid`}</inlineCode>{` which currently takes one prop, `}<inlineCode parentName="p">{`columns`}</inlineCode>{`.
This is a string which gets passed to the `}<inlineCode parentName="p">{`grid-template-columns`}</inlineCode>{` CSS property, defining the columns that its children can then adhere to.`}</p>
    <p>{`This defaults to `}<inlineCode parentName="p">{`repeat(12, 1fr)`}</inlineCode>{` providing 12 columns of equal width - which may well be good enough for your use case.`}</p>
    <p>{`If you want to add props like `}<inlineCode parentName="p">{`row-gap`}</inlineCode>{` and `}<inlineCode parentName="p">{`column-gap`}</inlineCode>{` to make these rules too, go ahead and modify the component.`}</p>
    <h2>{`BACell`}</h2>
    <p>{`Remember that all `}<inlineCode parentName="p">{`BACells`}</inlineCode>{` adhering to this grid must be direct children.`}</p>
    <p>{`Pass them the `}<inlineCode parentName="p">{`cols`}</inlineCode>{` prop to set their `}<inlineCode parentName="p">{`grid-column`}</inlineCode>{` CSS property.`}</p>
    <Example mdxType="Example">
  <BAGrid style={{
        height: '300px',
        width: '100%',
        color: "white",
        border: "1px dashed var(--text-main)",
        fontWeight: "bold",
        padding: "1rem"
      }} mdxType="BAGrid">
    <BACell cols={"1 / 13"} style={{
          color: "var(--text-text-main)"
        }} mdxType="BACell">
      <div className="center-all-directions text-text-main">{`<BAGrid />`}</div>
    </BACell>
    <BACell cols={"1 / 7"} style={{
          background: "purple"
        }} mdxType="BACell">
      <div className="center-all-directions">{`<BACell cols = { " 1 / 7 " } />`}</div>
    </BACell>
    <BACell cols={"7 / 13"} style={{
          background: "blue"
        }} mdxType="BACell">
      <div className="center-all-directions">{`<BACell cols = { " 7 / 13 " } />`}</div>
    </BACell>
    <BACell cols={"1 / 13"} style={{
          background: "salmon"
        }} mdxType="BACell">
      <div className="center-all-directions">{`<BACell cols = { " 1 / 13 " } />`}</div>
    </BACell>
  </BAGrid>
    </Example>
    <h2>{`Using in conjunction with Flex`}</h2>
    <p>{`You can use this with Flex functional classes to achieve pretty much any layout you want with a concise markup and no additional cluttery CSS files.`}</p>
    <p>{`Be aware that a flex rule applied directly to a `}<inlineCode parentName="p">{`BACell`}</inlineCode>{` grid child can interfere with its grid rules, so you may have to apply the flex classes to a child instead.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
