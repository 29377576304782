// this Higher Order Component gives other components the ability to rootClose -
// i.e. disappear from the UI when the user clicks outside them.

// It's set up to either keep track of the visible/invisible state of its children, or work
// alongside a parent component that keeps track of it.

// In the case of BADropdown like ReportHeader dropdown menus, which consist of a child of withRootClose which renders a button AND a menu,
// withRootClose passes down a 'show' function, and keeps control of the visible/invisible state as 'open'.

// In the case of BAModals like the context menu in Sidebar, we want the Sidebar to keep this state - and pass it
// down to the withRootClose component. In this instance to make rootClose work we also need to pass down
// an onClose function which calls a state-setting function back in the Context Menu.

import React, {Component} from 'react'
import ReactDOM from 'react-dom'

const withRootClose = WrappedComponent => class RootCloser extends Component {

  static getDerivedStateFromProps (props, state) {
    if (props.isOpen != null && state.isOpen != props.isOpen) {
      return {
        ...state,
        isOpen : props.isOpen
      }
    }
    return null
  }

  constructor(props) {
    super()
    this.state = {
      isOpen: props.isOpen || false
    }

    this.rootClose = this.rootClose.bind(this)
    this.hide = this.hide.bind(this)
  }

  // NOTE: this _isMounted property is being used to decide whether we need to call setState({open: false}) when rootclosing - or if
  // this.props.onClose has already provided the closing functionality. (onClose may be just being provided to do other stuff - such as
  // the reportHeaderMenu which needs to reset the export options menu when the dropdown closes).
  // if we don't perform this check, in places where the component is already gone we'll get a error saying you can't call setState on an unmounted component.
  // isMounted() used to be a lifecycle method but is deprecated - this is a replacement for it.
  componentDidMount() {
    this._ismounted = true
  }

  componentWillUnmount() {
    this._ismounted = false
    // clear the modal timeout before the component unmounts
    // otherwise you can hit a no-op error trying to set state on an unmounted component
    this.clearComponentTimeout()

    // remove the rootClose event listener
    // otherwise you can leak memory!
    this.tearDownRootClose()
  }

  show() {
    this.setState({isOpen: true})
  }

  hide() {
    this.tearDownRootClose()
    this.clearComponentTimeout()

    if (this.props.onClose) this.props.onClose()
    // NOTE: see note above - sometimes props.onClose method does the unmounting, sometimes not.
    // if this component is already unmounted we don't need to go any further.
    if (this._ismounted) this.setState({isOpen: false})
  }

  closeDropdownAfterDelay() {
    this.timeout = setTimeout( () =>
    this.hide()
    , 1500)
  }

  clearComponentTimeout() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  setupRootClose() {
    // this gets called on render.
    if (this.state.isOpen) {
      document.addEventListener("mousedown", this.rootClose)
    } else {
    // so if the menu is closed, we make sure the event listener isn't registered.
    // this is probably reduntant, but won't hurt.
      document.removeEventListener("mousedown", this.rootClose)
    }
  }

  tearDownRootClose() {
    document.removeEventListener("mousedown", this.rootClose)
  }

  rootClose(e) {
    const me = this.RootCloser // this element
    const path = e.path || []

    // polyfill event.path for browsers that don't support it.
    let parentGone = false
    if (!path.length) {
      let node = e.target
      while (node != document.body) {
        if (node == null) {
          parentGone = true
          break
        }
        path.push(node)
        node = node.parentNode
      }
    }

    // the event stopped bubbling because the parent element of the target has been removed form the DOM
    // either the menu is already closed, or we changed menu page
    if (parentGone) return

    // did the event bubble thru this element on its way to the document
    if (!path.includes(me) && (this.ChildRef ? !path.includes(this.ChildRef) : true)) { // ChildRef exists if we're rendering menu content via a portal.
      this.tearDownRootClose()
      this.hide()
    }
  }

  render() {
    // SSR check
    if (typeof document !== 'undefined') this.setupRootClose()

    return (
      <WrappedComponent  {...this.props}
        ref                     = {ref => this.RootCloser = ReactDOM.findDOMNode(ref)}
        hide                    = {this.hide.bind(this)}
        isOpen                  = {this.state.isOpen}
        show                    = {this.show.bind(this)}
        setRefFromWithRootClose = {ref => this.ChildRef = ref}
        mouseEnterMethod        = {this.props.closeAfterDelay ? this.clearComponentTimeout.bind(this) : null}
        mouseLeaveMethod        = {this.props.closeAfterDelay ? this.closeDropdownAfterDelay.bind(this) : null}
      />
    )
  }
}

export default withRootClose
