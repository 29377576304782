import styled from 'styled-components'
import {disabledStyles} from '../_shared/vars'

export const BAPalette = styled.aside`
  ${props => props.position
    ? `
        position: ${props.position.position || 'absolute'};
        left : ${props.position.x};
        top  : ${props.position.y};
        `
    : ''}
  min-width: 350px;
  ${props => props.zIndex 
    ? `z-index: var(--z-index-layer-${props.zIndex});` 
    : ''}
  background: var(--content-bg);
  border: 2px solid var(--border);
  box-shadow: 4px 4px 0 0 var(--border);
  ${props => props.dark
    ? `
    --content-bg: #333;
    --text-main: #fafafa;
    --highlight-lighter: #5a5a5a;
    --border: #555;
    `
    : `--border: var(--text-main);`
  }
`;
export const PickerContainer = styled.div`
  ${props => props.padded === false ? "padding: 0;" : "padding: 1rem;"}
  h3 { margin: 0; }
`;
export const Search = styled.input`
  width: 100%;
  line-height: 2em;
  padding-left: 1em;
  border: 1px solid var(--text-main);
  border-radius: 3px;
  box-sizing: border-box;
  background: transparent;
  color : var(--text-main);
  &:focus {
      outline: none;
      box-shadow: 0 0 3px 0 var(--text-main);
    }
`;
export const ExpandoButton = styled.button`
  color: var(--text-main);
  outline     : none; border: none;
  background  : transparent;
  transition  : transform 0.3s ease-out;
  display     : flex;
  align-items : center;
  justify-content: center;
  transform-origin: 50% 50%;
  width: 2em;
  height: 2em;
  padding: 0;
  ${props => props.expanded ? `transform: rotate(90deg);` : ""}
  &:hover {  
    transform: rotate(45deg);
  }
`;
export const ThingList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-inline-start: 0rem;
  max-height: ${props => props.maxHeight || "60vh"};
  overflow-y: auto;
  overflow-x: hidden;
`;
export const PickerItem = styled.li`
  ${disabledStyles}
  padding-left: ${props => (props.depth - 1) * 2}em;
  line-height: 2rem;
  border: 1px solid transparent;
  color: var(--text-main);
  ${props => props.selected ? "background: var(--highlight-lighter);" : ""}
  &:hover {
    border: 1px dotted var(--highlight);
  }
  display: flex;
  line-height: 2em;
  label {
    cursor: pointer;
    flex: 1 1;
    margin-right: 0;
  }
  .leaf-label::before {
    content: "•";
    text-decoration: none;
    display: inline-block;
    padding-right: 1em;
  }
  a {
    color: inherit;
  }
`;
