import React              from 'react'
import styled, { css }    from 'styled-components'

// @TODO -- hey this could use a big ole refactor

let Page = styled.div`
  margin  : 2rem;
  padding : 2rem;
  font-weight: 300;
  h1, h2, h3, h4, h5, h6 {
    display: inline-block;
    margin: 0;
    padding: 0.5rem;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;
    &.jumbo {
      font-size: 4rem; line-height: 6rem;
    }
  }
  * { box-sizing: border-box; }
`

const Grid = styled.div`
  @media screen and (min-width: 800px) {
    display               : grid;
    grid-template-columns : 300px repeat(12, 1fr);
    grid-template-rows: repeat(50px);
  }
`

const LeftCol = styled.div`
  padding     : 1rem;
  grid-column : 1 / 2;
  grid-row: auto / auto;
`

const RightCol = styled.div`
  padding : 1rem;
  grid-column : 2 / 14;
  grid-row: auto / auto;
`

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  li ul { margin-left: 1rem; }
`

const rulesColours = {
  darkGrey : "HSLA(106, 0%, 79%, 1.00)",
  blue     : "HSLA(201, 44%, 53%, 1.00)",
  orange   : "HSLA(31, 72%, 68%, 1.00)",
}

const ActionButton = styled.button`
  outline: none;
  border: none;
  border-radius: 3px;
  background: yellow;
  color: black;
  font-size: 1rem;
  line-height: 2.4rem;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-out;
  display: inline-block;
  font-weight: 400;
  border: 0.5px solid black;
  &:hover {
    box-shadow: 0 4px 0 0 orangered;
  }
  &:active {
    box-shadow: 0 0 0 0 orangered, inset 0 4px 0 0 darkcyan;
  }
`

const circleButtonStyles = css`
  line-height: 2rem;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding       : 0;
  border-radius : 1rem;
`

const NakedCircleButton = styled.button`
  ${circleButtonStyles}
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  color: black;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: orangered;
    background: RGBA(3,3,3,0.1);
  }
  &:active {
    color: black;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      color: inherit;
      background: inherit;
    }
  }
`

const Expando = styled.button`
  outline     : none; border: none;
  background  : transparent;
  transition  : transform 0.3s ease-out;
  display     : inline-block;
  transform-origin: 50% 50%;
  &.expanded-true { transform: rotate(90deg);}
`

const RemoveButton = styled.button`
  border: 1px solid black;
  outline: none;
  ${circleButtonStyles}
  background    : white;
  &:hover {
    background: red;
    color: white;
  }
`

const FlexContainer = styled.div`
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  &.clear-both { clear: both; }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .left {
    align-self: flex-start;
  }
  .right {
    align-self: flex-end;
  }
`
FlexContainer.displayName = "FlexContainer"

const Panel = styled.div`
  clear: both;
  background    : ${props => props.bg || "#fafafa"};
  color         : ${props => props.col || "black"};
  padding       : 0.5rem;
  margin-bottom : 0.5rem;
  border: 0px solid black;
  &.restrict-width {
    margin-left: calc(50% - 8em);
    margin-right: calc(50% - 8em);
    &.inline-block {
      margin-left: auto;
      margin-right: auto;
      min-width: 16em;
    }
  }
  &.border {
    border-radius: 3px;
    border : 0.5px solid black;
  }
  &.border-radius {
    border-radius: 3px;
  }
  &.naked {
    background: none;
    border: none;
  }
  &::after {
    display : block;
    content : " ";
    clear   : both;
  }
  &::before {
    ${props => props.highlight ? css`
      content: " ";
      display: block;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      margin-bottom: 1rem;
      height : 0.5rem
      background: ${props.highlight};
    ` : ""}
  }
`

export {

  // TODO -- refactor us?
  ActionButton,
  NakedCircleButton,
  Expando,
  RemoveButton,

  Grid,

  FlexContainer,
  Panel,

  LeftCol,
  RightCol,

  Page,
  rulesColours,
  UnstyledList,

}
