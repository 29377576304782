/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */
import Datepicker from '/lib/components/datepicker'
import Example from '/styleguide/lib/example'
import {BAButton}        from '/lib/components/buttons'

export const meta = {
  name: "Date Picker",
  category: "Ideas & Sketches",
  tags: [],
  components: ["Datepicker"],
  functions: [],
  values: {}
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Datepicker`}</h1>
    <p>{`A lawrence hunt experiment towards text-entry report building`}</p>
    <Example twocolumn mdxType="Example">
  <Datepicker mdxType="Datepicker" />
    </Example>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
